import {
  put,
  call,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';
import {
  ADMINISTRATION_ORGANIZATION_LIST_REQUESTED,
  ADMINISTRATION_ORGANIZATION_LIST_SUCCESSED,
  ADMINISTRATION_ORGANIZATION_LIST_FAILED,
  ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED,
  ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
  ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_FAILED,
  ADMINISTRATION_CLASSIFICATION_DELETE_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_DELETE_SUCCESSED,
  ADMINISTRATION_CLASSIFICATION_DELETE_FAILED,
  ADMINISTRATION_CLASSIFICATION_CREATE_LIST_REQUESTED,
  ADMINISTRATION_CLASSIFICATION_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_CLASSIFICATION_CREATE_LIST_FAILED,
  ADMINISTRATION_OWNERS_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_LIST_SUCCESSED,
  ADMINISTRATION_OWNERS_LIST_FAILED,
  ADMINISTRATION_OWNERS_DELETE_REQUESTED,
  ADMINISTRATION_OWNERS_DELETE_SUCCESSED,
  ADMINISTRATION_OWNERS_DELETE_FAILED,
  ADMINISTRATION_OWNERS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_OWNERS_UPDATE_LIST_FAILED,
  ADMINISTRATION_OWNERS_CREATE_LIST_REQUESTED,
  ADMINISTRATION_OWNERS_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_OWNERS_CREATE_LIST_FAILED,
  ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSETS_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_CREATE_FAILED,
  ADMINISTRATION_ORAGANIZATION_CREATE_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_CREATE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_DELETE_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_DELETE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_DELETE_FAILED,
  ADMINISTRATION_ORGANIZATION_UPDATE_FAILED,
  ADMINISTRATION_ORGANIZATION_UPDATE_SUCCESSED,
  ADMINISTRATION_ORGANIZATION_UPDATE_REQUESTED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_SUCCESSED,
  ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED,
  ADMINISTRATION_SET_SELECTED_ORGANIZATION_SUCCESSED,
  ADMINISTRATION_SET_SELECTED_ORGANIZATION_FAILED,
  ADMINISTRATION_SET_SELECTED_ORGANIZATION_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_USERS_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_USERS_FAILED,
  ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_SUCCESSED,
  ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_SUCCESSED,
  ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_SUCCESSED,
  ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED,
  ADMINISTRATION_DELETE_ASSETS_GROUPS_REQUESTED,
  ADMINISTRATION_DELETE_ASSETS_GROUPS_SUCCESSED,
  ADMINISTRATION_DELETE_ASSETS_GROUPS_FAILED,
  ADMINISTRATION_EDIT_ASSETS_GROUPS_REQUESTED,
  ADMINISTRATION_EDIT_ASSETS_GROUPS_SUCCESSED,
  ADMINISTRATION_EDIT_ASSETS_GROUPS_FAILED,
  ADMINISTRATION_WORKFLOW_LIST_REQUESTED,
  ADMINISTRATION_WORKFLOW_LIST_SUCCESSED,
  ADMINISTRATION_WORKFLOW_LIST_FAILED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED,
  ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED,
  ORAGANIZATION_SUCCESSED,
  ORAGANIZATION_FAILED,
  ORAGANIZATION_REQUESTED,
  ORAGANIZATION_USER_CREATE_SUCCESSED,
  ORAGANIZATION_USER_CREATE_FAILED,
  ORAGANIZATION_USER_DELETE_SUCCESSED,
  ORAGANIZATION_USER_DELETE_FAILED,
  ORAGANIZATION_USER_UPDATE_SUCCESSED,
  ORAGANIZATION_USER_UPDATE_FAILED,
  ORAGANIZATION_USER_CREATE_REQUESTED,
  ORAGANIZATION_USER_DELETE_REQUESTED,
  ORAGANIZATION_USER_UPDATE_REQUESTED,
  SHOW_LOADER,
  HIDE_LOADER,
  ADMINISTRATION_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CATEGORY_LIST_FAILED,
  ADMINISTRATION_CATEGORY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_CATEGORY_UPDATE_LIST_FAILED,
  ADMINISTRATION_CATEGORY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_DELETE_LIST_SUCCESSED,
  ADMINISTRATION_CATEGORY_DELETE_LIST_FAILED,
  ADMINISTRATION_CATEGORY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_CATEGORY_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_CATEGORY_CREATE_LIST_FAILED,
  ADMINISTRATION_WORKFLOW_CHANGE_STATUS_REQUESTED,
  ADMINISTRATION_WORKFLOW_CHANGE_STATUS_SUCCESSED,
  ADMINISTRATION_WORKFLOW_CHANGE_STATUS_FAILED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_CREATE_ASSET_SUB_GROUP_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_SUB_GROUP_SUCCESSED,
  ADMINISTRATION_CREATE_ASSET_SUB_GROUP_FAILED,
  ADMINISTRATION_EDIT_ASSET_SUB_GROUP_REQUESTED,
  ADMINISTRATION_DELETE_ASSET_SUB_GROUP_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_SUB_GROUP_SUCCESSED,
  ADMINISTRATION_WORKFLOW_DUPLICATE_REQUESTED,
  ADMINISTRATION_WORKFLOW_DUPLICATE_SUCCESSED,
  ADMINISTRATION_WORKFLOW_DUPLICATE_FAILED,
  ADMINISTRATION_WORKFLOW_DELETE_REQUESTED,
  ADMINISTRATION_WORKFLOW_DELETE_SUCCESSED,
  ADMINISTRATION_WORKFLOW_DELETE_FAILED,
  ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_CATEGORY_SUCCESSED,
  ADMINISTRATION_WORKFLOW_CATEGORY_FAILED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_SUCCESSED,
  ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_SUCCESSED,
  ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED,

  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_SUCCESSED,
  ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED,

  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_SUCCESSED,
  ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED,

  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED,
  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_SUCCESSED,
  ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED,

  ADMINISTRATION_WORKFLOW_TAGS_REQUESTED,
  ADMINISTRATION_WORKFLOW_TAGS_SUCCESSED,
  ADMINISTRATION_WORKFLOW_TAGS_FAILED,

  ADMINISTRATION_EDIT_ASSET_SUB_GROUP_FAILED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_FAILED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_FAILED,

  ADMINISTRATION_WORKFLOW_CREATE_REQUESTED,
  ADMINISTRATION_WORKFLOW_CREATE_SUCCESSED,
  ADMINISTRATION_WORKFLOW_CREATE_FAILED,
  ADMINISTRATION_WORKFLOW_UPDATE_REQUESTED,
  ADMINISTRATION_WORKFLOW_UPDATE_SUCCESSED,
  ADMINISTRATION_WORKFLOW_UPDATE_FAILED,
  ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED,
  ADMINISTRATION_ASSET_VALUE_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_VALUE_LIST_FAILED,
  ADMINISTRATION_CREATE_ASSET_VALUE_LIST_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_VALUE_LIST_SUCCESSED,
  ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED,
  ADMINISTRATION_EDIT_ASSET_VALUE_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_VALUE_SUCCESSED,
  ADMINISTRATION_EDIT_ASSET_VALUE_FAILED,
  ADMINISTRATION_DELETE_ASSET_VALUE_REQUESTED,
  ADMINISTRATION_DELETE_ASSET_VALUE_SUCCESSED,
  ADMINISTRATION_DELETE_ASSET_VALUE_FAILED,
  ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_REQUESTED,
  ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_SUCCESSED,
  ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_FAILED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED,
  ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED,
  ADMINISTRATION_CREATE_OPERATING_SYSTEM_REQUESTED,
  ADMINISTRATION_CREATE_OPERATING_SYSTEM_SUCCESSED,
  ADMINISTRATION_CREATE_OPERATING_SYSTEM_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_SUCCESSED,
  ADMINISTRATION_ORAGANIZATION_TIMEZONE_FAILED,
  ORGANIZATION_INFOMATION_POST_REQUESTED,
  ORGANIZATION_INFOMATION_POST_SUCCESSED,
  ORGANIZATION_INFOMATION_POST_FAILED,
  EMAIL_CONFIGURATION_POST_SUCCESSED,
  EMAIL_CONFIGURATION_POST_REQUESTED,
  EMAIL_CONFIGURATION_POST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED,
  ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_VENDORS_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_VENDORS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_VENDORS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_PUBLISHERS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_SUCCESSED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONIO_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_THREAT_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_CASE_FAMILY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_EDIT_OPERATING_SYSTEM_REQUESTED,
  ADMINISTRATION_EDIT_OPERATING_SYSTEM_SUCCESSED,
  ADMINISTRATION_EDIT_OPERATING_SYSTEM_FAILED,
  ADMINISTRATION_DELETE_OPERATING_SYSTEM_REQUESTED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
  ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_REQUESTED,
  ADMINISTRATION_EDIT_DEPARTMENT_REQUESTED,
  ADMINISTRATION_EDIT_DEPARTMENT_SUCCESSED,
  ADMINISTRATION_EDIT_DEPARTMENT_FAILED,
  ADMINISTRATION_DELETE_DEPARTMENT_REQUESTED,
  ADMINISTRATION_DELETE_DEPARTMENT_SUCCESSED,
  ADMINISTRATION_DELETE_DEPARTMENT_FAILED,
  ADMINISTRATION_CASES_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_LIST_FAILED,
  ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED,
  ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_UPDATE_FAILED,
  ADMINISTRATION_CASES_CATEGORY_DELETE_FAILED,
  ADMINISTRATION_CASES_CATEGORY_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_CATEGORY_CREATE_FAILED,
  ADMINISTRATION_CASES_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_FAILED,
  ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_FAILED,
  ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_FAILED,
  ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_FAILED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_PRIMARY_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED,
  ADMINISTRATION_CASES_DISPOSITION_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_DISPOSITION_DELETE_FAILED,
  ADMINISTRATION_CASES_DISPOSITION_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_DISPOSITION_UPDATE_FAILED,
  ADMINISTRATION_CASES_DISPOSITION_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_DISPOSITION_CREATE_FAILED,
  ADMINISTRATION_CASES_DISPOSITION_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITION_DELETE_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITION_CREATE_REQUESTED,
  ADMINISTRATION_CASES_DISPOSITIONS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_FAILED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_FAILED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_FAILED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_REQUESTED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_SUCCESSED,
  ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_FAILED,
  ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED,
  ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED,
  ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
  ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_SUCCESSED,
  ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_FAILED,
  ADMINISTRATION_DELETE_ASSET_TYPE_REQUESTED,
  ADMINISTRATION_DELETE_ASSET_TYPE_SUCCESSED,
  ADMINISTRATION_DELETE_ASSET_TYPE_FAILED,
  ADMINISTRATION_SUB_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED,
  ADMINISTRATION_VULNERABILITY_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_FAILED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_SUCCESSED,
  ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_FAILED,
  ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_FAILED,
  ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_FAILED,
  ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED,
  ADMINISTRATION_CASES_LOCATION_DELETE_FAILED,
  ADMINISTRATION_CASES_LOCATION_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_LOCATION_DELETE_REQUESTED,
  ADMINISTRATION_CASES_LOCATION_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_LOCATION_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_LOCATION_UPDATE_FAILED,
  ADMINISTRATION_CASES_LOCATION_CREATE_FAILED,
  ADMINISTRATION_CASES_LOCATION_CREATE_REQUESTED,
  ADMINISTRATION_CASES_LOCATION_CREATE_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_DELETE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_CREATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_CREATE_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_CREATE_FAILED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_UPDATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_UPDATE_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_UPDATE_FAILED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_REQUESTED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_SEVERITY_REQUESTED,
  ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED,
  ADMINISTRATION_VULNERABILITY_THREAT_SUCCESSED,
  ADMINISTRATION_VULNERABILITY_THREAT_FAILED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_DELETE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_TECHNICAL_NON_UPDATE_REQUESTED,
  ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_CREATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
  ADMINISTRATION_THREAT_INTEL_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_UPDATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED,
  ADMINISTRATION_CREATE_ASSET_TYPE_REQUESTED,
  ADMINISTRATION_CREATE_ASSET_TYPE_SUCCESSED,
  ADMINISTRATION_CREATE_ASSET_TYPE_FAILED,
  ADMINISTRATION_EDIT_ASSET_TYPE_REQUESTED,
  ADMINISTRATION_EDIT_ASSET_TYPE_SUCCESSED,
  ADMINISTRATION_EDIT_ASSET_TYPE_FAILED,
  ADMINISTRATION_CASES_NCISS_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CREATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CREATE_FAILED,
  ADMINISTRATION_CASES_NCISS_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_UPDATE_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_FAILED,
  ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED,
  ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED,
  ADMINISTRATION_GET_DISPOSITION_LIST_SUCCESSED,
  ADMINISTRATION_GET_DISPOSITION_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_FAILED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_FAILED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_FAILED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_REQUESTED,
  ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_DISPOSITION_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_DISPOSITION_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_DISPOSITION_DELETE_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_SUCCESSED,
  ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED,
  ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_DELETE_LIST_REQUESTED,
  INGESTION_TYPE_REQUESTED,
  INGESTION_WIDGET_REQUESTED,
  INGESTION_FREQUENCY_REQUESTED,
  INGESTION_APPLICATION_REQUESTED,
  INGESTION_USERS_REQUESTED,
  INGESTION_ACTION_REQUESTED,
  INGESTION_TYPE_SUCCESSED,
  INGESTION_TYPE_FAILED,
  INGESTION_ACTION_SUCCESSED,
  INGESTION_ACTION_FAILED,
  INGESTION_USERS_SUCCESSED,
  INGESTION_USERS_FAILED,
  INGESTION_APPLICATION_SUCCESSED,
  INGESTION_APPLICATION_FAILED,
  INGESTION_FREQUENCY_SUCCESSED,
  INGESTION_FREQUENCY_FAILED,
  INGESTION_WIDGET_SUCCESSED,
  INGESTION_WIDGET_FAILED,
  ENABLE_ADVISORY_REQUESTED,
  ENABLE_ADVISORY_SUCCESSED,
  ENABLE_ADVISORY_FAILED,
  ADMINISTRATION_AUTOMATION_INGESTION_CREATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_INGESTION_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED,
  ACTIONS_TYPE_REQUESTED,
  ACTIONS_TYPE_SUCCESSED,
  ACTIONS_TYPE_FAILED,
  ACTIONS_SCRIPT_TYPE_REQUESTED,
  ACTIONS_SCRIPT_TYPE_SUCCESSED,
  ACTIONS_SCRIPT_TYPE_FAILED,
  ACTIONS_IO_TYPE_REQUESTED,
  ACTIONS_IO_TYPE_SUCCESSED,
  ACTIONS_IO_TYPE_FAILED,
  ACTIONS_APPLICATION_REQUESTED,
  ACTIONS_APPLICATION_SUCCESSED,
  ACTIONS_APPLICATION_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONS_CREATE_LIST_REQUESTED,
  GET_INGESTION_MULTI_DATA_REQUESTED,
  GET_INGESTION_MULTI_DATA_SUCCESSED,
  GET_INGESTION_MULTI_DATA_FAILED,
  GET_INGESTION_MAPPING_REQUESTED,
  GET_INGESTION_MAPPING_SUCCESSED,
  GET_INGESTION_MAPPING_FAILED,
  ADD_INGESTION_MULTI_DATA_REQUESTED,
  ADD_INGESTION_MULTI_DATA_SUCCESSED,
  ADD_INGESTION_MULTI_DATA_FAILED,
  ADMINISTRATION_LOGS_APPS_LIST_REQUESTED,
  APP_LOGS_NOTIFICATION_LIST_REQUESTED,
  ADMINISTRATION_LOGS_LIST_SUCCESSED,
  ADMINISTRATION_LOGS_LIST_FAILED,
  APP_LOGS_NOTIFICATION_LIST_SUCCESSED,
  APP_LOGS_NOTIFICATION_LIST_FAILED,
  ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED,
  ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED,
  ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED,
  ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED,
  ADMINISTRATION_PRODUCTS_LIST_SUCCESSED,
  ADMINISTRATION_PRODUCTS_LIST_FAILED,
  ADMINISTRATION_PRODUCT_FAILED_REGISTER_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_LIST_FAILED,
  ADMINISTRATION_ACCESS_USERS_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_SUCCESSED,
  ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED,
  ADMINISTRATION_ACCESS_PERMISSION_REQUESTED,
  ADMINISTRATION_ACCESS_PERMISSION_SUCCESSED,
  ADMINISTRATION_ACCESS_PERMISSION_FAILED,
  ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED,
  ADMINISTRATION_ACCESS_DASHBOARDS_SUCCESSED,
  ADMINISTRATION_ACCESS_DASHBOARDS_FAILED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_SUCCESSED,
  ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED,
  ADMINISTRATION_ACCESS_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_GROUP_SUCCESSED,
  ADMINISTRATION_ACCESS_GROUP_FAILED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_SUCCESSED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_USERS_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_USERS_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_UPDATE_SUCCESS,
  ADMINISTRATION_ACCESS_USER_INVITE_REQUESTED,
  ADMINISTRATION_ACCESS_USER_INVITE_SUCCESSED,
  ADMINISTRATION_ACCESS_USER_INVITE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUPS_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED,

  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED,

  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LIST_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LIST_SUCCESSED,
  ADMINISTRATION_NOTIFICATIONS_LIST_FAILED,
  ADMINISTRATION_NOTIFICATIONS_UPDATE_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_UPDATE_SUCCESSED,
  ADMINISTRATION_NOTIFICATIONS_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_REQUESTED,
  ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED,
  ADMINISTRATION_FIELD_SETTING_LIST_SUCCESSED,
  ADMINISTRATION_FIELD_SETTING_LIST_FAILED,
  ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED,
  ADMINISTRATION_FIELD_SETTING_UPDATE_SUCCESSED,
  ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED,
  ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED,
  ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_REQUESTED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_SUCCESSED,
  ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_SUCCESSED,
  ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_SUCCESSED,
  ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_SUCCESSED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_REQUESTED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_SUCCESSED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_SUCCESSED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_LIST_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_REQUESTED,
  ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_SUCCESSED,
  ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_FAILED,
  ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_SUCCESSED,
  ASSET_CLASSIFICATION_CHANGE_TITLE_SUCCESSED,
  ASSET_CLASSIFICATION_CHANGE_TITLE_FAILED,
  ASSET_CLASSIFICATION_FORMULA_CHANGE_SUCCESSED,
  ASSET_CLASSIFICATION_FORMULA_CHANGE_FAILED,
  ASSET_CLASSIFICATION_CHANGE_TITLE_REQUESTED,
  ASSET_CLASSIFICATION_FORMULA_CHANGE_REQUESTED,
  ASSET_CLASSIFICATION_GET_FORMULA_SUCCESSED,
  ASSET_CLASSIFICATION_GET_FORMULA_FAILED,
  ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_SUCCESSED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED,
  ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_SUCCESSED,
  ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_FAILED,
  ASSET_CLASSIFICATION_INTEGIRTY_DELETE_SUCCESSED,
  ASSET_CLASSIFICATION_INTEGIRTY_DELETE_FAILED,
  ASSET_CLASSIFICATION_INTEGIRTY_CREATE_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_CREATE_SUCCESSED,
  ASSET_CLASSIFICATION_INTEGIRTY_CREATE_FAILED,
  ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_REQUESTED,
  ASSET_CLASSIFICATION_INTEGIRTY_DELETE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_CREATE_FAILED,
  ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_FAILED,
  ASSET_CLASSIFICATION_AVAILABILITY_CREATE_SUCCESSED,
  ASSET_CLASSIFICATION_AVAILABILITY_DELETE_SUCCESSED,
  ASSET_CLASSIFICATION_AVAILABILITY_DELETE_FAILED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_CREATE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_DELETE_REQUESTED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_SUCCESSED,
  ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED,
  ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_SUCCESSED,
  ADMINISTRATION_GET_TABS_HEADING_REQUESTED,
  ADMINISTRATION_GET_TABS_HEADING_SUCCESSED,
  ADMINISTRATION_GET_TABS_HEADING_FAILED,
  ADMINISTRATION_CASES_SLA_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SLA_LIST_FAILED,
  ADMINISTRATION_CASES_SLA_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_SLA_DELETE_FAILED,
  ADMINISTRATION_CASES_SLA_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_SLA_UPDATE_FAILED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED,
  ADMINISTRATION_CASES_SLA_DELETE_REQUESTED,
  ADMINISTRATION_CASES_SLA_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED,
  ADMINISTRATION_CASES_NCISS_DELETE_LIST_SUCCESSED,
  ADMINISTRATION_CASES_NCISS_DELETE_LIST_FAILED,
  ADMINISTRATION_CASES_NCISS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_FAILED,
  ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED,
  ADMINISTRATION_RISK_MATRIX_LIST_FAILED,
  ADMINISTRATION_RISK_MATRIX_LIST_SUCCESSED,
  ADMINISTRATION_RESET_RISK_MATRIX_REQUESTED,
  ADMINISTRATION_RESET_RISK_MATRIX_FAILED,
  ADMINISTRATION_RESET_RISK_MATRIX_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_REQUESTED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_FAILED,
  ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_SUCCESSED,
  ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_SUCCESSED,
  AUTOMATION_ACTION_DUPLICATE_SUCCESSED,
  AUTOMATION_ACTION_DUPLICATE_FAILED,
  AUTOMATION_ACTION_DUPLICATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED,
  ADMINISTRATION_CREATE_RISK_MATRIX_REQUESTED,
  ADMINISTRATION_CREATE_RISK_MATRIX_SUCCESSED,
  ADMINISTRATION_CREATE_RISK_MATRIX_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONS_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_DELETE_LIST_REQUESTED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_SUCCESSED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED,
  ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED,
  ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_REQUESTED,
  ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_SUCCESSED,
  ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_FAILED,
  ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_REQUESTED,
  ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_SUCCESSED,
  ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_FAILED,
  AUTOMATION_ACTION_CONFIGURATION_SUCCESSED,
  AUTOMATION_ACTION_CONFIGURATION_FAILED,
  AUTOMATION_ACTION_CONFIGURATION_REQUESTED,
  AUTOMATION_ACTION_CONFIGURATION_POST_SUCCESSED,
  AUTOMATION_ACTION_CONFIGURATION_POST_FAILED,
  AUTOMATION_ACTION_CONFIGURATION_POST_REQUESTED,
  AUTOMATION_ACTION_VIEW_SCRIPT_SUCCESSED,
  AUTOMATION_ACTION_VIEW_SCRIPT_FAILED,
  AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED,
  AUTOMATION_ACTION_MULTIDATA_POST_REQUESTED,
  AUTOMATION_ACTION_MULTIDATA_POST_FAILED,
  AUTOMATION_ACTION_MULTIDATA_POST_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINER_LIST_SUCCESS,
  ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED,
  ADMINISTRATION_CASES_DESPOSITION_SUCCESS,
  ADMINISTRATION_CASES_DESPOSITION_FAILED,
  ADMINISTRATION_CASES_CATEGORY_SUCCESS,
  ADMINISTRATION_CASES_CATEGORY_FAILED,
  ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED,
  ADMINISTRATION_CASES_DESPOSITION_REQUESTED,
  ADMINISTRATION_CASES_CATEGORY_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_FAILED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
  ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_SUCCESSED,
  ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_SLA_CREATE_REQUESTED,
  ADMINISTRATION_CASES_SLA_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_SLA_CREATE_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_UPDATE_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_UPDATE_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_UPDATE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_DELETE_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_DELETE_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_DELETE_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_SUCCESSED,
  ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_FAILED,
  ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_REQUESTED,
  ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED,
  ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED_Old,
  ADMINISTRATION_PRODUCT_SETTINGS_PATCH_SUCCESSED,
  ADMINISTRATION_PRODUCT_SETTINGS_PATCH_FAILED,
  ADMINISTRATION_PRODUCT_SETTINGS_PATCH_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_REQUESTED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_SUCCESSED,
  ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_FAILED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_REQUESTED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_SUCCESSED,
  ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_FAILED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_FAILED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_REQUESTED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_SUCCESSED,
  ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_FAILED,
  ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_REQUESTED,
  ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_SUCCESS,
  ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_FAILED,
  ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_SUCCESSED,
  ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_FAILED,
  ADMINISTRATION_NOTIFICATIONS_LOG_LIST_REQUESTED,
  ADMINISTRATION_NOTIFICATIONS_LOG_LIST_SUCCESSED,
  ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED,

  ADMINISTRATION_CASES_LESSON_LIST_REQUESTED,
  ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED,
  ADMINISTRATION_CASES_LESSON_LIST_FAILED,
  ADMINISTRATION_CASES_LESSON_DELETE_FAILED,
  ADMINISTRATION_CASES_LESSON_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_LESSON_DELETE_REQUESTED,
  ADMINISTRATION_CASES_LESSON_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_LESSON_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_LESSON_UPDATE_FAILED,
  ADMINISTRATION_CASES_LESSON_CREATE_FAILED,
  ADMINISTRATION_CASES_LESSON_CREATE_REQUESTED,
  ADMINISTRATION_CASES_LESSON_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,
  ADMINISTRATION_CASES_CONTAINED_BY_DELETE_FAILED,
  ADMINISTRATION_CASES_CONTAINED_BY_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINED_BY_DELETE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_FAILED,
  ADMINISTRATION_CASES_CONTAINED_BY_CREATE_FAILED,
  ADMINISTRATION_CASES_CONTAINED_BY_CREATE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINED_BY_CREATE_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_FAILED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_SUCCESSED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_FAILED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_FAILED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_REQUESTED,
  ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_SUCCESSED,
  INGESTION_OUTPUT_REQUESTED,
  INGESTION_OUTPUT_FAILED,
  INGESTION_OUTPUT_SUCCESSED,
  UPDATE_INGESTION_OUTPUT_REQUESTED,
  UPDATE_INGESTION_OUTPUT_FAILED,
  UPDATE_INGESTION_OUTPUT_SUCCESSED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_SUCCESSED,
  ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED,
  ADMINISTRATION_AFFECTED_VENDOR_CREATE_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_CREATE_SUCCESSED,
  ADMINISTRATION_AFFECTED_VENDOR_CREATE_FAILED,
  ADMINISTRATION_AFFECTED_VENDOR_DELETE_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_DELETE_SUCCESSED,
  ADMINISTRATION_AFFECTED_VENDOR_DELETE_FAILED,
  ADMINISTRATION_AFFECTED_VENDOR_UPDATE_REQUESTED,
  ADMINISTRATION_AFFECTED_VENDOR_UPDATE_SUCCESSED,
  ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_SUCCESSED,
  ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED,
  ADMINISTRATION_AFFECTED_PRODUCT_CREATE_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_CREATE_SUCCESSED,
  ADMINISTRATION_AFFECTED_PRODUCT_CREATE_FAILED,
  ADMINISTRATION_AFFECTED_PRODUCT_DELETE_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_DELETE_SUCCESSED,
  ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED,
  ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_REQUESTED,
  ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_SUCCESSED,
  ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_FAILED,
  UPDATE_SLA_STATUS_REQUESTED,
  UPDATE_SLA_STATUS_PROCESSING,
  UPDATE_SLA_STATUS_SUCCESSED,
  UPDATE_SLA_STATUS_FAILED,
  PRE_INGESTION_RULE_SUCCESSED,
  PRE_INGESTION_RULE_FAILED,
  PRE_INGESTION_RULE_REQUESTED,
  PRE_INGESTION_RULE_CREATE_REQUESTED,
  PRE_INGESTION_RULE_CREATE_SUCCESSED,
  PRE_INGESTION_RULE_CREATE_FAILED,
  PRE_INGESTION_RULE_UPDATE_REQUESTED,
  PRE_INGESTION_RULE_UPDATE_SUCCESSED,
  PRE_INGESTION_RULE_UPDATE_FAILED,
  PRE_INGESTION_RULE_DELETE_REQUESTED,
  PRE_INGESTION_RULE_DELETE_SUCCESSED,
  PRE_INGESTION_RULE_DELETE_FAILED,
  ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED,
} from '../../constants/actionTypes';

import {
  assetsGroupsAPI,
  organizationListAPI,
  classificationListAPI,
  updateClassificationListAPI,
  deleteClassificationListAPI,
  createClassificationListAPI,
  ownersListAPI,
  deleteOwnerListAPI,
  createOwnerListAPI,
  updateOwnerListAPI,
  createOrganization,
  deleteOrganization,
  getselectedOraganization,
  setselectedOraganization,
  organizationUserListAPI,
  assetsSourceListAPI,
  assetsSubGroupListAPI,
  createAssetGroupAPI,
  deleteAssetsGroupAPI,
  editAssetGroupAPI,
  administrationListAPI,
  organizationMemberDetails,
  oraganizationslist,
  createOraganizationUser,
  updateOraganizationUser,
  deleteOraganizationUser,
  administrationCreateListAPI,
  administrationUpdateListAPI,
  administrationDeleteListAPI,
  administrationChangeStatusAPI,
  assetsSubGroupTabAPI,
  assetsSubGroupDropDownAPI,
  createAssetSubGroupAPI,
  deleteAssetsSubGroupAPI,
  editAssetSubGroupAPI,
  administrationDuplicateAPI,
  administrationDeleteAPI,
  administrationWorkflowCategoryListAPI,
  administrationWorkflowSubCategoryListAPI,
  administrationWorkflowTaskCategoryListAPI,
  administrationworkFlowMitreTacticsListAPI,
  administrationWorkflowMitreTechniquesListAPI,
  administrationWorkflowMitreSubTechniquesListAPI,
  administrationWorkflowTagsListAPI,
  administrationCreateWorkflowTaskAPI,
  administrationUpdateWorkflowTaskAPI,
  assetsValueTabAPI,
  createAssetValueAPI,
  editAssetValueAPI,
  deleteAssetsValueAPI,
  editAssetTitleValueAPI,
  assetsOsListAPI,
  createOperatingSystemAPI,
  threatRegisterValueApi,
  threatRegisterVulnerabilityApi,
  createRiskManagementThreatRegisterAPI,
  deleteRiskManagementThreatRegisterAPI,
  updateRiskManagementAPI,
  updateRiskManagementThreatRegisterAPI,
  oraganizationTimezone,
  updateOrganization,
  oraganizationPost,
  emailConfigPost,
  RiskManagementBusinesImpactChangeTitleAPI,
  automationListAPI,
  appLogsNotificationsAPI,
  automationCreateListAPI,
  automationUpdateListAPI,
  automationActionIOValidationListAPI,
  editAssetOsAPI,
  deleteOsAPI,
  assetsDepartmentListAPI,
  createAssetDepartmentAPI,
  editAssetDepartmentAPI,
  deleteAssetDepartmentAPI,
  administratorCasesCategory,
  administratorCasesCategoryDelete,
  administratorCasesCategoryUpdate,
  administratorCasesCategoryCreate,
  administratorCasesSubCategory,
  administratorCasesSubCategoryUpdate,
  administratorCasesSubCategoryDelete,
  administratorCasesSubCategoryCreate,
  administratorCasesCategoryList,
  assetsBusinessGroupListAPI,
  createAssetBusinessGroupAPI,
  editAssetBusinessGroupAPI,
  deleteAssetBusinessGroupAPI,
  editAssetBusinessGroupTitleAPI,
  assetsTypeListAPI,
  editAssetTypeTitleAPI,
  deleteAssetTypeAPI,
  administratorCasesDispositionsList,
  administratorCasesDispositionDelete,
  administratorCasesDispositionUpdate,
  administratorCasesDispositionCreate,
  administratorCasesDispositionsItem,
  administratorCasesSubDispositionsList,
  administratorCasesSubDispositionCreate,
  administratorSubCasesDispositionUpdate,
  administratorCasesSubDispositionDelete,
  vulnerabilityListAPI,
  assetsDropDownListAPI,
  subGroupDropDownListAPI,
  ownerDropDownListAPI,
  departmentDropDownListAPI,
  administratorCasesDetectionMethodsList,
  administratorCasesDetectionMethodDelete,
  administratorCasesDetectionMethodUpdate,
  administratorCasesDetectionMethodCreate,
  administratorSubCasesDispositionDropdownList,
  administratorCasesLocationsList,
  administratorCasesLocationDelete,
  administratorCasesLocationCreate,
  administratorCasesLocationUpdate,
  deleteVulnerabilityAPI,
  createVulnerabilityAPI,
  updateVulnerabilityAPI,
  threatIntelListAPI,
  createthreatIntelAPI,
  deletethreatIntelAPI,
  updateThreatIntelAPI,
  createAssetTypeAPI,
  editAssetTypeListAPI,
  administratorCasesNciss,
  administratorCasesNcissCreate,
  administratorCasesNcissUpdate,
  casesNcissDropDownListAPI,
  administratorCasesNcisCategories,
  administratorNcisCategoryUpdate,
  administratorNcisCategoryCreate,
  administratorNcisCategoryDelete,
  administratorCasesNcissPeriorities,
  administratorCasesNcissPerioritieDelete,
  administratorCasesNcissPerioritieUpdate,
  administratorCasesNcissPerioritieCreate,
  assetValueDropDownListAPI,
  allIngestionAPI,
  enableAdvisoryAPI,
  ingestionActionAPI,
  addMultiData,
  sessionPassword,
  sessionPasswordUpdate,
  userAccessUpdate,
  userAccessInviteUser,
  accessControlGroups,
  updateAccessContorlGroup,
  createAccessControlGroup,
  deleteAccessContorlGroup,
  accessControlRoles,
  updateAccessControlRole,
  createAccessControlRole,
  deleteAccessControlRole,
  accessControlActions,
  updateAccessControlAction,
  createAccessControlAction,
  deleteAccessControlaction,
  accessControlActionsGroup,
  accessControlActionsGroupTab,
  updateAccessControlActionGroup,
  deleteAccessControlActionGroup,
  createAccessControlActionGroup,
  accessControlAuthSettings,
  accessControlAuthSettingAuthencticationType,
  accessControlAuthSettingAuthencticationVendors,
  updateAccessControlThirdPartyAuth,
  deleteAccessControlThirdPartyAuth,
  createAccessControlThirdPartyAuth,
  accessControlPrivileges,
  accessControlAllPrivileges,
  accessControlProductSettingFeedback,
  deleteProductSettingFeedback,
  accessControlProductSettingWidgets,
  updateProductSettingWidget,
  createProductSettingWidget,
  deleteProductSettingWidget,
  deleteProductSettingLicense,
  downloadProductSettingLicense,
  accessControlWidgetDataSource,
  accessControlWidgetDataGroup,
  productSettingLicense,
  updateProductSettingLicense,
  notificationListApi,
  notificationUpdateApi,
  accessControlProductSettingBackup,
  deleteProductSettingBackup,
  createBackupList,
  notifyUserList,
  dowloadBackup,
  getBackupSettingAPI,
  getSftpConfigureAPI,
  fieldSettingListApi,
  fieldSettingUpdateApi,
  fieldCaseSettingListApi,
  caseFieldSettingUpdateApi,
  caseFieldSettingDuplicateApi,
  probabilityGetDefaultAPI,
  Riskprobability,
  deleteRiskprobability,
  updateRiskprobability,
  createRiskprobability,
  changeProbabilityTitle,
  updateFormula,
  getFormula,
  getInitOraganizationInfo,
  getInitEmailConfig,
  getIntConnectivity,
  accessControlPrivilegeGroup,
  accessControlPrivilegeDelete,
  accessControlPrivilegeUpdate,
  accessControlPrivilegeCreate,
  assetConfidentialityApi,
  updateConfidentialityApi,
  deleteConfidentialityApi,
  classificationTitleChange,
  classificationFormulaChange,
  classificationGetFormula,
  createConfidentialityApi,
  assetIntegrityApi,
  updateIntegrityApi,
  deleteIntegrityApi,
  createIntegrityApi,
  assetAvailabilityApi,
  updateAvailabilityApi,
  createAvailabilityApi,
  deleteAvailabilityApi,
  caseGetTabsHeadingsApi,
  administratorCasesSlaList,
  administratorCaseseDelete,
  administratorCasesSlaCreate,
  administratorCasesSlaLogs,
  administratorCasesSlaViewRule,
  administratorCasesNcissDelete,
  getRiskMatrixAPi,
  resetRiskMatrixAPi,
  getRiskMatrixRangeFromApi,
  getRiskMatrixRangeToApi,
  getRiskMatrixColorListApi,
  automationActionDuplication,
  createRiskMatrixAPi,
  automationDeleteListAPI,
  automationActionOutput,
  executeScript,
  saveIntegration,
  automationActionConfigurations,
  automationActionConfigurationsPost,
  automationViewAction,
  automationActionsMultiPostData,
  getContainerList,
  getCasesDespositionList,
  getCasesCategoriesList,
  technicalSeverityLevel,
  NontechnicalSeverityLevel,
  technicalSeverityLevelCreate,
  technicalSeverityLevelDelete,
  NontechnicalSeverityLevelDelete,
  NontechnicalSeverityLevelUpdated,
  NontechnicalSeverityLevelCreated,
  technicalSeverityLevelUpdate,
  uploadLicenseApi,
  createLicenceRequest,
  getModuleSources,
  importBackup,
  configureRestoreBackupAPI,
  sftpconfigureAPI,
  RestoreBackup,
  patchUploadRequest,
  productSettingHealthAPI,
  productSettingConnectivityAPI,
  getRiskMetaAPI,
  administratorCasesSlaUpdate,
  riskMetaLabelUpdate,
  riskMetaUpdateAPI,
  getAssociateThreatActor,
  getThreatActor,
  associateThreatActorListAPI,
  administratorThreatActorCreate,
  administratorThreatActorUpdate,
  administratorThreatActorDelete,
  getAssociatedThreatActor,
  administratorAssociatedThreatActorCreate,
  administratorAssociatedThreatActorUpdate,
  administratorAssociatedThreatActorDelete,
  userResetPassword,
  logNotificationUpdateApi,
  logNotificationListApi,
  administratorCasesLessonsList,
  administratorCasesLessonUpdate,
  administratorCasesLessonCreate,
  administratorCasesLessonDelete,
administratorCasesContainmentStatusList,
administratorCasesContainmentStatusUpdate,
administratorCasesContainmentStatusCreate,
administratorCasesContainmentStatusDelete,
administratorCasesContainedByList,
administratorCasesContainedByUpdate,
administratorCasesContainedByCreate,
administratorCasesContainedByDelete,
getIngestionOutputApi,
setIngestionOutputApi,
affectedVendorListApi,
affectedProductListApi,
createAffectedVendorApi,
updateAffectedVendorApi,
deleteAffectedVendorApi,
createAffectedProductApi,
updateAffectedProductApi,
deleteAffectedProductApi,
updateSlaStatusApi,
preIngestionRuleListApi,
createPreIngestionRuleApi,
updatePreIngestionRuleApi,
deletePreIngestionRuleApi,
allDashboardsApi,
} from '../../api/administrationApi';

import {
  oraganizationUsers,
  ongetSelectedOraganization,
  getOrganizationList,
  administratorCasesCategoriesList,
  administratorCasesSubCategoriesList,
  administratorCasesDispositions as administratorCasesDispositionsDataList,
  administratorSubCasesDispositions,
  administratorCasesDitectionsMethod,
  administratorCasesLocationList,
  administratorCasesNcissList,
  administratorCasesNcisscategories,
  administratorCasesNcissPerioritiesList,
  getSessionPassword,
  accessUsersList as accessControlUserlist,
  onGetAccessControlGroupsTab,
  onGetAccessControlRolesTab,
  onGetAccessControlActionTab,
  onGetAccessControlActionGroupTab,
  onGetAccessControlAuthSettingList,
  onGetListOfProductFeedback,
  onGetWidgetList,
  onGetLicencesInfo,
  onGetListofBackups,
  riskManagementProbablity,
  onGetListOfPrivileges,
  getAssetValueList,
  getAssetOperatingSystemList,
  assetConfidentiality as assetConfidentialityList,
  getIntegirtyListRequest,
  getAvaiabilityListRequest,
  getAllTabbsHeading,
  getWorkflowList,
  administratorCasesSlaList as administratorCasesSlaAction,
  riskManagementThreatRegisterList,
  riskManagementThreatValueList,
  riskManagementControlRegisterList,
  getCategoryList,
  automationVendorsList,
  threatIntelCategoryList as threatIntelCategoryListAction,
  threatIntelSubCategoryList as threatIntelSubCategoryListAction,
  getThreatDispositionList,
  vulnerabilityTechnicalList,
  riskManagementComplianceList,
  automationPublishersList,
  automationActionIOList,
  automationThreatList,
  automationFamilyList,
  automationActionsList as automationActionsLists,
  riskManagementBusinessImpactList,
  getRiskMtrix,
  vulnerabilityNonTechnicalList,
  automationIngestionList as automationIngestionLists,
  productsRegisterList as productsRegisterListAction,
  valTechserverity,
  valNoTechserverity,
  onGetRiskMetaList,
  getAssetSubGroupList,
  getOwnersList,
  getAssetBusinessGroupList as getAssetBusinessGroupAction,
  threatIntelThreatActorList,
  threatIntelAssociatedThreatActorList,
  AffectedVendorList,
  AffectedProductList,
  preIngestionRuleList,
  vulnerabilityTechnicalSeverityList
} from '../../actions/administration';
import { getUserProfile } from '../../actions/user';
import { onGetListOfDashboard } from "../../actions/dashboard";
import {
  showToastError,
  showToastSuccess,
  showToastLoader,
  dismissToastLoader,
  showToastProgress
} from '../../utils/toasts'
import { removeSpacesFromFields } from '../../utils/helper.utils';

export function* organizationList(action) {
  try {
    const organizationData = yield call(organizationListAPI, action.payload);
    if (organizationData.success) {
      yield put({
        type: ADMINISTRATION_ORGANIZATION_LIST_SUCCESSED,
        data: organizationData,
      });
    } else {
      yield put({ type: ADMINISTRATION_ORGANIZATION_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ORGANIZATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* classificationList(action) {
  try {
    const organizationData = yield call(classificationListAPI, action.payload);

    if (organizationData.success) {
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED,
        data: organizationData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateList(action) {
  const loaderHandle = showToastLoader('Operation in progress...')
  try {
    const organizationData = yield call(
      updateClassificationListAPI,
      action.payload
    );
    if (organizationData.success) {
      showToastSuccess(`${`${organizationData?.data[0]}`}`, loaderHandle)
      const classificationListData = yield call(classificationListAPI, {
        queryItem: action.payload.queryItem,
      });

      if (classificationListData.success) {
        yield put({
          type: ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED,
          data: classificationListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_SUCCESSED,
        data: organizationData,
      });
    } else {
      showToastError('Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteClassification(action) {
  const loaderHandle = showToastLoader('Operation in progress...')
  try {
    const organizationData = yield call(
      deleteClassificationListAPI,
      action.payload
    );

    if (organizationData.success) {
      const classificationListData = yield call(classificationListAPI, {
        queryItem: action.payload.queryItem,
      });

      if (classificationListData.success) {
        yield put({
          type: ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED,
          data: classificationListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_CLASSIFICATION_DELETE_SUCCESSED,
        data: organizationData,
      });
      showToastSuccess(`${organizationData?.data[0]}`, loaderHandle)
    } else {
      showToastError('Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_CLASSIFICATION_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createClassification(action) {
  const loaderHandle = showToastLoader('Operation in progress...')
  try {
    const organizationData = yield call(
      createClassificationListAPI,
      action.payload
    );

    const classificationListData = yield call(classificationListAPI, {
      queryItem: action.payload.queryItem,
    });

    if (classificationListData.success) {
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_LIST_SUCCESSED,
        data: classificationListData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_LIST_FAILED,
        data: null,
      });
    }
    if (organizationData.success) {
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_CREATE_LIST_SUCCESSED,
        data: organizationData,
      });
      showToastSuccess(`${organizationData?.data[0]}`, loaderHandle)
    } else {
      showToastError('Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_CLASSIFICATION_CREATE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_CLASSIFICATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* ownerList(action) {
  try {
    const organizationData = yield call(ownersListAPI, action.payload);

    if (organizationData.success) {
      yield put({
        type: ADMINISTRATION_OWNERS_LIST_SUCCESSED,
        data: organizationData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_OWNERS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_OWNERS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteOwner(action) {
  const loaderHandle = showToastLoader('Deleting asset owner...')
  try {
    const deltedOwnerInfo = yield call(deleteOwnerListAPI, action.payload);
    if (deltedOwnerInfo.success) {
      showToastSuccess('Asset owner deleted', loaderHandle)
      const ownerListData = yield call(ownersListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (ownerListData.success) {
        yield put({
          type: ADMINISTRATION_OWNERS_LIST_SUCCESSED,
          data: ownerListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_OWNERS_LIST_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_OWNERS_DELETE_SUCCESSED,
        data: deltedOwnerInfo,
      });
    } else {
      showToastError('Deleting failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_OWNERS_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Deleting failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_OWNERS_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateOwner(action) {
  const loaderHandle = showToastLoader('Updating asset owner...')
  try {
    const organizationData = yield call(updateOwnerListAPI, action.payload);
    if (organizationData.success) {
      showToastSuccess('Asset owner updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_OWNERS_UPDATE_LIST_SUCCESSED,
        data: organizationData,
      });
      yield put(getOwnersList({ queryItem: action.payload.queryItem }))
    } else {
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError('Asset owner email already exist', loaderHandle)
    yield put({
      type: ADMINISTRATION_OWNERS_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createOwner(action) {
  const loaderHandle = showToastLoader('Creating asset owner...')
  try {
    const organizationData = yield call(createOwnerListAPI, action.payload);
    if (organizationData.success) {
      showToastSuccess('Asset owner created', loaderHandle)
      yield put({
        type: ADMINISTRATION_OWNERS_CREATE_LIST_SUCCESSED,
        data: organizationData,
      });
      yield put(getOwnersList({ queryItem: action.payload.queryItem }))
    } else {
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError('Asset owner email already exist', loaderHandle)
    yield put({
      type: ADMINISTRATION_OWNERS_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetGroupList(action) {
  try {
    const assetsGroupData = yield call(assetsGroupsAPI, action.payload);

    if (assetsGroupData.success) {
      yield put({
        type: ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({ type: ADMINISTRATION_ASSETS_GROUP_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSETS_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreateOraganization(action) {
  const loaderHandle = showToastLoader('Creating organization...')
  try {
    const response = yield call(createOrganization, action.payload);
    if (response.success) {
      const msg = response?.data[1]
      showToastSuccess(msg || 'Organization Created', loaderHandle)
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(getOrganizationList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_CREATE_FAILED,
        data: null,
      });
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    const msg = err?.response?.data?.data?.message
    showToastError(msg || 'Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_ORAGANIZATION_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchOragaizationInfomationPost(action) {
  const loaderHandle = showToastLoader('Updating organization info...')
  try {
    const response = yield call(oraganizationPost, action.payload);
    if (response.success) {
      yield put({
        type: ORGANIZATION_INFOMATION_POST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Organization Information updated', loaderHandle)
      //history.push('/administration?active_tab=organization&&active_sub_tab=organization_information');
      window.location.reload();
    } else {
      yield put({
        type: ORGANIZATION_INFOMATION_POST_FAILED,
        data: null,
      });
      showToastError('Organization Information update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ORGANIZATION_INFOMATION_POST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Organization Information update failed', loaderHandle)
  }
}

export function* watchEmailConfigPostRequest(action) {
  const loaderHandle = showToastLoader('Updating email configuration...')
  try {
    const response = yield call(emailConfigPost, action.payload);

    if (response.success) {
      showToastSuccess('Email configuration updated', loaderHandle)
      yield put({
        type: EMAIL_CONFIGURATION_POST_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Email configuration update failed', loaderHandle)
      yield put({
        type: EMAIL_CONFIGURATION_POST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Email configuration update failed', loaderHandle)
    yield put({
      type: EMAIL_CONFIGURATION_POST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteOraganization(action) {
  const loaderHandle = showToastLoader('Deleting organization...')
  try {
    const response = yield call(deleteOrganization, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Organization deleted', loaderHandle)
      yield put(getOrganizationList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_DELETE_FAILED,
        data: null,
      });
      showToastError('Deleteing oraganization failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ORAGANIZATION_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Deleteing oraganization failed', loaderHandle)
  }
}

export function* watchUpdateOraganization(action) {
  const loaderHandle = showToastLoader('Updating organization info...')
  try {
    const response = yield call(updateOrganization, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ORGANIZATION_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.[1]
      showToastSuccess(msg || 'Organization updated', loaderHandle)
      yield put(getOrganizationList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ORGANIZATION_UPDATE_FAILED,
        data: null,
      });
      showToastError('Organization update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ORGANIZATION_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Organization update failed', loaderHandle)
  }
}

export function* watchGetSelectionOraganization() {
  try {
    const response = yield call(getselectedOraganization);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_GET_SELECTED_ORGANIZATION_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_SELECTED_ORGANIZATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetIntialValueInfo() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getInitOraganizationInfo);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetIntialEmailConfig() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getInitEmailConfig);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetIntEmailConnectivity() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getIntConnectivity);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_GET_EMAIL_CONNECTIVITY_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_GET_EMAIL_CONNECTIVITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSetSelectionOraganization(action) {
  const loaderHandle = showToastLoader('Updating tenant...')
  try {
    const response = yield call(setselectedOraganization, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_SET_SELECTED_ORGANIZATION_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Tenant updated', loaderHandle);
      if (action.payload.module === 'true') {
        window.location.reload();
      } else {
        yield put(getUserProfile());
        yield put(ongetSelectedOraganization());
        yield put(onGetListOfDashboard());
      }
    } else {
      yield put({
        type: ADMINISTRATION_SET_SELECTED_ORGANIZATION_FAILED,
        data: null,
      });
      showToastError('Tenant update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_SET_SELECTED_ORGANIZATION_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(
      'Permission denied!! No licence found against selected tenant',
      loaderHandle
    )
  } finally {
    if(action?.callback && typeof action?.callback == 'function') action?.callback();
  }
}

export function* organizationUsersList(action) {
  try {
    const response = yield call(organizationUserListAPI, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_USERS_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_USERS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ORAGANIZATION_USERS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetSourceList(action) {
  try {
    const assetsSourceListData = yield call(
      assetsSourceListAPI,
      action.payload
    );

    if (assetsSourceListData.success) {
      yield put({
        type: ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_SUCCESSED,
        data: assetsSourceListData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetSubGroupList(action) {
  try {
    const assetsGroupData = yield call(assetsSubGroupListAPI, action.payload);

    if (assetsGroupData.success) {
      yield put({
        type: ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAssetGroup(action) {
  const loaderHandle = showToastLoader('Creating asset group...')
  try {
    const assetsGroupData = yield call(createAssetGroupAPI, action.payload);

    if (assetsGroupData.success) {
      showToastSuccess('Asset group created', loaderHandle)
      const assetsGroupListData = yield call(assetsGroupsAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsGroupListData.success) {
        yield put({
          type: ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED,
          data: assetsGroupListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_ASSETS_GROUP_LIST_FAILED,
          data: null,
        });
      }
      yield put({
        type: ADMINISTRATION_CREATE_ASSETS_GROUP_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED,
        data: null,
      });
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CREATE_ASSETS_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Group already exist', loaderHandle)
  }
}

export function* watchOraganizatioMemberList() {
  try {
    const response = yield call(organizationMemberDetails);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_MEMBERS_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ORAGANIZATION_MEMBERS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteAssetGroup(action) {
  const loaderHandle = showToastLoader('Deleting asset group...')
  try {
    const assetsGroupData = yield call(deleteAssetsGroupAPI, action.payload);

    if (assetsGroupData.success) {
      showToastSuccess('Asset group deleted', loaderHandle)
      const assetsGroupListData = yield call(assetsGroupsAPI, {
        queryItem: action.payload.queryItem,
      });

      if (assetsGroupListData.success) {
        yield put({
          type: ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED,
          data: assetsGroupListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_ASSETS_GROUP_LIST_FAILED,
          data: null,
        });
        showToastError('Asset group delete failed', loaderHandle)
      }
      yield put({
        type: ADMINISTRATION_DELETE_ASSETS_GROUPS_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_DELETE_ASSETS_GROUPS_FAILED,
        data: null,
      });
      showToastError('Asset group delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_DELETE_ASSETS_GROUPS_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset group delete failed', loaderHandle)
  }
}

export function* editAssetGroup(action) {
  const loaderHandle = showToastLoader('Updating asset group...')
  try {
    const assetsGroupData = yield call(editAssetGroupAPI, action.payload);

    if (assetsGroupData.success) {
      showToastSuccess('Asset group updated', loaderHandle)
      const assetsGroupListData = yield call(assetsGroupsAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsGroupListData.success) {
        yield put({
          type: ADMINISTRATION_ASSETS_GROUP_LIST_SUCCESSED,
          data: assetsGroupListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_ASSETS_GROUP_LIST_FAILED,
          data: null,
        });
        showToastError('Updating asset group failed', loaderHandle)
      }

      yield put({
        type: ADMINISTRATION_EDIT_ASSETS_GROUPS_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({ type: ADMINISTRATION_EDIT_ASSETS_GROUPS_FAILED, data: null });
      showToastError('Updating asset group failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_EDIT_ASSETS_GROUPS_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Group already exist', loaderHandle)
  }
}

export function* workflowList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'workflow',
    };
    const workflowData = yield call(administrationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({ type: ADMINISTRATION_WORKFLOW_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchOraganizationsList() {
  try {
    const response = yield call(oraganizationslist);
    if (response.success) {
      yield put({ type: ORAGANIZATION_SUCCESSED, data: response?.data });
    } else {
      yield put({ type: ORAGANIZATION_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: ORAGANIZATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreateOraganizationUser(action) {
  const loaderHandle = showToastLoader('Creating organization user...')
  try {
    const response = yield call(createOraganizationUser, action.payload);
    if (response.success) {
      yield put({
        type: ORAGANIZATION_USER_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Organization user created', loaderHandle)
      yield put(oraganizationUsers({ queryItem: action.query }));
    } else {
      yield put({ type: ORAGANIZATION_USER_CREATE_FAILED, data: null });
      showToastError('User creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ORAGANIZATION_USER_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('User creation failed', loaderHandle)
  }
}

export function* watchDeleteOraganizationUser(action) {
  const loaderHandle = showToastLoader('Deleting organization user...')
  try {
    const response = yield call(deleteOraganizationUser, action.id);
    if (response.success) {
      yield put({
        type: ORAGANIZATION_USER_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Organization user deleted', loaderHandle)
      yield put(oraganizationUsers({ queryItem: action.query }));
    } else {
      yield put({ type: ORAGANIZATION_USER_DELETE_FAILED, data: null });
      showToastError('Delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ORAGANIZATION_USER_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Delete failed', loaderHandle)
  }
}

export function* watchUpdateOraganizationUser(action) {
  const loaderHandle = showToastLoader('Updating organization user...')
  try {
    const response = yield call(
      updateOraganizationUser,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ORAGANIZATION_USER_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Organization user updated', loaderHandle)

      yield put(oraganizationUsers({ queryItem: action.query }));
    } else {
      yield put({ type: ORAGANIZATION_USER_UPDATE_FAILED, data: null });
      showToastError('Update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ORAGANIZATION_USER_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Update failed', loaderHandle)
  }
}

export function* watchOraganizationTimezon() {
  try {
    const response = yield call(oraganizationTimezone);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_TIMEZONE_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ORAGANIZATION_TIMEZONE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ORAGANIZATION_TIMEZONE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* categoryList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'category',
    };
    const categoryData = yield call(administrationListAPI, payload);

    if (categoryData.success) {
      yield put({
        type: ADMINISTRATION_CATEGORY_LIST_SUCCESSED,
        data: categoryData?.data,
      });
    } else {
      yield put({ type: ADMINISTRATION_CATEGORY_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CATEGORY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateCateogryList(action) {
  const loaderHandle = showToastLoader('Updating workflow category...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'category',
    };
    const categoryData = yield call(administrationUpdateListAPI, payload);

    if (categoryData.success) {
      yield put({
        type: ADMINISTRATION_CATEGORY_UPDATE_LIST_SUCCESSED,
        data: categoryData?.data,
      });
      const msg = categoryData?.data?.[1]
      yield put(getCategoryList({ queryItem: action.payload.query }))
      showToastSuccess(msg || 'Category updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_CATEGORY_UPDATE_LIST_FAILED,
        data: null,
      });
      showToastError('Category already exist', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CATEGORY_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category already exist', loaderHandle)
  }
}

export function* deleteCategory(action) {
  const loaderHandle = showToastLoader('Deleting workflow category...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'category',
    };
    const categoryData = yield call(administrationDeleteListAPI, payload);

    if (categoryData.success) {
      yield put({
        type: ADMINISTRATION_CATEGORY_DELETE_LIST_SUCCESSED,
        data: categoryData?.data,
      });
      const msg = categoryData?.data?.[1]
      showToastSuccess(msg || 'Category list deleted', loaderHandle)
      yield put(getCategoryList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_CATEGORY_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CATEGORY_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Delete failed', loaderHandle)
  }
}

export function* createCategoryList(action) {
  const loaderHandle = showToastLoader('Creating workflow category...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'category',
    };
    const categoryData = yield call(administrationCreateListAPI, payload);

    if (categoryData.success) {
      yield put({
        type: ADMINISTRATION_CATEGORY_CREATE_LIST_SUCCESSED,
        data: categoryData?.data,
      });
      const msg = categoryData?.data?.[1]
      showToastSuccess(msg || 'Category list created', loaderHandle)
      yield put(getCategoryList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_CATEGORY_CREATE_LIST_FAILED,
        data: null,
      });
      showToastError('Category already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CATEGORY_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category already exists', loaderHandle)
  }
}

export function* changeWorkFlowStatus(action) {
  const loaderHandle = showToastLoader('Changing status...')
  try {
    const response = yield call(
      administrationChangeStatusAPI,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_CHANGE_STATUS_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Workflow status changed', loaderHandle)
      yield put(getWorkflowList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_CHANGE_STATUS_FAILED,
        data: null,
      });
      showToastError('Status change failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Status change failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_WORKFLOW_CHANGE_STATUS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetSubGroupTabList(action) {
  try {
    const assetsGroupData = yield call(assetsSubGroupTabAPI, action.payload);
    if (assetsGroupData.success) {
      yield put({
        type: ADMINISTRATION_ASSET_SUB_GROUP_LIST_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_SUB_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetSubGroupDropDownList(action) {
  try {
    const assetsGroupData = yield call(
      assetsSubGroupDropDownAPI,
      action.payload
    );
    if (assetsGroupData.success) {
      yield put({
        type: ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_SUCCESSED,
        data: assetsGroupData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAssetSubGroup(action) {
  const loaderHandle = showToastLoader('Creating sub-group...')
  try {
    const assetsGroupData = yield call(createAssetSubGroupAPI, action.payload);
    if (assetsGroupData.success) {
      const msg = assetsGroupData?.[0];
      yield put({
        type: ADMINISTRATION_CREATE_ASSET_SUB_GROUP_SUCCESSED,
        data: assetsGroupData,
      });
      showToastSuccess('Asset SubGroup created', loaderHandle)
      yield put(getAssetSubGroupList({ queryItem: action.payload.queryItem }))
    } else {
      showToastError('Subgroup already exist', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CREATE_ASSET_SUB_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Subgroup already exist', loaderHandle)
  }
}

export function* deleteAssetSubGroup(action) {
  const loaderHandle = showToastLoader('Deleting sub-group...')
  try {
    const response = yield call(deleteAssetsSubGroupAPI, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_DELETE_ASSETS_GROUPS_SUCCESSED,
        data: response,
      });
      const msg = response?.[0];
      showToastSuccess(msg || 'Asset SubGroup deleted', loaderHandle)
      yield put(getAssetSubGroupList({ queryItem: action.payload.queryItem }))
    } else {
      showToastError('SubGroup delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('SubGroup delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_DELETE_ASSETS_GROUPS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* editAssetSubGroup(action) {
  const loaderHandle = showToastLoader('Updating sub-group...')
  try {
    const assetsGroupData = yield call(editAssetSubGroupAPI, action.payload);
    if (assetsGroupData.success) {
      yield put({
        type: ADMINISTRATION_EDIT_ASSET_SUB_GROUP_SUCCESSED,
        data: assetsGroupData,
      });
      const msg = assetsGroupData?.[0];
      showToastSuccess(msg || 'Asset SubGroup updated', loaderHandle)
      yield put(getAssetSubGroupList({ queryItem: action.payload.queryItem }))
    } else {
      showToastError('SubGroup update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_EDIT_ASSET_SUB_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('SubGroup update failed', loaderHandle)
  }
}

export function* duplicateWorkFlow(action) {
  const loaderHandle = showToastLoader('Duplicating workflow...')
  try {
    const duplicateData = yield call(
      administrationDuplicateAPI,
      action.payload
    );

    if (duplicateData.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_DUPLICATE_SUCCESSED,
        data: duplicateData?.data,
      });
      showToastSuccess('Workflow duplicated', loaderHandle)
      yield put(getWorkflowList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_DUPLICATE_FAILED,
        data: null,
      });
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_DUPLICATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Something went wrong', loaderHandle)
  }
}

export function* deleteWorkFlow(action) {
  const loaderHandle = showToastLoader('Deleting workflow...')
  try {
    const response = yield call(administrationDeleteAPI, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Workflow deleted', loaderHandle)
      yield put(getWorkflowList({ queryItem: action.payload.query }))
    } else {
      showToastError('Delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_WORKFLOW_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* workFlowCategoryList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationWorkflowCategoryListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_CATEGORY_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_CATEGORY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_CATEGORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* workFlowSubCategoryList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationWorkflowSubCategoryListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_SUB_CATEGORY_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_SUB_CATEGORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* workFlowTaskCategoryList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationWorkflowTaskCategoryListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_TASK_CATEGORY_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_TASK_CATEGORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* workFlowMitreTacticsList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationworkFlowMitreTacticsListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_MITRE_TACTICS_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_MITRE_TACTICS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* workFlowMitreTechniquesList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationWorkflowMitreTechniquesListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* workFlowMitreSubTechniquesList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationWorkflowMitreSubTechniquesListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* workFlowTagsList(action) {
  try {
    const deleteWorkFlow = yield call(
      administrationWorkflowTagsListAPI,
      action.payload
    );

    if (deleteWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_TAGS_SUCCESSED,
        data: deleteWorkFlow?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_TAGS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_WORKFLOW_TAGS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createWorkFlow(action) {
  const loaderHandle = showToastLoader('Creating workflow...')
  try {
    const response = yield call(
      administrationCreateWorkflowTaskAPI,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.[1]
      showToastSuccess(msg || 'Workflow created', loaderHandle)
      yield put(getWorkflowList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_CREATE_FAILED,
        data: null,
      });
      showToastError('Workflow creation failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Workflow creation failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_WORKFLOW_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* riskManagementThreatRegister(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementThreatRegister',
    };
    const workflowData = yield call(administrationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* updateWorkFlow(action) {
  const loaderHandle = showToastLoader('Updating workflow...')
  try {
    const updateWorkFlow = yield call(
      administrationUpdateWorkflowTaskAPI,
      action.payload
    );
    if (updateWorkFlow.success) {
      yield put({
        type: ADMINISTRATION_WORKFLOW_UPDATE_SUCCESSED,
        data: updateWorkFlow?.data,
      });
      const msg = updateWorkFlow?.data?.[1]
      showToastSuccess(msg || 'Workflow updated', loaderHandle)
      yield put(getWorkflowList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_WORKFLOW_UPDATE_FAILED,
        data: null,
      });
      showToastError('Workflow update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Workflow update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_WORKFLOW_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetValueList(action) {
  try {
    const assetsValueList = yield call(assetsValueTabAPI, action.payload);
    if (assetsValueList.success) {
      yield put({
        type: ADMINISTRATION_ASSET_VALUE_LIST_SUCCESSED,
        data: assetsValueList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_VALUE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_VALUE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetConfidentiality(action) {
  try {
    const response = yield call(assetConfidentialityApi, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetClassificationTitleChange(action) {
  const loaderHandle = showToastLoader('Updating title...')
  try {
    const response = yield call(classificationTitleChange, action.payload);
    if (response.success) {
      yield put(getAllTabbsHeading({ module: 'assets', payload: 'assetsClassification' }));
      yield put({
        type: ASSET_CLASSIFICATION_CHANGE_TITLE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Title updated', loaderHandle)
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_CHANGE_TITLE_FAILED,
        data: null,
      });
      showToastError('Title update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_CHANGE_TITLE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Title update failed', loaderHandle)
  }
}

export function* assetClassificationFormulaChange(action) {
  const loaderHandle = showToastLoader('Updating formula...')
  try {
    const response = yield call(classificationFormulaChange, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_FORMULA_CHANGE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Formula updated', loaderHandle)
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_FORMULA_CHANGE_FAILED,
        data: null,
      });
      showToastError('Formula update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_FORMULA_CHANGE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Formula update failed', loaderHandle)
  }
}

export function* getAssetClassificationgFormula() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(classificationGetFormula);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_GET_FORMULA_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_GET_FORMULA_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ASSET_CLASSIFICATION_GET_FORMULA_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetIntegrity(action) {
  try {
    const response = yield call(assetIntegrityApi, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_INTEGIRTY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateIntegrity(action) {
  const loaderHandle = showToastLoader('Updating integrity...')
  try {
    const response = yield call(updateIntegrityApi, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset integrity updated', loaderHandle)
      yield put(getIntegirtyListRequest({ queryItem: action.query }));
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_FAILED,
        data: null,
      });
      showToastError('Asset integrity already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset integrity already exists', loaderHandle)
  }
}

export function* deleteIntegrity(action) {
  const loaderHandle = showToastLoader('Deleting integrity...')
  try {
    const response = yield call(deleteIntegrityApi, action.id);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset Integrity deleted', loaderHandle)
      yield put(getIntegirtyListRequest({ queryItem: action.query }));
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_DELETE_FAILED,
        data: null,
      });
      showToastError('Integrity delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_INTEGIRTY_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Integrity delete failed', loaderHandle)
  }
}
export function* createIntegrity(action) {
  const loaderHandle = showToastLoader('Creating integrity...')
  try {
    const response = yield call(createIntegrityApi, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset Integrity created', loaderHandle)
      yield put(getIntegirtyListRequest({ queryItem: action.query }));
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_INTEGIRTY_CREATE_FAILED,
        data: null,
      });
      showToastError('Asset Integrity already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_INTEGIRTY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset Integrity already exists', loaderHandle)
  }
}

export function* assetAvailability(action) {
  try {
    const response = yield call(assetAvailabilityApi, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_AVAILABILITY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateAvailability(action) {
  const loaderHandle = showToastLoader('Updating availability...')
  try {
    const response = yield call(updateAvailabilityApi, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset availability updated', loaderHandle)
      yield put(getAvaiabilityListRequest({ queryItem: action.query }));
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_FAILED,
        data: null,
      });
      showToastError('Asset availability already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset availability already exists', loaderHandle)
  }
}

export function* createAvailability(action) {
  const loaderHandle = showToastLoader('Creating availability...')
  try {
    const response = yield call(createAvailabilityApi, action.payload);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset availability created', loaderHandle)
      yield put(getAvaiabilityListRequest({ queryItem: action.query }));
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_CREATE_FAILED,
        data: null,
      });
      showToastError('Asset availability already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_AVAILABILITY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset availability already exists', loaderHandle)
  }
}

export function* deleteAvailability(action) {
  const loaderHandle = showToastLoader('Deleting availability...')
  try {
    const response = yield call(deleteAvailabilityApi, action.id);
    if (response.success) {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset availability deleted', loaderHandle)
      yield put(getAvaiabilityListRequest({ queryItem: action.query }));
    } else {
      yield put({
        type: ASSET_CLASSIFICATION_AVAILABILITY_DELETE_FAILED,
        data: null,
      });
      showToastError('Asset availability delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ASSET_CLASSIFICATION_AVAILABILITY_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset availability delete failed', loaderHandle)
  }
}


export function* updateConfidentiality(action) {
  const loaderHandle = showToastLoader('Updating confidentiality...')
  try {
    const response = yield call(updateConfidentialityApi, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(assetConfidentialityList({ queryItem: action.payload.query }))
      showToastSuccess('Asset confidentiality updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_FAILED,
        data: null,
      });
      showToastError('Asset confidentiality already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset confidentiality updated', loaderHandle)
  }
}

export function* createConfidentiality(action) {
  const loaderHandle = showToastLoader('Creating confidentiality...')
  try {
    const response = yield call(createConfidentialityApi, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(assetConfidentialityList({ queryItem: action.payload.query }));
      showToastSuccess('Asset confidentiality created', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_FAILED,
        data: null,
      });
      showToastError('Asset confidentiality creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset confidentiality already exists', loaderHandle)
  }
}


export function* deleteConfidentiality(action) {
  const loaderHandle = showToastLoader('Deleting confidentiality...')
  try {
    const response = yield call(deleteConfidentialityApi, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(assetConfidentialityList({ queryItem: action.query }))
      showToastSuccess('Asset confidentiality deleted', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_FAILED,
        data: null,
      });
      showToastError('Asset confidentiality delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset confidentiality delete failed', loaderHandle)
  }
}

export function* createAssetValue(action) {
  const loaderHandle = showToastLoader('Creating asset value...')
  try {
    const response = yield call(
      createAssetValueAPI,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put(getAssetValueList({ queryItem: action.payload.queryItem }))
      showToastSuccess('Asset value created', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED,
        data: null,
      });
      showToastError('Asset value creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset value already exists', loaderHandle)
  }
}

export function* editAssetsValue(action) {
  const loaderHandle = showToastLoader('Updating asset value...')
  try {
    const response = yield call(editAssetValueAPI, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_EDIT_ASSET_VALUE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Asset value updated', loaderHandle)
      yield put(getAssetValueList({ queryItem: action.payload.queryItem }))
    } else {
      yield put({
        type: ADMINISTRATION_EDIT_ASSET_VALUE_FAILED,
        data: null,
      });
      showToastError('Asset value update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_EDIT_ASSET_VALUE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset value already exists', loaderHandle)
  }
}



export function* deleteAssetsValue(action) {
  const loaderHandle = showToastLoader('Deleting asset value...')
  try {
    const assetsValueData = yield call(deleteAssetsValueAPI, action.payload);
    if (assetsValueData.success) {
      yield put({
        type: ADMINISTRATION_DELETE_ASSET_VALUE_SUCCESSED,
        data: assetsValueData,
      });
      yield put(getAssetValueList({ queryItem: action.payload.queryItem }))
      showToastSuccess('Asset value deleted', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_DELETE_ASSET_VALUE_FAILED,
        data: null,
      });
      showToastError('Asset value delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_DELETE_ASSET_VALUE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset value delete failed', loaderHandle)
  }
}

export function* changeAssetValueTitle(action) {
  const loaderHandle = showToastLoader('Changing asset value title...')
  try {
    const assetsEditTitleValueData = yield call(
      editAssetTitleValueAPI,
      action.payload
    );
    if (assetsEditTitleValueData.success) {
      yield put(getAllTabbsHeading({ module: 'assets', payload: 'assetsValue' }));
      yield put({
        type: ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_SUCCESSED,
        data: assetsEditTitleValueData,
      });
      showToastSuccess('Title updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_FAILED,
        data: null,
      });

      showToastError('Title update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Title update failed', loaderHandle)
  }
}

export function* assetOsList(action) {
  try {
    const assetsOsListData = yield call(assetsOsListAPI, action?.payload);
    if (assetsOsListData.success) {
      yield put({
        type: ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED,
        data: assetsOsListData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createOperatingSystem(action) {
  const loaderHandle = showToastLoader('Creating OS...')
  try {
    const assetsCreateValueData = yield call(
      createOperatingSystemAPI,
      action.payload
    );
    if (assetsCreateValueData.success) {
      showToastSuccess('Operating system created', loaderHandle)
      yield put(
        getAssetOperatingSystemList({ queryItem: action.payload?.queryItem })
      );

      if (assetsCreateValueData.success) {
        yield put({
          type: ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED,
          data: assetsCreateValueData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_CREATE_OPERATING_SYSTEM_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_CREATE_OPERATING_SYSTEM_SUCCESSED,
        data: assetsCreateValueData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CREATE_OPERATING_SYSTEM_FAILED,
        data: null,
      });
      showToastError('OS creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CREATE_OPERATING_SYSTEM_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('OS creation failed', loaderHandle)

  }
}

export function* editOperatingSystem(action) {
  const loaderHandle = showToastLoader('Updating OS...')
  try {
    const assetsEditOsData = yield call(editAssetOsAPI, action.payload);
    if (assetsEditOsData.success) {
      const assetsOsListData = yield call(assetsOsListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsOsListData.success) {
        showToastSuccess('Operating system updated', loaderHandle)

        yield put(
          getAssetOperatingSystemList({ queryItem: action.payload?.queryItem })
        );
      } else {
        yield put({
          type: ADMINISTRATION_EDIT_OPERATING_SYSTEM_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_EDIT_OPERATING_SYSTEM_SUCCESSED,
        data: assetsEditOsData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_EDIT_OPERATING_SYSTEM_FAILED,
        data: null,
      });
      showToastError('OS update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_EDIT_OPERATING_SYSTEM_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('OS update failed', loaderHandle)
  }
}

export function* deleteOperatingSystem(action) {
  const loaderHandle = showToastLoader('Deleting OS...')
  try {
    const assetsValueData = yield call(deleteOsAPI, action.payload);
    if (assetsValueData.success) {
      const assetsOsListData = yield call(assetsOsListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsOsListData.success) {
        showToastSuccess('Operating system deleted', loaderHandle)

        yield put({
          type: ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_SUCCESSED,
          data: assetsOsListData,
        });
      } else {
        yield put({
          type: ADMINISTRATION_DELETE_ASSET_VALUE_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_DELETE_ASSET_VALUE_SUCCESSED,
        data: assetsValueData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_DELETE_ASSET_VALUE_FAILED,
        data: null,
      });
      showToastError('OS delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_DELETE_ASSET_VALUE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('OS delete failed', loaderHandle)
  }
}

export function* riskManagementThreatResisterValueList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementThreatRegister',
    };
    const valueList = yield call(threatRegisterValueApi, payload);

    if (valueList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_SUCCESSED,
        data: valueList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* riskManagementThreatResisterVulnerabilityList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementThreatRegister',
    };
    const valueList = yield call(threatRegisterVulnerabilityApi, payload);

    if (valueList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED,
        data: valueList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createRiskManagementThreatRegister(action) {
  const loaderHandle = showToastLoader('Creating Threat Register...')
  try {
    const payload = {
      payload: action.payload,
      tabName: 'riskManagementThreatRegister',
    };
    const createList = yield call(
      createRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess(createList?.data[1], loaderHandle)
      yield put(riskManagementThreatRegisterList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
        data: null,
      });
      showToastError('Threat register create failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
      data: err?.response?.data?.data,
    });

    showToastError('Threat register create failed', loaderHandle)
  }
}




export function* updateRiskManagementThreatRegister(action) {
  const loaderHandle = showToastLoader('Updating Threat Register...')
  try {
    const payload = {
      payload: action.payload,
      tabName: 'riskManagementThreatRegister',
    };
    const updateList = yield call(
      updateRiskManagementThreatRegisterAPI,
      payload
    );

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      showToastSuccess(updateList?.data[1], loaderHandle)
      yield put(riskManagementThreatRegisterList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_FAILED,
        data: null,
      });
      showToastError('Threat register update failed', loaderHandle)

    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat register update failed', loaderHandle)
  }
}

export function* deleteRiskManagementThreatRegister(action) {
  const loaderHandle = showToastLoader('Deleting Threat Register...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementThreatRegister',
    };
    const createList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess(createList?.data[1], loaderHandle)
      yield put(riskManagementThreatRegisterList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
        data: null,
      });
      showToastError('Threat register delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
      data: err?.response?.data?.data,
    });

    showToastError('Threat register delete failed', loaderHandle)
  }
}

export function* riskManagementThreatValue(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementThreatValue',
    };
    const workflowData = yield call(administrationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* riskManagementControlRegister(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementControlRegister',
    };
    const workflowData = yield call(administrationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createRiskManagementThreatValue(action) {
  const loaderHandle = showToastLoader('Creating Threat Value...')
  try {
    const payload = {
      payload: action.payload,
      tabName: 'riskManagementThreatValue',
    };
    const createList = yield call(
      createRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Threat value created', loaderHandle)
      yield put(riskManagementThreatValueList({ queryItem: action.query }))
    } else {

      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
        data: null,
      });
      showToastError('Creating threat value failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Creating threat value failed', loaderHandle)
  }
}

export function* deleteRiskManagementThreatValue(action) {
  const loaderHandle = showToastLoader('Deleting Threat Value...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementThreatValue',
    };
    const createList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Threat value deleted', loaderHandle)
      yield put(riskManagementThreatValueList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
        data: null,
      });
      showToastError('Threat value delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat value delete failed', loaderHandle)
  }
}

export function* updateRiskManagementThreatValue(action) {
  const loaderHandle = showToastLoader('Updating Threat Value...')
  try {
    const payload = {
      payload: action.payload,
      id: action.id,
      tabName: 'riskManagementThreatValue',
    };
    const createList = yield call(updateRiskManagementAPI, payload);

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Threat value updated', loaderHandle)
      yield put(riskManagementThreatValueList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
        data: null,
      });
      showToastError('Threat value update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat value update failed', loaderHandle)
  }
}

export function* createRiskManagementControlRegister(action) {
  const loaderHandle = showToastLoader('Creating Control Register...')
  try {
    const payload = {
      payload: action.payload,
      tabName: 'riskManagementControlRegister',
    };
    const createList = yield call(
      createRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Control register created', loaderHandle)
      yield put(riskManagementControlRegisterList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
        data: null,
      });
      showToastError('Creating control register failed', loaderHandle)
    }
  } catch (err) {

    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Creating control register failed', loaderHandle)
  }
}

export function* updateRiskManagementControlRegister(action) {
  // debugger;
  const loaderHandle = showToastLoader('Updating Control Register...')
  try {
    const payload = {
      payload: action.payload,
      id: action.id,
      tabName: 'riskManagementControlRegister',
    };
    const createList = yield call(
      updateRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Control register updated', loaderHandle)
      yield put(riskManagementControlRegisterList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
        data: null,
      });
      showToastError('Control register update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Control register update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteRiskManagementControlRegister(action) {
  const loaderHandle = showToastLoader('Deleting Control Register...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementControlRegister',
    };
    const createList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Control register deleted', loaderHandle)
      yield put(riskManagementControlRegisterList({ queryItem: action.query }))
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
        data: null,
      });
      showToastError('Control register delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Control register delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* riskManagementBusinessImpact(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementBusinessImpact',
    };
    const workflowData = yield call(administrationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteRiskManagementBusinessImpact(action) {
  const loaderHandle = showToastLoader('Deleting business impact...')
  try {
    const payload = {
      id: action.payload,
      tabName: 'riskManagementBusinessImpact',
    };
    const createList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED,
        data: createList?.data,
      });
      yield put(riskManagementBusinessImpactList({ queryItem: action.query }));
      showToastSuccess(createList.data[1], loaderHandle);
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
        data: null,
      });
      showToastError('Business impact delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Business impact delete failed', loaderHandle)
  }
}

export function* createRiskManagementBusinessImpact(action) {
  const loaderHandle = showToastLoader('Creating business impact...')
  try {
    const payload = {
      payload: action.payload,
      tabName: 'riskManagementBusinessImpact',
    };
    const createList = yield call(
      createRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED,
        data: createList?.data,
      });

      yield put(riskManagementBusinessImpactList({ queryItem: action.query }));
      showToastSuccess(createList.data[1], loaderHandle);

    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
        data: null,
      });
      showToastError('Creating business impact failed', loaderHandle);
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Creating business impact failed', loaderHandle);
  }
}

export function* updateRiskManagementBusinessImpact(action) {
  const loaderHandle = showToastLoader('Updating business impact...')
  try {
    const payload = {
      payload: action.payload,
      id: action.id,
      tabName: 'riskManagementBusinessImpact',
    };
    const createList = yield call(
      updateRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess(createList.data[1], loaderHandle);
      yield put(riskManagementBusinessImpactList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
        data: null,
      });
      showToastError('Business impact update failed', loaderHandle);
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Business impact update failed', loaderHandle);
  }
}

export function* RiskManagementBusinessImpactChangeTitle(action) {
  const loaderHandle = showToastLoader('Changing title...')
  try {
    const title = yield call(RiskManagementBusinesImpactChangeTitleAPI, action);

    if (title.success) {
      showToastSuccess('Title changed', loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_SUCCESSED,
        data: title?.data,
      });
      yield put(getAllTabbsHeading({ module: 'assets', payload: 'businessImpact' }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_FAILED,
        data: null,
      });
      showToastError('Changing title failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Changing title failed', loaderHandle)
  }
}

export function* riskManagementCompliance(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'riskManagementCompliance',
    };
    const workflowData = yield call(administrationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createRiskManagementCompliance(action) {
  const loaderHandle = showToastLoader('Creating compliance...')
  try {
    const payload = {
      payload: action.payload,
      tabName: 'riskManagementCompliance',
    };
    const createList = yield call(
      createRiskManagementThreatRegisterAPI,
      payload
    );

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Compliance created', loaderHandle);
      yield put(riskManagementComplianceList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
        data: null,
      });
      showToastError('Compliance creation failed', loaderHandle);
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Compliance creation failed', loaderHandle);
  }
}

export function* deleteRiskManagementCompliance(action) {
  const loaderHandle = showToastLoader('Deleting compliance...')
  try {
    const payload = {
      id: action.payload,
      tabName: 'riskManagementCompliance',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      showToastSuccess('Compliance deleted', loaderHandle);
      yield put(riskManagementComplianceList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
        data: null,
      });
      showToastError('Compliance delete failed', loaderHandle);
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Compliance delete failed', loaderHandle);
  }
}

export function* updateRiskManagementCompliance(action) {
  const loaderHandle = showToastLoader('Updating compliance...')
  try {
    const payload = {
      payload: action.payload,
      id: action.id,
      tabName: 'riskManagementCompliance',
    };
    const updateList = yield call(
      updateRiskManagementThreatRegisterAPI,
      payload
    );

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      showToastSuccess('Compliance updated', loaderHandle);
      yield put(riskManagementComplianceList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
        data: null,
      });
      showToastError('Compliance update failed', loaderHandle);
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Compliance update failed', loaderHandle);
  }
}

export function* automationVendors(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationVendorsList',
    };
    const workflowData = yield call(automationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAutomationVendors(action) {
  const loaderHandle = showToastLoader('Creating product vendor...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationVendorsList',
    };
    const createList = yield call(automationCreateListAPI, payload);
    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Product vendor created', loaderHandle)
      yield put(automationVendorsList({ queryItem: action.payload.query }))
      if(action.payload?.callback) action.payload?.callback()
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
        data: null,
      });
      showToastError('Product vendor creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product vendor creation failed', loaderHandle)
  }
}

export function* updateAutomationVendors(action) {
  const loaderHandle = showToastLoader('Updating product vendor...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationVendorsList',
    };
    const updateList = yield call(automationUpdateListAPI, payload);

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
        data: updateList?.data,
      });
      showToastSuccess('Product vendor updated', loaderHandle)
      yield put(automationVendorsList({ queryItem: action.payload.query }))
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
        data: null,
      });
      showToastError('Product vendor update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product vendor update failed', loaderHandle)
  }
}

export function* deleteAutomationVendors(action) {
  const loaderHandle = showToastLoader('Deleting product vendor...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationVendorsList',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );
    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      showToastSuccess('Product vendor deleted', loaderHandle)
      yield put(automationVendorsList({ queryItem: action.payload.query }))
    } else {
      showToastError('Product vendor delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Product vendor delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationPublishers(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationPublishers',
    };
    const response = yield call(automationListAPI, payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAutomationPublishers(action) {
  const loaderHandle = showToastLoader('Creating product publisher...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationPublishers',
    };
    const createList = yield call(automationCreateListAPI, payload);
    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      yield put(automationPublishersList({ queryItem: action.query }));
      showToastSuccess('Product publisher created', loaderHandle)
      if(action.payload.callback) action.payload.callback();
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
        data: null,
      });
      showToastError('Product publisher create failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product publisher create failed', loaderHandle)
  }
}

export function* updateAutomationPublishers(action) {
  const loaderHandle = showToastLoader('Updating product publisher...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationPublishers',
    };
    const updateList = yield call(automationUpdateListAPI, payload);

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
        data: updateList?.data,
      });
      yield put(automationPublishersList({ queryItem: action.query }));
      showToastSuccess('Product publisher updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
        data: null,
      });
      showToastError('Product publisher update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product publisher update failed', loaderHandle)
  }
}

export function* deleteAutomationPublishers(action) {
  const loaderHandle = showToastLoader('Deleting product publisher...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationPublishers',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );
    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(automationPublishersList({ queryItem: action.query }));
      showToastSuccess('Product publisher deleted', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Product publisher delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product publisher delete failed', loaderHandle)
  }
}

export function* automationActionIO(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsIO',
    };
    const workflowData = yield call(automationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchautomationActionDuplication(action) {
  const loaderHandle = showToastLoader('Duplicating action...')
  try {
    const response = yield call(automationActionDuplication, action.id);
    if (response.success) {
      yield put({
        type: AUTOMATION_ACTION_DUPLICATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Action is duplicated', loaderHandle)
      yield put(automationActionsLists({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message || 'Something went wrong', loaderHandle)
    yield put({
      type: AUTOMATION_ACTION_DUPLICATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* createAutomationActionIO(action) {
  const loaderHandle = showToastLoader('Creating action IO type...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsIO',
    };
    const createList = yield call(automationCreateListAPI, payload);
    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      yield put(automationActionIOList({ queryItem: action.payload.query }));
      showToastSuccess('Action IO type created', loaderHandle)
    } else {
      showToastError('Action IO type creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Action IO type creation failed', loaderHandle)
  }
}

export function* automationActionIOValidationList(action) {
  try {
    const createList = yield call(automationActionIOValidationListAPI);

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_SUCCESSED,
        data: createList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateAutomationActionsIO(action) {
  const loaderHandle = showToastLoader('Updating action IO type...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsIO',
    };
    const updateList = yield call(automationUpdateListAPI, payload);

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
        data: updateList?.data,
      });
      yield put(automationActionIOList({ queryItem: action.payload.query }));
      showToastSuccess('Action IO type updated', loaderHandle)
    } else {
      showToastError('Action IO type update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Action IO type update failed', loaderHandle)
  }
}

export function* deleteAutomationActionsIO(action) {
  const loaderHandle = showToastLoader('Deleting action IO type...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsIO',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(automationActionIOList({ queryItem: action.payload.query }));
      showToastSuccess('Action IO type deleted', loaderHandle)
    } else {
      showToastError('Action IO type delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Action IO type delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationThreat(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationThreat',
    };
    const workflowData = yield call(automationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteAutomationThreat(action) {
  const loaderHandle = showToastLoader('Deleting thread feed...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationThreat',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );
    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      showToastSuccess('Threat feed deleted', loaderHandle)
      yield put(automationThreatList({ queryItem: action.payload.query }));
    } else {
      showToastError('Thread feed delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Thread feed delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAutomationThreat(action) {
  const loaderHandle = showToastLoader('Creating thread feed...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationThreat',
    };
    const createList = yield call(automationCreateListAPI, payload);
    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Threat feed created', loaderHandle)
      yield put(automationThreatList({ queryItem: action.payload.query }));
    } else {
      showToastError('Threat feed create failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Url already exists', loaderHandle)
  }
}

export function* updateAutomationThreat(action) {
  const loaderHandle = showToastLoader('Updating thread feed...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationThreat',
    };
    const updateList = yield call(automationUpdateListAPI, payload);

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
        data: updateList?.data,
      });
      yield put(automationThreatList({ queryItem: action.payload.query }));
      showToastSuccess('Threat feed updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
        data: null,
      });
      showToastError('Threat feed update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat feed update failed', loaderHandle)
  }
}

export function* automationFamily(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationFamily',
    };
    const workflowData = yield call(automationListAPI, payload);

    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteAutomationFamily(action) {
  const loaderHandle = showToastLoader('Deleting use case family....')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationFamily',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );

    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      showToastSuccess('Use case family deleted', loaderHandle)
      yield put(automationFamilyList({ queryItem: action.payload.query }));
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Use case family delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Can not delete this use case family because it has been tagged in one or more playbook', loaderHandle)
  }
}

export function* createAutomationFamily(action) {
  const loaderHandle = showToastLoader('Creating use case family....')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationFamily',
    };
    const createList = yield call(automationCreateListAPI, payload);
    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Use case family created', loaderHandle)
      yield put(automationFamilyList({ queryItem: action.payload.query }));
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
        data: null,
      });
      showToastError('Use case family creation failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Use case family creation failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateAutomationFamily(action) {
  const loaderHandle = showToastLoader('Updating use case family....')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationFamily',
    };
    const updateList = yield call(automationUpdateListAPI, payload);

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
        data: updateList?.data,
      });
      showToastSuccess('Use case family updated', loaderHandle)
      yield put(automationFamilyList({ queryItem: action.payload.query }));
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
        data: null,
      });
      showToastError('Use case family update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Use case family update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* assetDepartmentList(action) {
  try {
    const assetsValueList = yield call(assetsDepartmentListAPI, action.payload);
    if (assetsValueList.success) {
      yield put({
        type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED,
        data: assetsValueList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAssetDepartment(action) {
  const loaderHandle = showToastLoader('Creating department...')
  try {
    const assetsCreateDepartmentData = yield call(
      createAssetDepartmentAPI,
      action.payload
    );
    if (assetsCreateDepartmentData.success) {
      showToastSuccess('Department created', loaderHandle)
      const assetsValueList = yield call(assetsDepartmentListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsValueList.success) {
        yield put({
          type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED,
          data: assetsValueList,
        });
      } else {
        yield put({
          type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
          data: null,
        });
      }
      yield put({
        type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_SUCCESSED,
        data: assetsCreateDepartmentData,
      });
    } else {
      showToastError('Department creation failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Department already exists', loaderHandle)
    yield put({
      type: ADMINISTRATION_CREATE_ASSET_VALUE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* editAssetDepartment(action) {
  const loaderHandle = showToastLoader('Updating department...')
  try {
    const assetsEditDepartmentData = yield call(
      editAssetDepartmentAPI,
      action.payload
    );
    if (assetsEditDepartmentData.success) {
      showToastSuccess('Department updated', loaderHandle)
      const assetsValueList = yield call(assetsDepartmentListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsValueList.success) {
        yield put({
          type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED,
          data: assetsValueList,
        });
      } else {
        yield put({
          type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_EDIT_DEPARTMENT_SUCCESSED,
        data: assetsEditDepartmentData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_EDIT_DEPARTMENT_FAILED,
        data: null,
      });
      showToastError('Department update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Department already exists', loaderHandle)
    yield put({
      type: ADMINISTRATION_EDIT_DEPARTMENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteAssetDepartment(action) {
  const loaderHandle = showToastLoader('Deleting department...')
  try {
    const assetsValueData = yield call(
      deleteAssetDepartmentAPI,
      action.payload
    );
    if (assetsValueData.success) {
      showToastSuccess('Department deleted', loaderHandle)
      const assetsValueList = yield call(assetsDepartmentListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsValueList.success) {
        yield put({
          type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_SUCCESSED,
          data: assetsValueList,
        });
      } else {
        yield put({
          type: ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_DELETE_DEPARTMENT_SUCCESSED,
        data: assetsValueData,
      });
    } else {
      showToastError('Department delete failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_DELETE_DEPARTMENT_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Department delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_DELETE_DEPARTMENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchContainerList() {
  try {
    const containerList = yield call(getContainerList);

    if (containerList.success) {
      yield put({ type: ADMINISTRATION_CASES_CONTAINER_LIST_SUCCESS, data: containerList?.data });
      // openNotification('Case has been deleted Successfully');
      // yield put(listCases(action.pageNo));

    } else {
      yield put({ type: ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED, data: null });
    }
  } catch (err) {
    yield put({ type: ADMINISTRATION_CASES_CONTAINER_LIST_FAILDED, data: err?.response?.data?.data });
  }
}

export function* watchCasesDisposition(action) {
  try {
    const despositionList = yield call(getCasesDespositionList, action.name);

    if (despositionList.success) {
      yield put({ type: ADMINISTRATION_CASES_DESPOSITION_SUCCESS, data: despositionList?.data });
      // openNotification('Case has been deleted Successfully');
      // yield put(listCases(action.pageNo));

    } else {
      yield put({ type: ADMINISTRATION_CASES_DESPOSITION_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: ADMINISTRATION_CASES_DESPOSITION_FAILED, data: err?.response?.data?.data });
  }
}

export function* watchCasesCategoriesList(action) {
  try {
    const categoryList = yield call(getCasesCategoriesList, action.name);

    if (categoryList.success) {
      yield put({ type: ADMINISTRATION_CASES_CATEGORY_SUCCESS, data: categoryList?.data });
    } else {
      yield put({ type: ADMINISTRATION_CASES_CATEGORY_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: ADMINISTRATION_CASES_CATEGORY_FAILED, data: err?.response?.data?.data });
  }
}


export function* watchCasesCategory(action) {
  try {
    const response = yield call(administratorCasesCategory, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CATEGORY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesCategoryDisposition() {
  try {
    const response = yield call(administratorCasesDispositionsItem);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_DISPOSITION_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CATEGORY_DISPOSITION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesCategoryUpdate(action) {
  const loaderHandle = showToastLoader('Updating case category...')
  try {
    const response = yield call(
      administratorCasesCategoryUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data.message;
      showToastSuccess(msg || 'Case category updated', loaderHandle)
      yield put(administratorCasesCategoriesList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Category already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CATEGORY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category already exists', loaderHandle)
  }
}

export function* watchCasesCategoryDelete(action) {
  const loaderHandle = showToastLoader('Deleting case category...')
  try {
    const response = yield call(administratorCasesCategoryDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Case category deleted', loaderHandle)
      yield put(administratorCasesCategoriesList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Category delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CATEGORY_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category delete failed', loaderHandle)
  }
}

export function* watchCasesCategoryCreate(action) {
  const loaderHandle = showToastLoader('Creating case category...')
  try {
    const response = yield call(
      administratorCasesCategoryCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data.message;
      showToastSuccess(msg || 'Case category created', loaderHandle)
      yield put(administratorCasesCategoriesList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CATEGORY_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Category already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CATEGORY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category already exists', loaderHandle)
  }
}

export function* watchCasesSubCategory(action) {
  try {
    const response = yield call(administratorCasesSubCategory, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUBCATEGORY_LIST_SUCCESSED,
        data: response?.data,
      });

    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUBCATEGORY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CATEGORY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesSubCategoryCreate(action) {
  const loaderHandle = showToastLoader('Creating case subcategory...')
  try {
    const response = yield call(
      administratorCasesSubCategoryCreate,
      action.payload
    );
    if (response.success) {
      showToastSuccess(response?.data?.message || 'Case subcategory created', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(
        administratorCasesSubCategoriesList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Subcategory already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Subcategory already exists', loaderHandle)
  }
}

export function* watchCasesSubCategoryUpdate(action) {
  const loaderHandle = showToastLoader('Updating case subcategory...')
  try {
    const response = yield call(
      administratorCasesSubCategoryUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data?.message || 'Case subcategory updated', loaderHandle)
      yield put(
        administratorCasesSubCategoriesList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Subcategory already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Subcategory already exists', loaderHandle)
  }
}

export function* watchCasesSubCategoryDelete(action) {
  const loaderHandle = showToastLoader('Deleting case subcategory...')
  try {
    const response = yield call(administratorCasesSubCategoryDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data?.message || 'Case subcategory deleted', loaderHandle)
      yield put(
        administratorCasesSubCategoriesList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Subcategory delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Subcategory delete failed', loaderHandle)
  }
}

export function* watchCasesSubCategoryList() {
  try {
    const response = yield call(administratorCasesCategoryList);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_PRIMARY_CATEGORY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_PRIMARY_CATEGORY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesDispositionsList(action) {
  try {
    const response = yield call(
      administratorCasesDispositionsList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITIONS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DISPOSITIONS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCasesDispositionDelete(action) {
  const loaderHandle = showToastLoader('Deleting case disposition...')
  try {
    const response = yield call(administratorCasesDispositionDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITION_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Case disposition deleted', loaderHandle)
      yield put(
        administratorCasesDispositionsDataList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITION_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Case disposition delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DISPOSITION_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Case disposition delete failed', loaderHandle)
  }
}
export function* watchCasesDispositionUpdate(action) {
  const loaderHandle = showToastLoader('Updating case disposition...')
  try {
    const response = yield call(
      administratorCasesDispositionUpdate,
      action.id,
      action.payload
    );

    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITION_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Case disposition updated', loaderHandle)
      yield put(
        administratorCasesDispositionsDataList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITION_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Case disposition already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DISPOSITION_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Case disposition already exists', loaderHandle)
  }
}

export function* watchCasesDispositionCreate(action) {
  const loaderHandle = showToastLoader('Creating case disposition...')
  try {
    const response = yield call(
      administratorCasesDispositionCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITION_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Case disposition created', loaderHandle)
      yield put(
        administratorCasesDispositionsDataList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DISPOSITION_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Case disposition already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DISPOSITION_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Case disposition already exists', loaderHandle)
  }
}

export function* watchSubDispositions(action) {
  try {
    const response = yield call(
      administratorCasesSubDispositionsList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchSubDispositionsDropDownList(action) {
  try {
    const response = yield call(administratorSubCasesDispositionDropdownList);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesSubDispositionCreate(action) {
  const loaderHandle = showToastLoader('Creating case subdisposition...')
  try {
    const response = yield call(
      administratorCasesSubDispositionCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Case subdisposition created', loaderHandle)
      yield put(administratorSubCasesDispositions({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Case subdisposition already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Case subdisposition already exists', loaderHandle)
  }
}

export function* watchCasesSubDispositionUpdate(action) {
  const loaderHandle = showToastLoader('Updating case subdisposition...')
  try {
    const response = yield call(
      administratorSubCasesDispositionUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Case subdisposition updated', loaderHandle)
      yield put(administratorSubCasesDispositions({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Case subdisposition already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Case subdisposition already exists', loaderHandle)
  }
}
export function* watchCasesSubDispositionDelete(action) {
  const loaderHandle = showToastLoader('Deleting case subdisposition...')
  try {
    const response = yield call(
      administratorCasesSubDispositionDelete,
      action.id
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Case subdisposition deleted', loaderHandle)
      yield put(administratorSubCasesDispositions({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Case subdisposition delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Case subdisposition delete failed', loaderHandle)
  }
}

export function* getAssetBusinessGroupList(action) {
  try {
    const assetsBusinessGroupList = yield call(
      assetsBusinessGroupListAPI,
      action.payload
    );
    if (assetsBusinessGroupList.success) {
      yield put({
        type: ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_SUCCESSED,
        data: assetsBusinessGroupList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAssetBusinessGroup(action) {
  const loaderHandle = showToastLoader('Creating business group...')
  try {
    const assetsCreateDepartmentData = yield call(
      createAssetBusinessGroupAPI,
      action.payload
    );
    if (assetsCreateDepartmentData.success) {
      yield put({
        type: ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_SUCCESSED,
        data: assetsCreateDepartmentData,
      })
      showToastSuccess('Business group created', loaderHandle)
      yield put(getAssetBusinessGroupAction({ queryItem: action.payload?.queryItem }));
    } else {
      showToastError('Business group already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Business group already exists', loaderHandle)
  }
}

export function* editAssetBusinessGroup(action) {
  const loaderHandle = showToastLoader('Updating business group...')
  try {
    const assetsEditDepartmentData = yield call(
      editAssetBusinessGroupAPI,
      action.payload
    );
    if (assetsEditDepartmentData.success) {
      yield put({
        type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_SUCCESSED,
        data: assetsEditDepartmentData,
      });
      showToastSuccess('Business group updated', loaderHandle)
      yield put(getAssetBusinessGroupAction({ queryItem: action.payload?.queryItem }));
    } else {
      showToastError('Business group update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Business group already exists', loaderHandle)
  }
}

export function* deleteAssetBusinessGroup(action) {
  const loaderHandle = showToastLoader('Deleting business group...')
  try {
    const assetsValueData = yield call(
      deleteAssetBusinessGroupAPI,
      action.payload
    );
    if (assetsValueData.success) {
      yield put({
        type: ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_SUCCESSED,
        data: assetsValueData,
      });
      showToastSuccess('Business group deleted', loaderHandle)
      yield put(getAssetBusinessGroupAction({ queryItem: action.payload?.queryItem }));
    } else {
      showToastError('Business group delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Business group delete failed', loaderHandle)
  }
}

export function* editAssetBusinessGroupTitle(action) {
  const loaderHandle = showToastLoader('Updating business group title...')
  try {
    const assetsEditDepartmentData = yield call(
      editAssetBusinessGroupTitleAPI,
      action.payload
    );
    if (assetsEditDepartmentData.success) {
      yield put(getAllTabbsHeading({ module: 'assets', payload: 'assetsBussinessGroup' }));
      yield put({
        type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_SUCCESSED,
        data: assetsEditDepartmentData,
      });
      showToastSuccess('Business group title updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_FAILED,
        data: null,
      });
      showToastError('Business group title update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Business group title update failed', loaderHandle)
  }
}

export function* getAssetTypeList(action) {
  try {
    const assetsTypeList = yield call(assetsTypeListAPI, action.payload);
    if (assetsTypeList.success) {
      yield put({
        type: ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED,
        data: assetsTypeList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAssetTypeList(action) {
  const loaderHandle = showToastLoader('Creating asset type...')
  try {
    const assetsCreateAssetTypeData = yield call(
      createAssetTypeAPI,
      action.payload
    );
    if (assetsCreateAssetTypeData.success) {
      const assetsTypeList = yield call(assetsTypeListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsTypeList.success) {
        yield put({
          type: ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED,
          data: assetsTypeList,
        });
        showToastSuccess('Asset type created', loaderHandle)

      } else {
        yield put({
          type: ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
          data: null,
        });
      }

      yield put({
        type: ADMINISTRATION_CREATE_ASSET_TYPE_SUCCESSED,
        data: assetsCreateAssetTypeData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CREATE_ASSET_TYPE_FAILED,
        data: null,
      });
      showToastError('Asset type already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CREATE_ASSET_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset type already exists', loaderHandle)
  }
}

export function* editAssetTypeList(action) {
  const loaderHandle = showToastLoader('Updating asset type...')
  try {
    const assetsEditAssetTypeData = yield call(
      editAssetTypeListAPI,
      action.payload
    );
    if (assetsEditAssetTypeData.success) {
      const assetsTypeList = yield call(assetsTypeListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsTypeList.success) {
        yield put({
          type: ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED,
          data: assetsTypeList,
        });
        showToastSuccess('Asset type updated', loaderHandle)
      } else {
        yield put({
          type: ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
          data: null,
        });
        showToastError('Asset type already exists', loaderHandle)
      }

      yield put({
        type: ADMINISTRATION_EDIT_ASSET_TYPE_SUCCESSED,
        data: assetsEditAssetTypeData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_EDIT_ASSET_TYPE_FAILED,
        data: null,
      });
      showToastError('Asset type already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_EDIT_ASSET_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset type already exists', loaderHandle)
  }
}

export function* deleteAssetType(action) {
  const loaderHandle = showToastLoader('Deleting asset type...')
  try {
    const assetsValueData = yield call(deleteAssetTypeAPI, action.payload);
    if (assetsValueData.success) {
      showToastSuccess('Asset type deleted', loaderHandle)
      const assetsTypeList = yield call(assetsTypeListAPI, {
        queryItem: action.payload.queryItem,
      });
      if (assetsTypeList.success) {
        yield put({
          type: ADMINISTRATION_ASSET_TYPE_LIST_SUCCESSED,
          data: assetsTypeList,
        });
        // yield put(getAssetTypeList({ queryItem: action.queryItem }));
      } else {
        yield put({
          type: ADMINISTRATION_ASSET_TYPE_LIST_FAILED,
          data: null,
        });
      }
      yield put({
        type: ADMINISTRATION_DELETE_ASSET_TYPE_SUCCESSED,
        data: assetsValueData,
      });
    } else {
      yield put({
        type: ADMINISTRATION_DELETE_ASSET_TYPE_FAILED,
        data: null,
      });
      showToastError('Asset type delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_DELETE_ASSET_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset type delete failed', loaderHandle)
  }
}

export function* editAssetTypeTitle(action) {
  const loaderHandle = showToastLoader('Updating asset type title...')
  try {
    const assetsEditDepartmentData = yield call(
      editAssetTypeTitleAPI,
      action.payload
    );
    if (assetsEditDepartmentData.success) {
      yield put(getAllTabbsHeading({ module: 'assets', payload: 'assetsType' }));
      yield put({
        type: ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_SUCCESSED,
        data: assetsEditDepartmentData,
      });
      showToastSuccess('Asset type title updated', loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_FAILED,
        data: null,
      });
      showToastError('Asset type title update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Asset type title update failed', loaderHandle)
  }
}

export function* watchvulnerabilityTechnical(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'technicalVulnerability',
    };
    const vulnerabilityList = yield call(vulnerabilityListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getAssetDropDownList(action) {
  try {
    const assetsBusinessGroupList = yield call(
      assetsDropDownListAPI,
      action.payload
    );
    if (assetsBusinessGroupList.success) {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_SUCCESSED,
        data: assetsBusinessGroupList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getSubGroupDropDownList(action) {
  try {
    const assetsBusinessGroupList = yield call(
      subGroupDropDownListAPI,
      action.payload
    );
    if (assetsBusinessGroupList.success) {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_SUCCESSED,
        data: assetsBusinessGroupList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteTechnicalVulnerability(action) {
  const loaderHandle = showToastLoader('Deleting technical vulnerability...')
  try {
    const payload = {
      ...action.id,
      tabName: 'technicalVulnerability',
    };
    const deleteList = yield call(deleteVulnerabilityAPI, payload);

    if (deleteList.success) {
      showToastSuccess('Technical vulnerability deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(vulnerabilityTechnicalList({queryItem: action?.query}));
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Deleting technical vulnerability failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Deleting technical vulnerability failed', loaderHandle)
  }
}

export function* getOwnerDropDownList(action) {
  try {
    const assetsBusinessGroupList = yield call(
      ownerDropDownListAPI,
      action.payload
    );
    if (assetsBusinessGroupList.success) {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_SUCCESSED,
        data: assetsBusinessGroupList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* vulnerabilityseverityList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'vulnerabilitySeverityList',
    };
    const vulnerabilityList = yield call(vulnerabilityListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* vulnerabilityseverityLevelList(action) {
  try {
    const response = yield call(technicalSeverityLevel, action.payload);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createVulnerabilityseverityLevelList(action) {
  const loaderHandle = showToastLoader('Creating vulnerability severity...')
  try {
    const response = yield call(technicalSeverityLevelCreate, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Vulnerability Severity created', loaderHandle)
      yield put(valTechserverity({ queryItem: action.query }));
    } else {
      showToastError('Vulnerability Severity creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Vulnerability Severity creation failed', loaderHandle)
  }
}

export function* updateVulnerabilityseverityLevelList(action) {
  const loaderHandle = showToastLoader('Updating vulnerability severity...')
  try {
    const response = yield call(technicalSeverityLevelUpdate, action.id, action.payload);
    if (response.success) {
      showToastSuccess('Vulnerability severity updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put(valTechserverity({ queryItem: action.query }));
    } else {
      showToastError('Vulnerability severity update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Vulnerability severity update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteVulnerabilityseverityLevelList(action) {
  const loaderHandle = showToastLoader('Deleting vulnerability severity...')
  try {
    const response = yield call(technicalSeverityLevelDelete, action.id);
    if (response.success) {
      showToastSuccess('Vulnerability severity deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put(valTechserverity({ queryItem: action.query }));
      yield put(vulnerabilityTechnicalSeverityList());
    } else {
      showToastError('Vulnerability severity delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Vulnerability severity delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* nontechvulnerabilityseverityLevelList(action) {
  try {
    const response = yield call(NontechnicalSeverityLevel, action.payload);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_SUCCESSED,
        data: response?.data,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteNonVulnerabilityseverityLevelList(action) {
  const loaderHandle = showToastLoader('Deleting vulnerability severity...')
  try {
    const response = yield call(NontechnicalSeverityLevelDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Vulnerability severity deleted', loaderHandle)
      yield put(valNoTechserverity({ queryItem: action.query }));
    } else {
      showToastError('Vulnerability severity delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Vulnerability severity delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateNonVulnerabilityseverityLevelList(action) {
  const loaderHandle = showToastLoader('Updating vulnerability severity...')
  try {
    const response = yield call(NontechnicalSeverityLevelUpdated, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Vulnerability severity updated', loaderHandle)
      yield put(valNoTechserverity({ queryItem: action.query }));
    } else {
      showToastError('Vulnerability severity update failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Vulnerability severity update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createNonVulnerabilityseverityLevelList(action) {
  const loaderHandle = showToastLoader('Creating vulnerability severity...')
  try {
    const response = yield call(NontechnicalSeverityLevelCreated, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Vulnerability severity created', loaderHandle)
      yield put(valNoTechserverity({ queryItem: action.query }));
    } else {
      showToastError('Vulnerability severity creation failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Vulnerability severity creation failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getDepartmentDropDownList(action) {
  try {
    const assetsBusinessGroupList = yield call(
      departmentDropDownListAPI,
      action.payload
    );
    if (assetsBusinessGroupList.success) {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_SUCCESSED,
        data: assetsBusinessGroupList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* getAssetValueDropDownList(action) {
  try {
    const assetsBusinessGroupList = yield call(
      assetValueDropDownListAPI,
      action.payload
    );
    if (assetsBusinessGroupList.success) {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_SUCCESSED,
        data: assetsBusinessGroupList,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createTechnicalVulnerability(action) {
  const loaderHandle = showToastLoader('Creating technical vulnerability...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'technicalVulnerability',
    };
    const createList = yield call(createVulnerabilityAPI, payload);
    if (createList.success) {
      showToastSuccess('Technical vulnerability created', loaderHandle)
      yield put({
        type: ADMINISTRATION_VULNERABILITY_CREATE_SUCCESSED,
        data: createList?.data,
      });
      yield put(vulnerabilityTechnicalList());
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_CREATE_FAILED,
        data: null,
      });
      showToastError('Technical vulnerability creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Technical vulnerability creation failed', loaderHandle)
  }
}

export function* watchDitectionMethod(action) {
  try {
    const response = yield call(
      administratorCasesDetectionMethodsList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DITECTION_METHOD_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesDetectionMethodDelete(action) {
  const loaderHandle = showToastLoader('Deleting detection method...')
  try {
    const response = yield call(
      administratorCasesDetectionMethodDelete,
      action.id
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Detection method deleted', loaderHandle)
      yield put(
        administratorCasesDitectionsMethod({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Detection method delete failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Detection method delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateTechnicalVulnerability(action) {
  const loaderHandle = showToastLoader('Updating technical vulnerability...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'technicalVulnerability',
    };
    const updateList = yield call(updateVulnerabilityAPI, payload);

    if (updateList.success) {
      showToastSuccess('Technical vulnerability updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_VULNERABILITY_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      yield put(vulnerabilityTechnicalList({ queryItem: action.query }));
    } else {
      showToastError('Technical vulnerability update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_VULNERABILITY_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Technical vulnerability update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_VULNERABILITY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesDitectionMethodUpdate(action) {
  const loaderHandle = showToastLoader('Updating detection method...')
  try {
    const response = yield call(
      administratorCasesDetectionMethodUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data?.message || 'Detection method updated', loaderHandle)
      yield put(
        administratorCasesDitectionsMethod({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Detection method already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Detection method already exists', loaderHandle)
  }
}
export function* watchCasesDitectionMethodCreate(action) {
  const loaderHandle = showToastLoader('Creating detection method...')
  try {
    const response = yield call(
      administratorCasesDetectionMethodCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data?.message || 'Detection method created', loaderHandle)
      yield put(
        administratorCasesDitectionsMethod({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Detection method already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Detection method already exists', loaderHandle)
  }
}

export function* watchDitectionSlas(action) {
  try {
    const response = yield call(
      administratorCasesSlaList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SLA_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SLA_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SLA_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchDeleteDitectionSlas(action) {
  const loaderHandle = showToastLoader('Deleting SLA...')
  try {
    const response = yield call(
      administratorCaseseDelete,
      action.id
    );
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'SLA deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASES_SLA_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(
        administratorCasesSlaAction({ queryItem: action.query })
      );
    } else {
      showToastError(response?.data.message || 'Deleting SLA failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASES_SLA_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Deleting SLA failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_CASES_SLA_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchCreateDitectionSlas(action) {
  const loaderHandle = showToastLoader('Creating SLA...')
  try {
    const response = yield call(
      administratorCasesSlaCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SLA_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'SLA created', loaderHandle)
      yield put(
        administratorCasesSlaAction({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SLA_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Creating SLA failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SLA_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Creating SLA failed', loaderHandle)
  }
}

export function* watchUpdateDitectionSlas(action) {
  const loaderHandle = showToastLoader('Updating SLA...')
  try {
    const response = yield call(
      administratorCasesSlaUpdate,
      action.id, action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SLA_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'SLA updated', loaderHandle)
      yield put(
        administratorCasesSlaAction({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SLA_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data.message || 'Updating SLA failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SLA_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Updating SLA failed', loaderHandle)
  }
}

export function* watchLogDitectionSlas(action) {
  try {
    const response = yield call(
      administratorCasesSlaLogs,
      action.id
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SLA_VIEW_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SLA_VIEW_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSlasRulesView(action) {
  try {
    const response = yield call(
      administratorCasesSlaViewRule,
      action.id
    );

    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_SLA_RULE_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_SLA_RULE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchvulnerabilityNonTechnical(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'nonTechnicalVulnerability',
    };
    const vulnerabilityList = yield call(vulnerabilityListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesNcissList(action) {
  try {
    const response = yield call(administratorCasesNciss, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({ type: ADMINISTRATION_CASES_NCISS_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesNcissDropDownList() {
  try {
    const response = yield call(casesNcissDropDownListAPI);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteCasesNciss(action) {
  const loaderHandle = showToastLoader('Deleting option...')
  try {
    const response = yield call(administratorCasesNcissDelete, action.id);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Option deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASES_NCISS_DELETE_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put(administratorCasesNcissList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Deleting option failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Deleting option failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_CASES_NCISS_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesNcissCreate(action) {
  const loaderHandle = showToastLoader('Creating option...')
  try {
    const response = yield call(administratorCasesNcissCreate, action.payload);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Option created', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(administratorCasesNcissList({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'Creating option failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.message || 'Option already exists', loaderHandle)
  }
}

export function* watchCasesNcissUpdate(action) {
  const loaderHandle = showToastLoader('Updating option...')
  try {
    const response = yield call(
      administratorCasesNcissUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      const msg = response?.data?.message;
      yield put({
        type: ADMINISTRATION_CASES_NCISS_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(msg || 'Option updated', loaderHandle)
      yield put(administratorCasesNcissList({ queryItem: action.query }));
    } else {
      yield put({ type: ADMINISTRATION_CASES_NCISS_UPDATE_FAILED, data: null });
      showToastError(response?.data?.message || 'Option already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Option already exists', loaderHandle)
  }
}

export function* watchCasesncissCategoriesList(action) {
  try {
    const response = yield call(
      administratorCasesNcisCategories,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCasesncissCategoriesUpdate(action) {
  const loaderHandle = showToastLoader('Updating category...')
  try {
    const response = yield call(
      administratorNcisCategoryUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      const msg = response?.data?.message;
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(msg || 'Category updated', loaderHandle)
      yield put(administratorCasesNcisscategories({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Category already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category already exists', loaderHandle)
  }
}
export function* watchCasesncissCategoriesCreate(action) {
  const loaderHandle = showToastLoader('Creating category...')
  try {
    const response = yield call(
      administratorNcisCategoryCreate,
      action.payload
    );
    if (response.success) {
      const msg = response?.data?.message;
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(msg || 'Category created', loaderHandle)
      yield put(administratorCasesNcisscategories({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Category already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category already exists', loaderHandle)
  }
}
export function* watchCasesncissCategoriesDelete(action) {
  const loaderHandle = showToastLoader('Deleting category...')
  try {
    const response = yield call(administratorNcisCategoryDelete, action.id);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Category deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(administratorCasesNcisscategories({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Category delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Category delete failed', loaderHandle)
  }
}

export function* watchadministratorCasesNcissPeriorities(action) {
  try {
    const response = yield call(
      administratorCasesNcissPeriorities,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchadministratorCasesNcissPerioritieDelete(action) {
  const loaderHandle = showToastLoader('Deleting priority...')
  try {
    const response = yield call(
      administratorCasesNcissPerioritieDelete,
      action.id
    );
    if (response.success) {
      const msg = response?.data?.message;
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(msg || 'Priority deleted', loaderHandle)
      yield put(
        administratorCasesNcissPerioritiesList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Priority delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Priority delete failed', loaderHandle)
  }
}
export function* watchadministratorCasesNcissPerioritieUpdate(action) {
  const loaderHandle = showToastLoader('Updating priority...')
  try {
    const response = yield call(
      administratorCasesNcissPerioritieUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      const msg = response?.data?.message;
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(msg || 'Priority updated', loaderHandle)
      yield put(
        administratorCasesNcissPerioritiesList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Priority already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Priority already exists', loaderHandle)
  }
}

export function* watchadministratorCasesNcissPerioritieCreate(action) {
  const loaderHandle = showToastLoader('Creating priority...')
  try {
    const response = yield call(
      administratorCasesNcissPerioritieCreate,
      action.payload
    );
    if (response.success) {
      const msg = response?.data?.message;
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(msg || 'Priority created', loaderHandle)
      yield put(
        administratorCasesNcissPerioritiesList({ queryItem: action.query })
      );
    } else {
      yield put({
        type: ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Priority already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Priority already exists', loaderHandle)
  }
}

export function* watchCasesLocationsList(action) {
  try {
    const response = yield call(
      administratorCasesLocationsList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LOCATIONS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LOCATIONS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesLocationDelete(action) {
  const loaderHandle = showToastLoader('Deleting location...')
  try {
    const response = yield call(administratorCasesLocationDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LOCATION_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Location deleted', loaderHandle)
      yield put(administratorCasesLocationList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LOCATION_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Location delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LOCATION_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Location delete failed', loaderHandle)
  }
}

export function* vulnerabilityNonTechnicalseverityList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'vulnerabilityNonTechnicalSeverityList',
    };
    const vulnerabilityList = yield call(vulnerabilityListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_SEVERITY_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_SEVERITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCasesLocationCreate(action) {
  const loaderHandle = showToastLoader('Creating location...')
  try {
    const response = yield call(
      administratorCasesLocationCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LOCATION_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Location created', loaderHandle)
      yield put(administratorCasesLocationList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LOCATION_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Location already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LOCATION_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.message || 'Location already exists', loaderHandle)
  }
}
export function* watchCasesLocationUpdate(action) {
  const loaderHandle = showToastLoader('Updating location...')
  try {
    const response = yield call(
      administratorCasesLocationUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LOCATION_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Location updated', loaderHandle)
      yield put(administratorCasesLocationList({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LOCATION_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Location already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LOCATION_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data || 'Location already exists', loaderHandle)
  }
}

export function* vulnerabilitythreatList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'vulnerabilityThreatList',
    };
    const vulnerabilityList = yield call(vulnerabilityListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_THREAT_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_THREAT_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_THREAT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteNonTechnicalVulnerability(action) {
  const loaderHandle = showToastLoader('Deleting non-technical vulnerability...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'nonTechnicalVulnerability',
    };
    const deleteList = yield call(deleteVulnerabilityAPI, payload);

    if (deleteList.success) {
      showToastSuccess('Non-technical vulnerability deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(vulnerabilityNonTechnicalList());
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Non-technical vulnerability delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Non-technical vulnerability delete failed', loaderHandle)
  }
}

export function* updateNonTechnicalVulnerability(action) {
  const loaderHandle = showToastLoader('Updating non-technical vulnerability...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'nonTechnicalVulnerability',
    };
    const updateList = yield call(updateVulnerabilityAPI, payload);

    if (updateList.success) {
      showToastSuccess('Non-technical vulnerability updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_VULNERABILITY_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_UPDATE_FAILED,
        data: null,
      });
      showToastError('Non-technical vulnerability update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Non-technical vulnerability update failed', loaderHandle)
  }
}

export function* createNonTechnicalVulnerability(action) {
  const loaderHandle = showToastLoader('Creating non-technical vulnerability...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'nonTechnicalVulnerability',
    };
    const createList = yield call(createVulnerabilityAPI, payload);

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_CREATE_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Non-technical vulnerability created', loaderHandle)
      yield put(vulnerabilityNonTechnicalList());
    } else {
      yield put({
        type: ADMINISTRATION_VULNERABILITY_CREATE_FAILED,
        data: null,
      });
      showToastError('Non-technical vulnerability creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_VULNERABILITY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Non-technical vulnerability creation failed', loaderHandle)
  }
}

export function* threatIntelCategoryList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelCategory',
    };
    const threatList = yield call(threatIntelListAPI, payload);

    if (threatList.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED,
        data: threatList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createthreatIntelCategoryList(action) {
  const loaderHandle = showToastLoader('Creating threat intelligence category...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelCategory',
    };
    const createList = yield call(createthreatIntelAPI, payload);

    if (createList.success) {
      showToastSuccess(createList?.data?.[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_CREATE_SUCCESSED,
        data: createList?.data,
      });

      yield put(threatIntelCategoryListAction({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
        data: null,
      });
      showToastError('Threat intelligence category creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.message || 'Threat intelligence category already exists', loaderHandle)
  }
}


export function* deletethreatIntelCategoryList(action) {
  const loaderHandle = showToastLoader('Deleting threat intelligence category...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelCategory',
    };
    const deleteList = yield call(deletethreatIntelAPI, payload);

    if (deleteList.success) {
      showToastSuccess(deleteList.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(threatIntelCategoryListAction({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
        data: null,
      });
      showToastError('Threat intelligence category delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat intelligence category delete failed', loaderHandle)
  }
}

export function* updatethreatIntelCategoryList(action) {
  const loaderHandle = showToastLoader('Updating threat intelligence category...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelCategory',
    };
    const updateList = yield call(updateThreatIntelAPI, payload);
    if (updateList.success) {
      showToastSuccess(updateList.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      yield put(threatIntelCategoryListAction({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
        data: null,
      });
      showToastError('Threat intelligence category already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat intelligence category already exists', loaderHandle)
  }
}
export function* affectedVendorList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedVendor',
    };
    const threatList = yield call(affectedVendorListApi, payload);

    if (threatList.success) {
      yield put({
        type:ADMINISTRATION_AFFECTED_VENDOR_LIST_SUCCESSED,
        data: threatList?.data,
      });
    } else {
      yield put({
        type:ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type:ADMINISTRATION_AFFECTED_VENDOR_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* affectedProductList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedProduct',
    };
    const threatList = yield call(affectedProductListApi, payload);

    if (threatList.success) {
      yield put({
        type:ADMINISTRATION_AFFECTED_PRODUCT_LIST_SUCCESSED,
        data: threatList?.data,
      });
    } else {
      yield put({
        type:ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type:ADMINISTRATION_AFFECTED_PRODUCT_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* preIngestionRules(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'preIngestionRule',
    };
    const preIngestionRuleList = yield call(preIngestionRuleListApi, payload);

    if (preIngestionRuleList.success) {
      yield put({
        type:PRE_INGESTION_RULE_SUCCESSED,
        data: preIngestionRuleList?.data,
      });
    } else {
      yield put({
        type:PRE_INGESTION_RULE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type:PRE_INGESTION_RULE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

/*  */

export function* threatIntelSubCategoryList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelSubCategory',
    };
    const threatList = yield call(threatIntelListAPI, payload);

    if (threatList.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_LIST_SUCCESSED,
        data: threatList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createthreatIntelSubCategoryList(action) {
  const loaderHandle = showToastLoader('Creating threat intelligence subcategory...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelSubCategory',
    };
    const createList = yield call(createthreatIntelAPI, payload);

    if (createList.success) {
      showToastSuccess(createList.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_CREATE_SUCCESSED,
        data: createList?.data,
      });
      yield put(threatIntelSubCategoryListAction());
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
        data: null,
      });
      showToastError('Threat intelligence subcategory creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('threat intelligence subcategory already exists', loaderHandle)
  }
}

export function* deletethreatIntelSubCategoryList(action) {
  const loaderHandle = showToastLoader('Deleting threat intelligence subcategory...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelSubCategory',
    };
    const deleteList = yield call(deletethreatIntelAPI, payload);

    if (deleteList.success) {
      showToastSuccess(deleteList.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(threatIntelSubCategoryListAction());
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
        data: null,
      });
      showToastError('Threat intelligence subcategory delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat intelligence subcategory delete failed', loaderHandle)
  }
}

export function* updatethreatIntelSubCategoryList(action) {
  const loaderHandle = showToastLoader('Updating threat intelligence subcategory...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelSubCategory',
    };
    const updateList = yield call(updateThreatIntelAPI, payload);

    if (updateList.success) {
      showToastSuccess(updateList.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      yield put(threatIntelSubCategoryListAction());
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
        data: null,
      });
      showToastError('Threat intelligence subcategory update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat intelligence subcategory already exists', loaderHandle);
  }
}
export function* threatIntelSeverityList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelSubCategorySeverity',
    };
    const vulnerabilityList = yield call(threatIntelListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_SEVERITY_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_SEVERITY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* threatIntelPrimaryCateogryList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelSubCategoryPrimaryCategory',
    };
    const vulnerabilityList = yield call(threatIntelListAPI, payload);

    if (vulnerabilityList.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_SUCCESSED,
        data: vulnerabilityList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* getThreatIntelDispositionList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelDispositions',
    };
    const dispositionList = yield call(threatIntelListAPI, payload);
    if (dispositionList.success) {
      yield put({
        type: ADMINISTRATION_GET_DISPOSITION_LIST_SUCCESSED,
        data: dispositionList?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_DISPOSITION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_DISPOSITION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createThreatIntelDispositionList(action) {
  const loaderHandle = showToastLoader('Creating threat type...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelDispositions',
    };
    const createList = yield call(createthreatIntelAPI, payload);

    if (createList.success) {
      showToastSuccess('Threat type created', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_CREATE_SUCCESSED,
        data: createList?.data,
      });
      yield put(getThreatDispositionList());
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
        data: null,
      });
      showToastError('Threat type creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat type already exists', loaderHandle)
  }
}

export function* updateThreatIntelDispositionList(action) {
  const loaderHandle = showToastLoader('Updating threat type...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelDispositions',
    };
    const updateList = yield call(updateThreatIntelAPI, payload);

    if (updateList.success) {
      showToastSuccess('Threat type updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      yield put(getThreatDispositionList());
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
        data: null,
      });
      showToastError('Threat type update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat type already exists', loaderHandle)
  }
}

export function* deleteThreatIntelDispositionList(action) {
  const loaderHandle = showToastLoader('Deleting threat type...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'threatIntelDispositions',
    };
    const deleteList = yield call(deletethreatIntelAPI, payload);
    if (deleteList.success) {
      showToastSuccess('Threat type deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(getThreatDispositionList());
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
        data: null,
      });
      showToastError('Threat type delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat type delete failed', loaderHandle)
  }
}

export function* automationIngestionList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationIngestionSource',
    };
    const workflowData = yield call(automationListAPI, payload);
    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteAutomationIngestionIO(action) {
  const loaderHandle = showToastLoader('Deleting ingestion source...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationIngestionSource',
    };
    const deleteList = yield call(
      deleteRiskManagementThreatRegisterAPI,
      payload
    );
    if (deleteList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_SUCCESSED,
        data: deleteList?.data,
      });
      showToastSuccess('Ingestion source deleted', loaderHandle)
      yield put(automationIngestionLists({ queryItem: action.payload.query }));
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Ingestion source delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Ingestion source delete failed', loaderHandle)
  }
}

export function* watchProbabilityGetDefault(action) {
  try {
    const reponse = yield call(probabilityGetDefaultAPI, action.payload);
    if (reponse.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_SUCCESSED,
        data: reponse?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProbabilityList(action) {
  try {
    const response = yield call(Riskprobability, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchDeleteProbabilityList(action) {
  const loaderHandle = showToastLoader('Deleting probability...')
  try {
    const response = yield call(
      deleteRiskprobability,
      action.id
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data[1], loaderHandle)
      yield put(riskManagementProbablity({ queryItem: action.query }));

    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_FAILED,
        data: null,
      });
      showToastError('Deleting probability failed', loaderHandle)
    }

  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Deleting probability failed', loaderHandle)
  }
}

export function* watchUpdateProbabilityList(action) {
  const loaderHandle = showToastLoader('Updating probability...')
  try {
    const response = yield call(
      updateRiskprobability,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data[1], loaderHandle)
      yield put(riskManagementProbablity({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_FAILED,
        data: null,
      });
      showToastError('Updating probability failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Updating probability failed', loaderHandle)
  }
}

export function* watchCreateProbabilityList(action) {
  const loaderHandle = showToastLoader('Creating probability...')
  try {
    const response = yield call(
      createRiskprobability,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data[1], loaderHandle)
      yield put(riskManagementProbablity({ queryItem: action.query }));
    } else {
      showToastError('Creating probability failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Creating probability failed', loaderHandle)
  }
}

export function* watchChangeTitle(action) {
  const loaderHandle = showToastLoader('Changing title...')
  try {
    const response = yield call(
      changeProbabilityTitle,
      action.payload,
      action.isRiskScore
    );
    if (response.success) {
      showToastSuccess(response?.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_SUCCESSED,
        data: response?.data,
      });
      yield put(getAllTabbsHeading({ module: 'riskManagement', payload: 'riskmanagementProbability' }));
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_FAILED,
        data: null,
      });
      showToastError('Changing title failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Changing title failed', loaderHandle)
  }
}

// Skipped
export function* watchUpdateFormula(action) {
  const loaderHandle = showToastLoader('Updating formula...')
  try {
    const response = yield call(
      updateFormula,
      action.payload,
      action.isRiskScore
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_SUCCESSED,
        data: response?.data,
      });
      if (action.isRiskScore) {
        showToastSuccess(response?.data[1], loaderHandle)
      } else {
        dismissToastLoader(loaderHandle)
      }

      if (action?.payload?.lhv_select === "custom") {
        yield put(riskManagementProbablity({}));
      }
    } else {
      showToastError('Formula update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Formula update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetFormula(action) {
  const loaderHandle = showToastLoader('Getting formula...')
  try {
    const response = yield call(
      getFormula
    );
    if (response.success) {
      dismissToastLoader(loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_FAILED,
        data: null,
      });
      showToastError('Error while getting formula', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Error while getting formula', loaderHandle)
  }
}

export function* watchCaseGetTabsHeadings(action) {
  try {
    const response = yield call(caseGetTabsHeadingsApi, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_GET_TABS_HEADING_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_TABS_HEADING_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_GET_TABS_HEADING_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetRiskMatrix() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getRiskMatrixAPi);

    if (response.success) {
      yield put({ type: HIDE_LOADER });
      yield put({
        type: ADMINISTRATION_RISK_MATRIX_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put({ type: HIDE_LOADER });
    } else {
      yield put({ type: HIDE_LOADER });
      yield put({
        type: ADMINISTRATION_RISK_MATRIX_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    //  debugger
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_RISK_MATRIX_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreateRiskMatrix(action) {
  const loaderHandle = showToastLoader('Creating risk matrix...')
  try {
    const response = yield call(createRiskMatrixAPi, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CREATE_RISK_MATRIX_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data[1], loaderHandle)
    } else {
      yield put({
        type: ADMINISTRATION_CREATE_RISK_MATRIX_FAILED,
        data: null,
      });
      showToastError('Error while creating risk matrix', loaderHandle)

    }

  } catch (err) {
    yield put({
      type: ADMINISTRATION_CREATE_RISK_MATRIX_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Error while creating risk matrix', loaderHandle)
  }
}

export function* watchResetRiskMatrix(action) {
  const loaderHandle = showToastLoader('Resetting risk matrix...')
  try {
    const response = yield call(resetRiskMatrixAPi, action.payload);
    if (response.success) {
      showToastSuccess(response?.data[1], loaderHandle)
      yield put({
        type: ADMINISTRATION_RESET_RISK_MATRIX_SUCCESSED,
        data: response?.data,
      });
      yield put(getRiskMtrix());
    } else {
      yield put({
        type: ADMINISTRATION_RESET_RISK_MATRIX_FAILED,
        data: null,
      });
      showToastError('Error while resetting risk matrix', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_RESET_RISK_MATRIX_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Error while resetting risk matrix', loaderHandle)
  }
}

export function* watchRiskMetaList() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getRiskMetaAPI);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRiskMetaLabelUpdate(action) {
  // debugger;
  const loaderHandle = showToastLoader('Updating label...')
  try {
    const response = yield call(riskMetaLabelUpdate, action.id, action.payload);
    if (response.success) {
      showToastSuccess('Label updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetRiskMetaList());
    } else {
      showToastError('Error while updating label', loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Error while updating label', loaderHandle)
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRiskMetaUpdate(action) {
  const loaderHandle = showToastLoader('Updating risk meta...')
  try {
    const response = yield call(riskMetaUpdateAPI, action.payload);
    if (response.success) {
      showToastSuccess('Risk meta updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetRiskMetaList());
    } else {
      yield put({
        type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_FAILED,
        data: null,
      });
      showToastError('Error while updating risk meta', loaderHandle)
    }
  } catch (err) {
    showToastError('Error while updating risk meta', loaderHandle)
    yield put({
      type: ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetRiskMatrixDropdownData(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const rangeFromResponse = yield call(getRiskMatrixRangeFromApi, action.payload);
    if (rangeFromResponse.success) {
      yield put({
        type: ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_SUCCESSED,
        data: rangeFromResponse?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED,
        data: null,
      });
    }

    const rangeTResponse = yield call(getRiskMatrixRangeToApi, action.payload);
    if (rangeTResponse.success) {
      yield put({
        type: ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_SUCCESSED,
        data: rangeTResponse?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_RISK_MATRIX_RANGE_TO_FAILED,
        data: null,
      });
    }

    const colorListResponse = yield call(getRiskMatrixColorListApi, action.payload);
    if (colorListResponse.success) {
      yield put({
        type: ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_SUCCESSED,
        data: colorListResponse?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_GET_RISK_MATRIX_COLOR_LIST_FAILED,
        data: null,
      });
    }

    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_GET_RISK_MATRIX_RANGE_FROM_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchIngestionType(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionType',
    };
    const deleteList = yield call(allIngestionAPI, payload);

    if (deleteList.success) {
      yield put({
        type: INGESTION_TYPE_SUCCESSED,
        data: deleteList?.data,
      });
    } else {
      yield put({
        type: INGESTION_TYPE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: INGESTION_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchIngestionWidget(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionWidget',
    };
    const deleteList = yield call(allIngestionAPI, payload);

    if (deleteList.success) {
      yield put({
        type: INGESTION_WIDGET_SUCCESSED,
        data: deleteList?.data,
      });
    } else {
      yield put({
        type: INGESTION_WIDGET_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: INGESTION_WIDGET_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchInjestionFrequency(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionFrequence',
    };
    const deleteList = yield call(allIngestionAPI, payload);

    if (deleteList.success) {
      yield put({
        type: INGESTION_FREQUENCY_SUCCESSED,
        data: deleteList?.data,
      });
    } else {
      yield put({
        type: INGESTION_FREQUENCY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: INGESTION_FREQUENCY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchIngestionApplication(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionApplication',
    };
    const deleteList = yield call(allIngestionAPI, payload);

    if (deleteList.success) {
      yield put({
        type: INGESTION_APPLICATION_SUCCESSED,
        data: deleteList?.data,
      });
    } else {
      yield put({
        type: INGESTION_APPLICATION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: INGESTION_APPLICATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchInjestionUsers(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionUsers',
    };
    const deleteList = yield call(allIngestionAPI, payload);

    if (deleteList.success) {
      yield put({
        type: INGESTION_USERS_SUCCESSED,
        data: deleteList?.data,
      });
    } else {
      yield put({
        type: INGESTION_USERS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: INGESTION_USERS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchIngestionAction(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionAction',
    };
    const deleteList = yield call(ingestionActionAPI, payload);

    if (deleteList.success) {
      yield put({
        type: INGESTION_ACTION_SUCCESSED,
        data: deleteList?.data,
      });
    } else {
      yield put({
        type: INGESTION_ACTION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: INGESTION_ACTION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAdvisory(action) {
  const loaderHandle = showToastLoader('Updating ingestion source...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'enableAdvisory',
    };
    const enableAdvisory = yield call(enableAdvisoryAPI, payload);

    if (enableAdvisory.success) {
      yield put({
        type: ENABLE_ADVISORY_SUCCESSED,
        data: enableAdvisory?.data,
      });
      const data = enableAdvisory?.data?.message
      showToastSuccess('Ingestion Source updated', loaderHandle)
    } else {
      yield put({
        type: ENABLE_ADVISORY_FAILED,
        data: null,
      });
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: ENABLE_ADVISORY_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchActionOutput(action) {
  try {
    const response = yield call(automationActionOutput, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}


export function* watchExecuteScript(action) {
  const loaderHandle = showToastLoader('Executing script...')
  try {
    const response = yield call(executeScript, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data?.message, loaderHandle);
      if(action.callback) action.callback(response.data);
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Script execution failed', loaderHandle)
      if(action.callback) action.callback({error: response?.data?.message});
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.message || 'Script execution failed', loaderHandle)
    if(action.callback) action.callback({error: err?.response?.data?.data?.message});
  }
}

export function* watchSaveIntegration(action) {
  const loaderHandle = showToastLoader('Saving script...')
  try {
    const response = yield call(saveIntegration, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response?.data?.message, loaderHandle);
      if(action.callback) action.callback(response.data);
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Script script failed', loaderHandle)
      if(action.callback) action.callback('');
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.message || 'Script script failed', loaderHandle)
    if(action.callback) action.callback('');
  }
}


export function* createAutomationIngestion(action) {
  const loaderHandle = showToastLoader('Configuring ingestion source...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationIngestionSource',
    };
    const createList = yield call(automationCreateListAPI, payload);
    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Ingestion source created', loaderHandle)
      yield put(automationIngestionLists({ queryItem: action.payload.query }));
    } else {
      showToastError('Ingestion source creation failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Ingestion source creation failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationConfigurationIngestionPost(action) {
  const loaderHandle = showToastLoader('Creating inputs...')
  try {
    const createList = yield call(automationActionConfigurationsPost, action.id, action.payload);
    if (createList.success) {
      showToastSuccess('Inputs created', loaderHandle)
      yield put({
        type: AUTOMATION_ACTION_CONFIGURATION_POST_SUCCESSED,
        data: createList?.data,
      });
    } else {
      showToastError('Inputs creation failed', loaderHandle)
    }
  } catch (err) {
    showToastError('Inputs creation failed', loaderHandle)
    yield put({
      type: AUTOMATION_ACTION_CONFIGURATION_POST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationViewScript(action) {
  try {
    const createList = yield call(automationViewAction, action.id);
    if (createList.success) {
      yield put({
        type: AUTOMATION_ACTION_VIEW_SCRIPT_SUCCESSED,
        data: createList?.data,
      })
    }
  } catch (err) {
    showToastError('Something went wrong')
    yield put({
      type: AUTOMATION_ACTION_VIEW_SCRIPT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationactionUpdateList(action) {
  const loaderHandle = showToastLoader('Configuring ingestion source...')
  try {
    const createList = yield call(automationActionsMultiPostData, action.id, action.payload);
    if (createList.success) {
      const msg = createList?.data[1]
      showToastSuccess(msg || 'Ingestion source created', loaderHandle)
      yield put({
        type: AUTOMATION_ACTION_MULTIDATA_POST_SUCCESSED,
        data: createList?.data,
      })
      yield put(automationIngestionLists({ queryItem: action.payload.query }));
    } else {
      showToastError('Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: AUTOMATION_ACTION_MULTIDATA_POST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateAutomationIngestion(action) {
  const loaderHandle = showToastLoader('Updating ingestion source...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationIngestionSource',
    };
    const updateList = yield call(automationUpdateListAPI, payload);

    if (updateList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_SUCCESSED,
        data: updateList?.data,
      });
      showToastSuccess('Ingestion source updated', loaderHandle)
      yield put(automationIngestionLists({ queryItem: action.payload.query }));
    } else {
      showToastError('Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_UPDATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationActionsList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActions',
    };
    const workflowData = yield call(automationListAPI, payload);
    if (workflowData.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AUTOMATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* automationConfigurationList(action) {
  try {
    const response = yield call(automationActionConfigurations, action.id);
    if (response.success) {
      yield put({
        type: AUTOMATION_ACTION_CONFIGURATION_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: AUTOMATION_ACTION_CONFIGURATION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: AUTOMATION_ACTION_CONFIGURATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* actionsType(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsType',
    };
    const workflowData = yield call(allIngestionAPI, payload);
    if (workflowData.success) {
      yield put({
        type: ACTIONS_TYPE_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ACTIONS_TYPE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ACTIONS_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* actionsScript(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsScriptType',
    };
    const workflowData = yield call(allIngestionAPI, payload);
    if (workflowData.success) {
      yield put({
        type: ACTIONS_SCRIPT_TYPE_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ACTIONS_SCRIPT_TYPE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ACTIONS_SCRIPT_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* actionsIoType(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsIOType',
    };
    const workflowData = yield call(allIngestionAPI, payload);
    if (workflowData.success) {
      yield put({
        type: ACTIONS_IO_TYPE_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ACTIONS_IO_TYPE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ACTIONS_IO_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* actionsApplication(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActionsApplicationType',
    };
    const workflowData = yield call(allIngestionAPI, payload);
    if (workflowData.success) {
      yield put({
        type: ACTIONS_APPLICATION_SUCCESSED,
        data: workflowData?.data,
      });
    } else {
      yield put({
        type: ACTIONS_APPLICATION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ACTIONS_APPLICATION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createAutomationList(action) {
  const loaderHandle = showToastLoader('Creating automation action...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActions',
    };
    const createList = yield call(automationCreateListAPI, payload);

    if (createList.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: createList?.data,
      });
      showToastSuccess('Automation action created', loaderHandle)
      yield put(automationActionsLists({ queryItem: action.payload.query }));
    } else {
      showToastError(createList?.data?.message || 'Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message || 'Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data?.message,
    });
  }
}

export function* updatAutomationActionList(action) {
  const loaderHandle = showToastLoader('Updating automation action...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActions',
    };
    const response = yield call(automationUpdateListAPI, payload);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Automation action updated', loaderHandle)
      yield put(automationActionsLists({ queryItem: action.payload.query }));
    } else {
      showToastError(response?.data?.message || 'Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message || 'Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteAutomationActionList(action) {
  const loaderHandle = showToastLoader('Deleting automation action...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'automationActions',
    };
    const response = yield call(automationDeleteListAPI, payload);

    if (response.success) {
      showToastSuccess('Automation action deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_AUTOMATION_CREATE_LIST_SUCCESSED,
        data: response?.data,
      });
      yield put(automationActionsLists({ queryItem: action.payload.myQuery }));
    } else {
      showToastError(response?.data?.message || 'Something went wrong', loaderHandle)
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message || 'Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_AUTOMATION_CREATE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* ingestionGetMultiData(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionMultiData',
    };
    const getList = yield call(enableAdvisoryAPI, payload);

    if (getList.success) {
      yield put({
        type: GET_INGESTION_MULTI_DATA_SUCCESSED,
        data: getList?.data,
      });
    } else {
      yield put({
        type: GET_INGESTION_MULTI_DATA_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_INGESTION_MULTI_DATA_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* ingestionGetMapping(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'ingestionMapping',
    };
    const getList = yield call(enableAdvisoryAPI, payload);

    if (getList.success) {
      yield put({
        type: GET_INGESTION_MAPPING_SUCCESSED,
        data: getList?.data,
      });
    } else {
      yield put({
        type: GET_INGESTION_MAPPING_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: GET_INGESTION_MAPPING_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* ingestionAddMultiData(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const payload = {
      ...action.payload,
      tabName: 'ingestionMultiData',
    };
    const addList = yield call(addMultiData, payload);

    if (addList.success) {
      yield put({
        type: ADD_INGESTION_MULTI_DATA_SUCCESSED,
        data: addList?.data,
      });
    } else {
      yield put({
        type: ADD_INGESTION_MULTI_DATA_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADD_INGESTION_MULTI_DATA_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* logsAppList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'logsApps',
    };
    const logsData = yield call(automationListAPI, payload);
    if (logsData.success) {
      yield put({
      type: ADMINISTRATION_LOGS_LIST_SUCCESSED,
      data: logsData?.data,
  });
    } else {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_LOGS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* appLogsNotificationList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'logsApps',
    };
    const logsData = yield call(appLogsNotificationsAPI, payload);
    if (logsData.success) {
      yield put({
      type: APP_LOGS_NOTIFICATION_LIST_SUCCESSED,
      data: logsData?.data,
  });
    } else {
      yield put({
        type: APP_LOGS_NOTIFICATION_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: APP_LOGS_NOTIFICATION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* logsAuthenticationList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'logsAuthentication',
    };
    const logsData = yield call(automationListAPI, payload);

    if (logsData.success) {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_SUCCESSED,
        data: logsData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_LOGS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* logsActivityList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'logsActivity',
    };
    const logsData = yield call(automationListAPI, payload);

    if (logsData.success) {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_SUCCESSED,
        data: logsData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_LOGS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* logsIngestionList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'logsIngestion',
    };
    const logsData = yield call(automationListAPI, payload);

    if (logsData.success) {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_SUCCESSED,
        data: logsData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_LOGS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* logsPreIngestionList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'logsPreIngestion',
    };
    const logsData = yield call(automationListAPI, payload);

    if (logsData.success) {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_SUCCESSED,
        data: logsData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_LOGS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_LOGS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* productsRegisterList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'productsRegister',
    };
    const productsData = yield call(automationListAPI, payload);

    if (productsData.success) {
      yield put({
        type: ADMINISTRATION_PRODUCTS_LIST_SUCCESSED,
        data: productsData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_PRODUCTS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_PRODUCTS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* productsRegisterDeleteList(action) {
  const loaderHandle = showToastLoader('Deleting registeration...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'productsRegister',
    };
    const deleteList = yield call(deletethreatIntelAPI, payload);

    if (deleteList.success) {
      showToastSuccess(deleteList?.data?.message || 'Registration deleted', loaderHandle)

      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(productsRegisterListAction());
    } else {
      showToastError(deleteList?.data?.message || 'Registration delete failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Registration delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* accessUsersList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessControlUsers',
    };
    const accessData = yield call(automationListAPI, payload);

    if (accessData.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_LIST_SUCCESSED,
        data: accessData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* accessUserDeleteList(action) {
  const loaderHandle = showToastLoader('Deleting user...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessControlUsers',
    };

    const deleteList = yield call(deletethreatIntelAPI, payload);

    if (deleteList.success) {
      showToastSuccess('User deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED,
        data: deleteList?.data,
      });

    } else {
      showToastError('Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* authenticateType(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessUsersAuthenticationTypes',
    };
    const accessData = yield call(allIngestionAPI, payload);

    if (accessData.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_SUCCESSED,
        data: accessData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* accessPermission(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessUsersPermission',
    };
    const accessData = yield call(allIngestionAPI, payload);

    if (accessData.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_PERMISSION_SUCCESSED,
        data: accessData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_PERMISSION_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_PERMISSION_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* accessDashboards(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessUsersDashboards',
    };
    const accessData = yield call(allDashboardsApi, payload);

    if (accessData.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_DASHBOARDS_SUCCESSED,
        data: accessData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_DASHBOARDS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_DASHBOARDS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* accessLandingPage(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessUsersLandingPage',
    };
    const accessData = yield call(allIngestionAPI, payload);

    if (accessData.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_LANDING_PAGE_SUCCESSED,
        data: accessData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_LANDING_PAGE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* accessGroup(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'accessUsersGroup',
    };
    const accessData = yield call(allIngestionAPI, payload);

    if (accessData.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_GROUP_SUCCESSED,
        data: accessData?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_GROUP_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* WatchSessionPassword(action) {
  try {
    const response = yield call(sessionPassword);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* WatchuUpdateSessionPassword(action) {
  const loaderHandle = showToastLoader('Updating session & password...')
  try {
    const response = yield call(sessionPasswordUpdate, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Session & password updated', loaderHandle)
      yield put(getSessionPassword());
    } else {
      showToastError('Session & password update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Session & password update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessUserUpdate(action) {
  const loaderHandle = showToastLoader('Updating user...')
  try {
    const response = yield call(userAccessUpdate, action.id, action.payload);
    if (response.success) {
      showToastSuccess("User updated", loaderHandle)

      yield put({
        type: ADMINISTRATION_ACCESS_USERS_UPDATE_SUCCESS,
        data: response?.data,
      });
      yield put(accessControlUserlist({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_USERS_UPDATE_FAILED,
        data: null,
      });
      showToastError('User update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_USERS_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data[0], loaderHandle)
  }
}

export function* watchAccessUserPasswordReset(action) {
  const loaderHandle = showToastLoader('Resetting password...')
  try {
    const response = yield call(userResetPassword, action.id, action.payload);
    if (response.success) {
      showToastSuccess(`${response?.data.message}`, loaderHandle)
      action.onCloseModal()
      yield put({
        type: ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_FAILED,
        data: null,
      });
      showToastError('Password reset failed', loaderHandle)
    }
  } catch (err) {
    dismissToastLoader(loaderHandle)
    const errMessage = err?.response?.data?.data?.new_password?.[0]
      || 'Password reset failed'
    action.setResetError(errMessage)
    yield put({
      type: ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessInviteUser(action) {
  const loaderHandle = showToastLoader('Sending invitation...')
  try {
    const response = yield call(userAccessInviteUser, action.payload);
    if (response.success) {
      showToastSuccess('Invitation sent', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_USER_INVITE_SUCCESSED,
        data: response?.data,
      });
      yield put(accessControlUserlist({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_USER_INVITE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_USER_INVITE_FAILED,
      data: err?.response?.data?.data,
    });
    if (err?.response?.data?.message) {
      showToastError(err?.response?.data?.message, loaderHandle)
    } else {
      showToastError(err?.response?.data?.message, loaderHandle)
    }
  }
}

export function* watchAccessControlGroups(action) {
  try {
    const response = yield call(accessControlGroups, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUPS_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_GROUPS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessGroupsUpdate(action) {
  const loaderHandle = showToastLoader('Updating group...')
  try {
    const response = yield call(updateAccessContorlGroup, action.id, action.payload);
    if (response.success) {
      showToastSuccess(response?.data[0], loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlGroupsTab({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_FAILED,
        data: null,
      });
      showToastError('Group already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Group already exists', loaderHandle)
  }
}

export function* watchAccessGroupsCreate(action) {
  const loaderHandle = showToastLoader('Creating group...')
  try {
    const response = yield call(createAccessControlGroup, action.payload);
    if (response.success) {
      showToastSuccess(response?.data[0], loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlGroupsTab({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_FAILED,
        data: null,
      });
      showToastError('Group creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Group already exists', loaderHandle)
  }
}

export function* watchAccessGroupsDelete(action) {
  const loaderHandle = showToastLoader('Deleting group...')
  try {
    const response = yield call(deleteAccessContorlGroup, action.id);
    if (response.success) {
      showToastSuccess(response?.data?.message || 'User Group Delete Successfully!', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlGroupsTab({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_FAILED,
        data: null,
      });
      showToastError('Group delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Group delete failed', loaderHandle)
  }
}

export function* watchAccessControlRoles(action) {
  try {
    const response = yield call(accessControlRoles, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLES_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_ROLES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessRoleUpdate(action) {
  const loaderHandle = showToastLoader('Updating user role...')
  try {
    const response = yield call(updateAccessControlRole, action.id, action.payload);
    if (response.success) {
      showToastSuccess('User role updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlRolesTab({ queryItem: action.query }));
    } else {
      showToastError(`Something went wrong!`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(`Role already exists`, loaderHandle)
  }
}

export function* watchAccessRoleCreate(action) {
  const loaderHandle = showToastLoader('Creating user role...')
  try {
    const response = yield call(createAccessControlRole, action.payload);
    if (response.success) {
      showToastSuccess('User role created', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlRolesTab({ queryItem: action.query }));
    } else {
      showToastError(`Something went wrong!`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(`Role already exists`, loaderHandle)
  }
}

export function* watchAccessRoleDelete(action) {
  const loaderHandle = showToastLoader('Deleting user role...')
  try {
    const response = yield call(deleteAccessControlRole, action.id);
    if (response.success) {
      showToastSuccess('User role deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlRolesTab({ queryItem: action.query }));
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_FAILED,
        data: null,
      });
      showToastError(`Can not delete this role because it has been tagged in one or more users!`, loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(`Can not delete this role because it has been tagged in one or more users!`, loaderHandle)
  }
}

export function* watchAccessControlActions(action) {
  try {
    const response = yield call(accessControlActions, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlActionGroup(action) {
  try {
    const response = yield call(accessControlActionsGroup, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessActionsUpdate(action) {
  const loaderHandle = showToastLoader('Updating actions...')
  try {
    const response = yield call(updateAccessControlAction, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlActionTab({ queryItem: action.query }));
      showToastSuccess(`Action updated`, loaderHandle)
    } else {
      showToastError(`Action update failed`, loaderHandle)
    }
  } catch (err) {
    showToastError(`Action update failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessActionsCreate(action) {
  const loaderHandle = showToastLoader('Creating actions...')
  try {
    const response = yield call(createAccessControlAction, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(`Action created`, loaderHandle)
      yield put(onGetAccessControlActionTab({ queryItem: action.query }));
    } else {
      showToastError(`Action creation failed`, loaderHandle)
    }
  } catch (err) {
    showToastError(`Action creation failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessActionsDelete(action) {
  const loaderHandle = showToastLoader('Deleting actions...')
  try {
    const response = yield call(deleteAccessControlaction, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(`Action deleted`, loaderHandle)
      yield put(onGetAccessControlActionTab({ queryItem: action.query }));
    } else {
      showToastError(`Deleting action failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Deleting action failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlActionsGroupTab(action) {
  try {
    const response = yield call(accessControlActionsGroupTab, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessActionsGroupUpdate(action) {
  const loaderHandle = showToastLoader('Updating action group...')
  try {
    const response = yield call(updateAccessControlActionGroup, action.id, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(`Action group updated`, loaderHandle)

      yield put(onGetAccessControlActionGroupTab({ queryItem: action.query }));
    }
    else {
      showToastError(`Action group update failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Action group update failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessActionsGroupCreate(action) {
  const loaderHandle = showToastLoader('Creating action group...')
  try {
    const response = yield call(createAccessControlActionGroup, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(`Action group created`, loaderHandle)
      yield put(onGetAccessControlActionGroupTab({ queryItem: action.query }));
    } else {
      showToastError(`Action group creation failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Action group creation failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessActionsGroupDelete(action) {
  const loaderHandle = showToastLoader('Deleting action group...')
  try {
    const response = yield call(deleteAccessControlActionGroup, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(`Action group deleted`, loaderHandle)
      yield put(onGetAccessControlActionGroupTab({ queryItem: action.query }));
    } else {
      showToastError(`Action group delete failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Action group delete failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlAuthSetting(action) {
  try {
    const response = yield call(accessControlAuthSettings, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_SUCCESSED,
        data: response?.data,
      })
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlAuthenticationType() {
  try {
    const response = yield call(accessControlAuthSettingAuthencticationType);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlAuthenticationVendors() {
  try {
    const response = yield call(accessControlAuthSettingAuthencticationVendors);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchUpdateThirdPartyAuth(action) {
  const loaderHandle = showToastLoader('Updating third-party auth...')
  try {
    const response = yield call(updateAccessControlThirdPartyAuth, action.id, action.payload);
    if (response.success) {
      showToastSuccess(`Third-party auth updated`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlAuthSettingList({ queryItem: action.query }));
    } else {
      showToastError(`Third-party auth update failed`, loaderHandle)
    }
  } catch (err) {
    showToastError(`Third-party auth update failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreateThirdPartyAuth(action) {
  const loaderHandle = showToastLoader('Creating third-party auth...')
  try {
    const response = yield call(createAccessControlThirdPartyAuth, action.payload);
    if (response.success) {
      showToastSuccess(`Third-party auth created`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlAuthSettingList({ queryItem: action.query }));
    } else {
      showToastError(`Third-party auth creation failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Third-party auth creation failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteThirdPartyAuth(action) {
  const loaderHandle = showToastLoader('Deleting third-party auth...')
  try {
    const response = yield call(deleteAccessControlThirdPartyAuth, action.id);
    if (response.success) {
      showToastSuccess(`Third-party auth deleted`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetAccessControlAuthSettingList({ queryItem: action.query }));
    } else {
      showToastError(`Third-party auth delete failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Third-party auth delete failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlPrevileges(action) {
  try {
    const response = yield call(accessControlPrivileges, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlAllPrevileges(action) {
  try {
    const response = yield call(accessControlAllPrivileges, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlPrevilegesDelete(action) {
  const loaderHandle = showToastLoader('Deleting privilege...')
  try {
    const response = yield call(accessControlPrivilegeDelete, action.id);
    if (response.success) {
      showToastSuccess(`Privilege deleted`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetListOfPrivileges({ queryItem: action.query || "" }));
    } else {
      showToastError(`Privilege delete failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Privilege delete failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlPrevilegesUpdate(action) {
  const loaderHandle = showToastLoader('Updating privilege...')
  try {
    const response = yield call(accessControlPrivilegeUpdate, action.id, action.payload);
    if (response.success) {
      showToastSuccess(`Privilege updated`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetListOfPrivileges({ queryItem: action.query || "" }));
    } else {
      showToastError(`Privilege update failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Privilege update failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlPrevilegesCreate(action) {
  const loaderHandle = showToastLoader('Creating privilege...')
  try {
    const response = yield call(accessControlPrivilegeCreate, action.payload);
    if (response.success) {
      showToastSuccess(`Privilege created`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetListOfPrivileges({ queryItem: action.query || "" }));
    } else {
      showToastError(`Privilege creation failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Privilege creation failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAccessControlPrevilegesGroup() {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(accessControlPrivilegeGroup);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingFeedbackList(action) {
  try {
    const response = yield call(accessControlProductSettingFeedback, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteProductSettingFeedback(action) {
  const loaderHandle = showToastLoader('Deleting setting feedback...')
  try {
    const response = yield call(deleteProductSettingFeedback, action.id);
    if (response.success) {
      showToastSuccess(response?.data?.message, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetListOfProductFeedback({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || `Setting feedback delete failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Setting feedback delete failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingWidget(action) {
  try {
    const response = yield call(accessControlProductSettingWidgets, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingBackupList(action) {
  try {
    const response = yield call(accessControlProductSettingBackup, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchImportBackup(action) {
  const loaderHandle = showToastLoader('Importing backup...')
  try {
    const response = yield call(importBackup, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(`Backup imported`, loaderHandle)
    } else {
      showToastError(response?.data?.message || `Backup import failed`, loaderHandle)
    }
  } catch (err) {
    showToastError(`Backup import failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchConfigureRestoreBackup(action) {
  const loaderHandle = showToastLoader('Backup configuration saved')
  const { closeDrawer, toggleDisableBtn, ...rest } = action.payload;
  try {
    const response = yield call(configureRestoreBackupAPI, rest);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess(response.data[1] || 'Backup configuration saved', loaderHandle)
      closeDrawer();
      toggleDisableBtn();
    } else {
      showToastError(response?.data?.message, loaderHandle);
      toggleDisableBtn();
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message, loaderHandle);
    toggleDisableBtn();
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSFTPConfigure(action) {
  const loaderHandle = showToastLoader('Saving SFTP configuration')
  const {toggleDisableBtn, ...rest } = action.payload;
  try {
    const response = yield call(sftpconfigureAPI, rest);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_SUCCESSED,
        data: response?.data,
      });
      toggleDisableBtn();
      showToastSuccess(response.data[1] || 'SFTP configuration saved', loaderHandle)
    } else {
      toggleDisableBtn();
      showToastError('Connection problem', loaderHandle)
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message, loaderHandle);
    toggleDisableBtn();
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSFTPConfigureOld(action) {
  const loaderHandle = showToastLoader('SFTP Configure...')
  const { closeDrawer, toggleDisableBtn, ...rest } = action.payload;
  try {
    const response = yield call(sftpconfigureAPI, rest);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_SUCCESSED,
        data: response?.data,
      });
      closeDrawer();
      toggleDisableBtn();
      showToastSuccess(response.data[1] || 'SFTP save', loaderHandle)
    } else {
      toggleDisableBtn();
      showToastError('Connection problem', loaderHandle)
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message, loaderHandle);
    toggleDisableBtn();
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_SFTP_CONFIGURE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchRestoreBackup(action) {
  const loaderHandle = showToastLoader('Restoring backup...')
  try {
    const response = yield call(RestoreBackup, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Backup Restored', loaderHandle)
    } else {
      showToastError(response?.data?.message || `Restoring backup failed`, loaderHandle)
    }
  } catch (err) {
    showToastError(`Restoring backup failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchNotifyWatchList() {
  try {
    const response = yield call(notifyUserList);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingBackupDelete(action) {
  const loaderHandle = showToastLoader('Deleting backup...')
  try {
    const response = yield call(deleteProductSettingBackup, action.id);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Backup deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetListofBackups({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || `Backup delete failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Backup delete failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreateBackup(action) {
  const loaderHandle = showToastSuccess(`Please wait while backup is being created. Feel free to move around. You'll be notified once it's done.`)
  try {
    const response = yield call(createBackupList, action.payload);
    if (response.success) {
      showToastSuccess(response?.data[0] || 'Backup created', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetListofBackups({ queryItem: action.query }));
    } else {
      showToastError(`Backup creation failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    if (err?.response?.data?.data.message) {
      showToastError(err?.response?.data?.data.message, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_FAILED,
        data: err?.response?.data?.data,
      });
    } else {
      showToastSuccess('Backup in process', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_FAILED,
        data: err?.response?.data?.data,
      });
      yield put(onGetListofBackups({ queryItem: action.query }));
    }

  }
}

export function* watchDowloadBackup(action) {
  const loaderHandle = showToastProgress('Downloading backup...', { progress: 0 })
  try {
    const response = yield call(dowloadBackup, action.id, loaderHandle);
    if (response.size) {
      showToastSuccess('Backup downloaded')
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_SUCCESSED,
        data: response?.data,
      });

    } else {
      showToastError('Backup download failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Backup download failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchUpdateWidgets(action) {
  const loaderHandle = showToastLoader('Updating widget...')
  try {
    const response = yield call(updateProductSettingWidget, action.id, action.payload);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Widget updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetWidgetList({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || `Widget update failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Widget update failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetBackupSettingAPI(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getBackupSettingAPI, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchGetSftpConfigureAPI(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(getSftpConfigureAPI, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED,
        data: null,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreateWidgets(action) {
  const loaderHandle = showToastLoader('Creating widget...')
  try {
    const response = yield call(createProductSettingWidget, action.payload);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Widget created', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetWidgetList({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || `Widget creation failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Widget creation failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteWidget(action) {
  const loaderHandle = showToastLoader('Deleting widget...')
  try {
    const response = yield call(deleteProductSettingWidget, action.id);
    if (response.success) {
      showToastSuccess(`${response?.data?.message}`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetWidgetList({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || `Widget delete failed`, loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError(`Widget delete failed`, loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingWidgetSources() {
  try {
    const response = yield call(accessControlWidgetDataSource);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingWidgetGroup() {
  try {
    const response = yield call(accessControlWidgetDataGroup);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductLicense(action) {
  try {
    const response = yield call(productSettingLicense, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* uploadLicense(action) {
  const loaderHandle = showToastLoader('Uploading license...')
  try {
    const response = yield call(uploadLicenseApi, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || `License uploaded`, loaderHandle)
      yield put(onGetLicencesInfo({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'License upload failed', loaderHandle)
    }
  } catch (err) {
    const msg = err.response?.data?.data?.message;
    showToastError(msg || 'License upload failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* licenceModulesSources() {
  try {
    const response = yield call(getModuleSources);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_SOURCES_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_SOURCES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* createLicense(action) {
  const loaderHandle = showToastLoader('Creating license...')
  try {
    const response = yield call(createLicenceRequest, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || `License created`, loaderHandle)
      yield put(onGetLicencesInfo({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'License creation failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Organization license is already registered', loaderHandle)
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* updateLicense(action) {
  const loaderHandle = showToastLoader('Updating license...')
  try {
    const response = yield call(updateProductSettingLicense, action.id, action.payload);
    if (response.success) {
      showToastSuccess(response?.data?.message, loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetLicencesInfo({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'License update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('License update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* deleteLicense(action) {
  const loaderHandle = showToastLoader('Deleting license...')
  try {
    const response = yield call(deleteProductSettingLicense, action.id);
    if (response.success) {
      showToastSuccess(response?.data?.message, loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_DELETE_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetLicencesInfo({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'License delete failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('License delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* downloadLicense(action) {
  const loaderHandle = showToastLoader('Downloading license...')
  try {
    const response = yield call(downloadProductSettingLicense, action);
    if (response.size) {
      showToastSuccess('License downloaded', loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_SUCCESSED,
        data: response?.data,
      });
      yield put(onGetLicencesInfo({ queryItem: action.query }));
    } else {
      showToastError(response?.data?.message || 'License download failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('License download failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* patchUpdate(action) {
  const loaderHandle = showToastLoader('Updating license patch...')
  try {
    const response = yield call(patchUploadRequest, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_PRODUCT_SETTINGS_PATCH_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('License patch succesfully', loaderHandle)
    } else {
      showToastError('Something went wrong', loaderHandle)
      yield put({
        type: ADMINISTRATION_PRODUCT_SETTINGS_PATCH_FAILED,
        data: null,
      });
    }
  } catch (err) {
    const message = err.response?.data?.data[0];
    showToastError(message || 'Something went wrong', loaderHandle)
    yield put({
      type: ADMINISTRATION_PRODUCT_SETTINGS_PATCH_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchNotificationList() {
  try {
    const response = yield call(notificationListApi);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_NOTIFICATIONS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchProductSettingHealth() {
  const loaderHandle = showToastLoader('Loading server health...')
  try {
    const response = yield call(productSettingHealthAPI);
    if (response.success) {
      showToastSuccess('Server health loaded', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Error while loading health', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Error while loading health', loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_FAILED,
      data: null,
    });

  }
}

export function* watchProductSettingConnectivity() {
  const loaderHandle = showToastLoader('Checking TI connectivity...')
  try {
    const response = yield call(productSettingConnectivityAPI);
    if (response.success) {
      showToastSuccess('TI connectivity loaded', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Error while checking TI connectivity', loaderHandle)
      yield put({
        type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Error while checking TI connectivity', loaderHandle)
    yield put({
      type: ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_FAILED,
      data: null,
    });

  }
}

export function* watchNotificationUpdate(action) {
  const loaderHandle = showToastLoader('Updating notification...')
  try {
    const response = yield call(notificationUpdateApi, action.payload);
    if (response.success) {
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Notification updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_UPDATE_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError(response?.data?.message || 'Notification update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Notification update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_NOTIFICATIONS_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchFieldSettingList() {
  try {
    const response = yield call(fieldSettingListApi);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_FIELD_SETTING_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_FIELD_SETTING_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_FIELD_SETTING_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchFieldSettingUpdate(action) {
  const loaderHandle = showToastLoader('Updating settings...')
  try {
    const response = yield call(fieldSettingUpdateApi, action.payload);
    if (response.success) {
      showToastSuccess('Settings updated', loaderHandle)

      yield put({
        type: ADMINISTRATION_FIELD_SETTING_UPDATE_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Settings update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Settings update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseFieldSettingList() {
  try {
    const response = yield call(fieldCaseSettingListApi);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCaseFieldSettingUpdate(action) {
  const loaderHandle = showToastLoader('Updating ticket fields...')
  try {
    const response = yield call(caseFieldSettingUpdateApi, action.payload);
    if (response.success) {
      showToastSuccess(response?.data?.message || 'Ticket fields updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_FIELD_SETTING_UPDATE_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError(response?.data?.message || 'Ticket field update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Ticket field update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_FIELD_SETTING_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCaseFieldSettingDuplicate(action) {
  const loaderHandle = showToastLoader('Coping field settings...')
  try {
    const response = yield call(caseFieldSettingDuplicateApi, action.payload);
    if (response.success) {
      showToastSuccess('Field settings copied', loaderHandle)
      const response2 = yield call(fieldCaseSettingListApi);
      if (response2.success) {
        yield put({
          type: ADMINISTRATION_CASE_FIELD_SETTING_LIST_SUCCESSED,
          data: response2?.data,
        });
      } else {
        yield put({
          type: ADMINISTRATION_CASE_FIELD_SETTING_LIST_FAILED,
          data: null,
        });
      }
      yield put({
        type: ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Field settings copy failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Field settings copy failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAssociateGroupThreatActor() {
  try {

    const response = yield call(getAssociateThreatActor);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetThreatActorList(action) {
  try {
    const response = yield call(getThreatActor, action.payload);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_SUCCESSED,
        data: {
          ...response.data,
          items : removeSpacesFromFields(response?.data?.items),
        },
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* associateThreatActorList(action) {
  try {
    const payload = {
      ...action.payload,
      tabName: 'associateThreatActor',
    };
    const response = yield call(associateThreatActorListAPI, payload);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchThreatActorCreate(action) {
  const loaderHandle = showToastLoader('Creating threat actor...')
  try {
    const response = yield call(
      administratorThreatActorCreate,
      action.payload
    );
    if (response.success) {
      showToastSuccess('Threat actor created', loaderHandle)

      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_SUCCESSED,
        data: response?.data,
      });
      // yield put(
      //     associateGroupThreatActor({ queryItem: action.query })
      // );
      yield put(threatIntelThreatActorList(action.payload));
    } else {
      showToastError('Threat actor creation failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Threat actor creation failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchThreatActorUpdate(action) {
  const loaderHandle = showToastLoader('Updating threat actor...')
  try {
    const response = yield call(administratorThreatActorUpdate, action.payload);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_SUCCESSED,
        data: response?.data,
      });
      showToastSuccess('Threat actor updated', loaderHandle)
      //yield put(associateGroupThreatActorList());
      yield put(threatIntelThreatActorList(action.payload));
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED,
        data: null,
      });
      showToastError('Threat actor update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Threat actor update failed', loaderHandle)
  }
}

export function* watchThreatActorDelete(action) {
  const loaderHandle = showToastLoader('Deleting threat actor...')
  try {
    const deleteList = yield call(administratorThreatActorDelete, action.payload.id);

    if (deleteList.success) {

      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      showToastSuccess('Threat actor deleted', loaderHandle)
      yield put(threatIntelThreatActorList(action.payload));
    } else {
      showToastError('Threat actor delete failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Threat actor delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetAssociatedThreatActorList(action) {
  try {
    const response = yield call(getAssociatedThreatActor, action.payload);

    if (response.success) {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAssociatedThreatActorCreate(action) {
  const loaderHandle = showToastLoader('Creating associated threat actor...')
  try {
    const response = yield call(
      administratorAssociatedThreatActorCreate,
      action.payload
    );
    if (response.success) {
      showToastSuccess('Associated threat actor created', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_SUCCESSED,
        data: response?.data,
      });
      yield put(threatIntelAssociatedThreatActorList(action.payload));
    } else {
      showToastError('Associated threat actor creation failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Associated threat actor creation failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAssociatedThreatActorUpdate(action) {
  const loaderHandle = showToastLoader('Updating associated threat actor...')
  try {
    const response = yield call(administratorAssociatedThreatActorUpdate, action.payload);
    if (response.success) {
      showToastSuccess('Associated threat actor updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_SUCCESSED,
        data: response?.data,
      });
      yield put(threatIntelAssociatedThreatActorList(action.payload));
    } else {
      showToastError('Associated threat actor update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Associated threat actor update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAssociatedThreatActorDelete(action) {
  const loaderHandle = showToastLoader('Deleting associated threat actor...')
  try {
    const deleteList = yield call(administratorAssociatedThreatActorDelete, action.payload.id);
    if (deleteList.success) {
      showToastSuccess('Associated threat actor deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(threatIntelAssociatedThreatActorList(action.payload));
    } else {
      showToastError('Associated threat actor delete failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Associated threat actor delete failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchLogNotificationUpdate(action) {
  const loaderHandle = showToastLoader('Updating notification...')
  try {
    const response = yield call(logNotificationUpdateApi, action.payload);
    if (response.success) {
      showToastSuccess('Notification updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_SUCCESSED,
        data: response?.data,
      });
    } else {
      showToastError('Notification update failed', loaderHandle)
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_FAILED,
        data: null,
      });
    }
  } catch (err) {
    showToastError('Notification update failed', loaderHandle)
    yield put({
      type: ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchLognotificationList() {
  try {
    const response = yield call(logNotificationListApi);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_LOG_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_NOTIFICATIONS_LOG_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCasesLessonsList(action) {
  try {
    const response = yield call(
      administratorCasesLessonsList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LESSON_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCasesLessonDelete(action) {
  const loaderHandle = showToastLoader('Deleting lesson learned...')
  try {
    const response = yield call(administratorCasesLessonDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Lesson learned deleted', loaderHandle)
      const response2 = yield call(administratorCasesLessonsList, action.query);
      if (response2.success) {
        yield put({ type: ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED, data: response2?.data });
      } else {
        yield put({ type: ADMINISTRATION_CASES_LESSON_LIST_FAILED, data: null });
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Lesson delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LESSON_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Lesson delete failed', loaderHandle)
  }
}

export function* watchCasesLessonCreate(action) {
  const loaderHandle = showToastLoader('Creating lesson learned...')
  try {
    const response = yield call(
      administratorCasesLessonCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message ? response?.data?.message : 'Lesson created';
      showToastSuccess(msg, loaderHandle)
      // yield put(administratorCasesLessonsList({ queryItem: action.query }));
      const response2 = yield call(administratorCasesLessonsList, action.query);
      if (response2.success) {
        yield put({ type: ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED, data: response2?.data });
      } else {
        yield put({ type: ADMINISTRATION_CASES_LESSON_LIST_FAILED, data: null });
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Lesson create failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LESSON_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.message || 'Lesson already exists', loaderHandle)
  }
}
export function* watchCasesLessonUpdate(action) {
  const loaderHandle = showToastLoader('Updating lesson learned...')
  try {
    const response = yield call(
      administratorCasesLessonUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Lesson updated', loaderHandle)
      const response2 = yield call(administratorCasesLessonsList, action.query);
      if (response2.success) {
        yield put({ type: ADMINISTRATION_CASES_LESSON_LIST_SUCCESSED, data: response2?.data });
      } else {
        yield put({ type: ADMINISTRATION_CASES_LESSON_LIST_FAILED, data: null });
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_LESSON_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Lesson already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_LESSON_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Lesson already exists', loaderHandle)
  }
}
export function* watchCasesContainedByList(action) {
  try {
    const response = yield call(
      administratorCasesContainedByList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCasesContainedByDelete(action) {
  const loaderHandle = showToastLoader('Deleting contained by...')
  try {
    const response = yield call(administratorCasesContainedByDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Contained by deleted', loaderHandle)
      const response2 = yield call(administratorCasesContainedByList,action.query);
      if (response2.success) {
        yield put({type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED,data: response2?.data,});
      } else {
        yield put({type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,data: null,});
      }
    
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Contained by delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINED_BY_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Contained by delete failed', loaderHandle)
  }
}

export function* watchCasesContainedByCreate(action) {
  const loaderHandle = showToastLoader('Creating contained by...')
  try {
    const response = yield call(
      administratorCasesContainedByCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Contained by created', loaderHandle)
      const response2 = yield call(administratorCasesContainedByList,action.query);
      if (response2.success) {
        yield put({type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED,data: response2?.data,});
      } else {
        yield put({type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,data: null,});
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Contained by already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINED_BY_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.message || 'Contained by already exists', loaderHandle)
  }
}
export function* watchCasesContainedByUpdate(action) {
  const loaderHandle = showToastLoader('Updating contained by...')
  try {
    const response = yield call(
      administratorCasesContainedByUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message
      showToastSuccess(msg || 'Contained by updated', loaderHandle)
      const response2 = yield call(administratorCasesContainedByList,action.query);
      if (response2.success) {
        yield put({type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_SUCCESSED,data: response2?.data,});
      } else {
        yield put({type: ADMINISTRATION_CASES_CONTAINED_BY_LIST_FAILED,data: null,});
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Contained by already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Contained by already exists', loaderHandle)
  }
}
export function* watchCasesContainmentStatusList(action) {
  try {
    const response = yield call(
      administratorCasesContainmentStatusList,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED,
        data: response?.data,
      });
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchCasesContainmentStatusDelete(action) {
  const loaderHandle = showToastLoader('Deleting containment status...')
  try {
    const response = yield call(administratorCasesContainmentStatusDelete, action.id);
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Containment status deleted', loaderHandle)
      const response2 = yield call(administratorCasesContainmentStatusList,action.query);
      if (response2.success) {
        yield put({type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED,data: response2?.data,});
      } else {
        yield put({type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,data: null,});
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Containment status delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Containment status delete failed', loaderHandle)
  }
}

export function* watchCasesContainmentStatusCreate(action) {
  const loaderHandle = showToastLoader('Creating containment status...')
  try {
    const response = yield call(
      administratorCasesContainmentStatusCreate,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Containment status created', loaderHandle)
      const response2 = yield call(administratorCasesContainmentStatusList,action.query);
      if (response2.success) {
        yield put({type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED,data: response2?.data,});
      } else {
        yield put({type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,data: null,});
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Containment status already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Containment status already exists', loaderHandle)
  }
}
export function* watchCasesContainmentStatusUpdate(action) {
  const loaderHandle = showToastLoader('Updating containment status...')
  try {
    const response = yield call(
      administratorCasesContainmentStatusUpdate,
      action.id,
      action.payload
    );
    if (response.success) {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_SUCCESSED,
        data: response?.data,
      });
      const msg = response?.data?.message;
      showToastSuccess(msg || 'Containment status updated', loaderHandle)
      const response2 = yield call(administratorCasesContainmentStatusList,action.query);
      if (response2.success) {
        yield put({type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_SUCCESSED,data: response2?.data,});
      } else {
        yield put({type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_FAILED,data: null,});
      }
    } else {
      yield put({
        type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_FAILED,
        data: null,
      });
      showToastError(response?.data?.message || 'Containment status already exists', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Containment status already exists', loaderHandle)
  }
}
export function* watchIngestionOutput(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const data = yield call(getIngestionOutputApi, action.payload);
    if (data.success === true) {
      yield put({ type: INGESTION_OUTPUT_SUCCESSED, data: data?.data });
    
    } else {
      yield put({ type: INGESTION_OUTPUT_FAILED, data: null });
    };
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
    yield put({
      type: INGESTION_OUTPUT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchUpdateIngestionOutput(action) {
  const loaderHandle = showToastLoader('Saving sample...')
    try{
    const data = yield call(setIngestionOutputApi, action.payload);
    if (data.success) {
      showToastSuccess('Sample saved', loaderHandle)
      yield put({ type: UPDATE_INGESTION_OUTPUT_SUCCESSED, data: data?.data });
    
    } else {
      yield put({ type: UPDATE_INGESTION_OUTPUT_FAILED, data: null });
      showToastError('Sample save failed', loaderHandle)
    };
  } catch (err) {
    showToastError('Sample save failed', loaderHandle)
    yield put({
      type: UPDATE_INGESTION_OUTPUT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* createAffectedVendor(action) {
  const loaderHandle = showToastLoader('Creating vendor...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedVendor',
    };
    const createList = yield call(createAffectedVendorApi, payload);

    if (createList.success) {
      showToastSuccess('Vendor created', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_CREATE_SUCCESSED,
        data: createList?.data,
      });

      yield put(AffectedVendorList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_CREATE_FAILED,
        data: null,
      });
      showToastError('Vendor creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_VENDOR_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError(err?.response?.data?.data?.ave_organization[0] || 'Vendor creation failed', loaderHandle)
  }
}

export function* updateAffectedVendor(action) {
  const loaderHandle = showToastLoader('Updating vendor...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedVendor',
    };
    const updateList = yield call(updateAffectedVendorApi, payload);
    if (updateList.success) {
      showToastSuccess('Vendor updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      yield put(AffectedVendorList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED,
        data: null,
      });
      showToastError('Vendor update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Vendor update failed', loaderHandle)
  }
}
export function* deleteAffectedVendor(action) {
  const loaderHandle = showToastLoader('Deleting vendor...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedVendor',
    };
    const deleteList = yield call(deleteAffectedVendorApi, payload);

    if (deleteList.success) {
      showToastSuccess('Vendor deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(AffectedVendorList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_DELETE_FAILED,
        data: null,
      });
      showToastError('Vendor delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_VENDOR_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Vendor delete failed', loaderHandle)
  }
}
export function* createAffectedProduct(action) {

  const loaderHandle = showToastLoader('Creating product...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedProduct',
    };
    const createList = yield call(createAffectedProductApi, payload);

    if (createList.success) {
      showToastSuccess('Product created', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_PRODUCT_CREATE_SUCCESSED,
        data: createList?.data,
      });

      yield put(AffectedProductList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_PRODUCT_CREATE_FAILED,
        data: null,
      });
      showToastError('Product creation failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_PRODUCT_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
      showToastError(err?.response?.data?.data?.adp_organization[0] || 'Product creation failed', loaderHandle)
  }
}
export function* updateAffectedProduct(action) {
  const loaderHandle = showToastLoader('Updating product...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedProduct',
    };
    const updateList = yield call(updateAffectedProductApi, payload);
    if (updateList.success) {
      showToastSuccess('Product updated', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_SUCCESSED,
        data: updateList?.data,
      });
      yield put(AffectedProductList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED,
        data: null,
      });
      showToastError('Product update failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_VENDOR_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product update failed', loaderHandle)
  }
}
export function* deleteAffectedProduct(action) {
  const loaderHandle = showToastLoader('Deleting product...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'affectedProduct',
    };
    const deleteList = yield call(deleteAffectedProductApi, payload);

    if (deleteList.success) {
      showToastSuccess('Product deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(AffectedProductList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED,
        data: null,
      });
      showToastError('Product delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Product delete failed', loaderHandle)
  }
}

export function* updateSlaStatus(action) {
  const loaderHandle = showToastLoader('Updating SLA status...')
  try {
    const response = yield call(updateSlaStatusApi, action.payload);
    if (response.success) {
      showToastSuccess('Status updated', loaderHandle)
      const slaResponse = yield call(administratorCasesSlaList, { queryItem: action?.payload?.queryItem })
      if (slaResponse.success) {
        yield put({
          type: ADMINISTRATION_CASES_SLA_LIST_SUCCESSED,
          data: slaResponse?.data,
        });
      } else {
        yield put({
          type: ADMINISTRATION_CASES_SLA_LIST_FAILED,
          data: null,
        });
      }
      yield put({ type: UPDATE_SLA_STATUS_SUCCESSED, data: response });
    } else {
      showToastError('sla status update failed', loaderHandle)
      yield put({ type: UPDATE_SLA_STATUS_FAILED, data: null });
    }
  } catch (err) {
    showToastError('sla status update failed', loaderHandle)
    yield put({
      type: UPDATE_SLA_STATUS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* createPreIngestionRule(action) {

  const loaderHandle = showToastLoader('Creating pre ingestion rule...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'preIngestionRule',
    };
    const createList = yield call(createPreIngestionRuleApi, payload);

    if (createList.success) {
      showToastSuccess('Pre ingestion rule created', loaderHandle)
      yield put({
        type: PRE_INGESTION_RULE_CREATE_SUCCESSED,
        data: createList?.data,
      });
      if (action.payload?.callback) action.payload.callback({ isSuccess: true });
      yield put(preIngestionRuleList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: PRE_INGESTION_RULE_CREATE_FAILED,
        data: null,
      });
      showToastError('Pre ingestion rule creation failed', loaderHandle)
      if (action.payload?.callback) action.payload.callback({ isSuccess: false });
    }
  } catch (err) {
    yield put({
      type: PRE_INGESTION_RULE_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
      showToastError('Pre ingestion rule creation failed', loaderHandle)
      if (action.payload?.callback) action.payload.callback({ isSuccess: false });
    }
}
export function* updatePreIngestionRule(action) {
  const loaderHandle = showToastLoader('Updating pre ingestion rule...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'preIngestionRule',
    };
    const response = yield call(updatePreIngestionRuleApi, payload);
    if (response.success) {
      showToastSuccess('Pre ingestion rule updated', loaderHandle)
      if (action.payload?.callback) action.payload.callback({ isSuccess: true });
      yield put(preIngestionRuleList({ queryItem: action.payload.queryItem }))

      yield put({ type: PRE_INGESTION_RULE_UPDATE_SUCCESSED, data: response });
    } else {
      showToastError('Pre ingestion rule update failed', loaderHandle)
      if (action.payload?.callback) action.payload.callback({ isSuccess: false });
      yield put({ type: PRE_INGESTION_RULE_UPDATE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Pre ingestion rule update failed', loaderHandle)
    if (action.payload?.callback) action.payload.callback({ isSuccess: false });
    yield put({
      type: PRE_INGESTION_RULE_UPDATE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* deletePreIngestionRule(action) {
  const loaderHandle = showToastLoader('Deleting pre ingestion rule...')
  try {
    const payload = {
      ...action.payload,
      tabName: 'preIngestionRule',
    };
    const deleteList = yield call(deletePreIngestionRuleApi, payload);

    if (deleteList.success) {
      showToastSuccess('Pre ingestion rule deleted', loaderHandle)
      yield put({
        type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_SUCCESSED,
        data: deleteList?.data,
      });
      yield put(preIngestionRuleList({ queryItem: action.payload.queryItem }));
    } else {
      yield put({
        type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED,
        data: null,
      });
      showToastError('Pre ingestion rule delete failed', loaderHandle)
    }
  } catch (err) {
    yield put({
      type: ADMINISTRATION_AFFECTED_PRODUCT_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
    showToastError('Pre ingestion rule delete failed', loaderHandle)
  }
}
export default function* watcher() {
  yield takeLatest(
    ADMINISTRATION_ORGANIZATION_LIST_REQUESTED,
    organizationList
  );
  yield takeLatest(
    ADMINISTRATION_ORAGANIZATION_USERS_REQUESTED,
    organizationUsersList
  );
  yield takeLatest(
    ADMINISTRATION_CLASSIFICATION_LIST_REQUESTED,
    classificationList
  );
  yield takeLatest(
    ADMINISTRATION_CLASSIFICATION_UPDATE_LIST_REQUESTED,
    updateList
  );
  yield takeLatest(
    ADMINISTRATION_GET_SELECTED_ORGANIZATION_REQUESTED,
    watchGetSelectionOraganization
  );
  yield takeLatest(
    ADMINISTRATION_SET_SELECTED_ORGANIZATION_REQUESTED,
    watchSetSelectionOraganization
  );
  yield takeLatest(
    ADMINISTRATION_CLASSIFICATION_DELETE_REQUESTED,
    deleteClassification
  );
  yield takeLatest(
    ADMINISTRATION_ORAGANIZATION_DELETE_REQUESTED,
    watchDeleteOraganization
  );
  yield takeLatest(
    ADMINISTRATION_CLASSIFICATION_CREATE_LIST_REQUESTED,
    createClassification
  );
  yield takeLatest(ORAGANIZATION_REQUESTED, watchOraganizationsList);
  yield takeLatest(
    ADMINISTRATION_ORAGANIZATION_MEMBERS_REQUESTED,
    watchOraganizatioMemberList
  );
  yield takeLatest(
    ADMINISTRATION_ORAGANIZATION_CREATE_REQUESTED,
    watchCreateOraganization
  );
  yield takeLatest(
    ADMINISTRATION_ORGANIZATION_UPDATE_REQUESTED,
    watchUpdateOraganization
  );
  yield takeLatest(
    ADMINISTRATION_GET_INTIAL_DATA_ORAGANIZATION_INFOMATION_REQUESTED,
    watchGetIntialValueInfo
  );
  yield takeLatest(
    ADMINISTRATION_GET_INTIAL_EMAIL_CONFIGURATION_REQUESTED,
    watchGetIntialEmailConfig
  );
  yield takeLatest(
    ADMINISTRATION_GET_EMAIL_CONNECTIVITY_REQUESTED,
    watchGetIntEmailConnectivity
  );
  yield takeLatest(
    ORAGANIZATION_USER_CREATE_REQUESTED,
    watchCreateOraganizationUser
  );
  yield takeLatest(
    ORAGANIZATION_USER_UPDATE_REQUESTED,
    watchUpdateOraganizationUser
  );
  yield takeLatest(
    ORAGANIZATION_USER_DELETE_REQUESTED,
    watchDeleteOraganizationUser
  );
  yield takeLatest(ADMINISTRATION_OWNERS_LIST_REQUESTED, ownerList);
  yield takeLatest(ADMINISTRATION_OWNERS_DELETE_REQUESTED, deleteOwner);
  yield takeLatest(ADMINISTRATION_OWNERS_UPDATE_LIST_REQUESTED, updateOwner);
  yield takeLatest(ADMINISTRATION_OWNERS_CREATE_LIST_REQUESTED, createOwner);
  yield takeLatest(ADMINISTRATION_ASSETS_GROUP_LIST_REQUESTED, assetGroupList);
  yield takeLatest(
    ADMINISTRATION_ASSETS_GROUP_SOURCE_LIST_REQUESTED,
    assetSourceList
  );
  yield takeLatest(
    ADMINISTRATION_ASSETS_GROUP_SUB_GROUP_REQUESTED,
    assetSubGroupList
  );
  yield takeLatest(
    ADMINISTRATION_CREATE_ASSETS_GROUP_REQUESTED,
    createAssetGroup
  );
  yield takeLatest(
    ADMINISTRATION_DELETE_ASSETS_GROUPS_REQUESTED,
    deleteAssetGroup
  );
  yield takeLatest(ADMINISTRATION_EDIT_ASSETS_GROUPS_REQUESTED, editAssetGroup);
  yield takeLatest(ADMINISTRATION_WORKFLOW_LIST_REQUESTED, workflowList);
  yield takeLatest(ADMINISTRATION_CATEGORY_LIST_REQUESTED, categoryList);
  yield takeLatest(
    ADMINISTRATION_CATEGORY_CREATE_LIST_REQUESTED,
    createCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_CATEGORY_UPDATE_LIST_REQUESTED,
    updateCateogryList
  );
  yield takeLatest(
    ADMINISTRATION_CATEGORY_DELETE_LIST_REQUESTED,
    deleteCategory
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_CHANGE_STATUS_REQUESTED,
    changeWorkFlowStatus
  );
  yield takeLatest(
    ADMINISTRATION_ASSET_SUB_GROUP_LIST_REQUESTED,
    assetSubGroupTabList
  );
  yield takeLatest(
    ADMINISTRATION_ASSET_GROUP_DROP_DOWN_LIST_REQUESTED,
    assetSubGroupDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_CREATE_ASSET_SUB_GROUP_REQUESTED,
    createAssetSubGroup
  );
  yield takeLatest(
    ADMINISTRATION_DELETE_ASSET_SUB_GROUP_REQUESTED,
    deleteAssetSubGroup
  );
  yield takeLatest(
    ADMINISTRATION_EDIT_ASSET_SUB_GROUP_REQUESTED,
    editAssetSubGroup
  );
  yield takeLatest(
    ORGANIZATION_INFOMATION_POST_REQUESTED,
    watchOragaizationInfomationPost
  );
  yield takeLatest(
    EMAIL_CONFIGURATION_POST_REQUESTED,
    watchEmailConfigPostRequest
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_DUPLICATE_REQUESTED,
    duplicateWorkFlow
  );
  yield takeLatest(
    ADMINISTRATION_ORAGANIZATION_TIMEZONE_REQUESTED,
    watchOraganizationTimezon
  );
  yield takeLatest(ADMINISTRATION_WORKFLOW_DELETE_REQUESTED, deleteWorkFlow);
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_CATEGORY_REQUESTED,
    workFlowCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_CATEGORY_DISPOSITION_REQUESTED,
    watchCasesCategoryDisposition
  );
  yield takeLatest(
    ADMINISTRATION_CASES_CATEGORY_UPDATE_REQUESTED,
    watchCasesCategoryUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_CATEGORY_DELETE_REQUESTED,
    watchCasesCategoryDelete
  );
  yield takeLatest(
    ADMINISTRATION_CASES_CATEGORY_CREATE_REQUESTED,
    watchCasesCategoryCreate
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_SUB_CATEGORY_REQUESTED,
    workFlowSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_TASK_CATEGORY_REQUESTED,
    workFlowTaskCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_MITRE_TACTICS_REQUESTED,
    workFlowMitreTacticsList
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_MITRE_TECHNIQUES_REQUESTED,
    workFlowMitreTechniquesList
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_MITRE_SUBTECHNIQUES_REQUESTED,
    workFlowMitreSubTechniquesList
  );
  yield takeLatest(
    ADMINISTRATION_WORKFLOW_TAGS_REQUESTED,
    workFlowTagsList
  );
  yield takeLatest(ADMINISTRATION_WORKFLOW_CREATE_REQUESTED, createWorkFlow);
  yield takeLatest(ADMINISTRATION_WORKFLOW_UPDATE_REQUESTED, updateWorkFlow);
  yield takeLatest(ADMINISTRATION_ASSET_VALUE_LIST_REQUESTED, assetValueList);
  yield takeLatest(ADMINISTRATION_ASSET_CONFIDENTIALUITY_LIST_REQUESTED, assetConfidentiality);
  yield takeLatest(ASSET_CLASSIFICATION_CHANGE_TITLE_REQUESTED, assetClassificationTitleChange);
  yield takeLatest(ASSET_CLASSIFICATION_FORMULA_CHANGE_REQUESTED, assetClassificationFormulaChange);
  yield takeLatest(ASSET_CLASSIFICATION_GET_FORMULA_REQUESTED, getAssetClassificationgFormula);
  yield takeLatest(ASSET_CLASSIFICATION_INTEGIRTY_LIST_REQUESTED, assetIntegrity);
  yield takeLatest(ASSET_CLASSIFICATION_INTEGIRTY_UPDATE_REQUESTED, updateIntegrity);
  yield takeLatest(ASSET_CLASSIFICATION_INTEGIRTY_DELETE_REQUESTED, deleteIntegrity);
  yield takeLatest(ASSET_CLASSIFICATION_INTEGIRTY_CREATE_REQUESTED, createIntegrity);
  yield takeLatest(ASSET_CLASSIFICATION_AVAILABILITY_LIST_REQUESTED, assetAvailability);
  yield takeLatest(ASSET_CLASSIFICATION_AVAILABILITY_CREATE_REQUESTED, createAvailability);
  yield takeLatest(ASSET_CLASSIFICATION_AVAILABILITY_UPDATE_REQUESTED, updateAvailability);
  yield takeLatest(ASSET_CLASSIFICATION_AVAILABILITY_DELETE_REQUESTED, deleteAvailability);
  yield takeLatest(ADMINISTRATION_ASSET_CONFIDENTIALUITY_UPDATE_REQUESTED, updateConfidentiality);
  yield takeLatest(ADMINISTRATION_ASSET_CONFIDENTIALUITY_CREATE_REQUESTED, createConfidentiality);
  yield takeLatest(ADMINISTRATION_ASSET_CONFIDENTIALUITY_DELETE_REQUESTED, deleteConfidentiality);
  yield takeLatest(
    ADMINISTRATION_CREATE_ASSET_VALUE_LIST_REQUESTED,
    createAssetValue
  );
  yield takeLatest(ADMINISTRATION_EDIT_ASSET_VALUE_REQUESTED, editAssetsValue);
  yield takeLatest(
    ADMINISTRATION_DELETE_ASSET_VALUE_REQUESTED,
    deleteAssetsValue
  );
  yield takeLatest(
    ADMINISTRATION_CHANGE_TITLE_ASSET_VALUE_REQUESTED,
    changeAssetValueTitle
  );
  yield takeLatest(
    ADMINISTRATION_GET_OPERATING_SYSTEM_LIST_REQUESTED,
    assetOsList
  );
  yield takeLatest(
    ADMINISTRATION_CREATE_OPERATING_SYSTEM_REQUESTED,
    createOperatingSystem
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_REQUESTED,
    riskManagementThreatRegister
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VALUE_REQUESTED,
    riskManagementThreatResisterValueList
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_VULNERABILITY_REQUESTED,
    riskManagementThreatResisterVulnerabilityList
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_CREATE_REQUESTED,
    createRiskManagementThreatRegister
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_UPDATE_REQUESTED,
    updateRiskManagementThreatRegister
  );

  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_REGISTER_DELETE_REQUESTED,
    deleteRiskManagementThreatRegister
  );

  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_DELETE_REQUESTED,
    deleteRiskManagementControlRegister
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_REQUESTED,
    riskManagementThreatValue
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_CREATE_REQUESTED,
    createRiskManagementThreatValue
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_DELETE_REQUESTED,
    deleteRiskManagementThreatValue
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_THREAT_VALUE_UPDATE_REQUESTED,
    updateRiskManagementThreatValue
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_REQUESTED,
    riskManagementControlRegister
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_CREATE_REQUESTED,
    createRiskManagementControlRegister
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_CONTROL_REGISTER_UPDATE_REQUESTED,
    updateRiskManagementControlRegister
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_REQUESTED,
    riskManagementBusinessImpact
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_DELETE_REQUESTED,
    deleteRiskManagementBusinessImpact
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CREATE_REQUESTED,
    createRiskManagementBusinessImpact
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_UPDATE_REQUESTED,
    updateRiskManagementBusinessImpact
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_BUSINESS_IMPACT_CHANGE_TITLE_REQUESTED,
    RiskManagementBusinessImpactChangeTitle
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_REQUESTED,
    riskManagementCompliance
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_CREATE_REQUESTED,
    createRiskManagementCompliance
  );
  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_DELETE_REQUESTED,
    deleteRiskManagementCompliance
  );

  yield takeLatest(
    ADMINISTRATION_RISKMANAGEMENT_COMPLIANCE_UPDATE_REQUESTED,
    updateRiskManagementCompliance
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_VENDORS_LIST_REQUESTED,
    automationVendors
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_VENDORS_CREATE_LIST_REQUESTED,
    createAutomationVendors
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_VENDORS_UPDATE_LIST_REQUESTED,
    updateAutomationVendors
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_VENDORS_DELETE_LIST_REQUESTED,
    deleteAutomationVendors
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_PUBLISHERS_LIST_REQUESTED,
    automationPublishers
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_PUBLISHERS_CREATE_LIST_REQUESTED,
    createAutomationPublishers
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_PUBLISHERS_UPDATE_LIST_REQUESTED,
    updateAutomationPublishers
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_PUBLISHERS_DELETE_LIST_REQUESTED,
    deleteAutomationPublishers
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONIO_LIST_REQUESTED,
    automationActionIO
  );
  yield takeLatest(
    AUTOMATION_ACTION_DUPLICATE_REQUESTED,
    watchautomationActionDuplication
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONS_OUTPUT_REQUESTED,
    watchActionOutput
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_EXECUTE_SCRIPT_REQUESTED,
    watchExecuteScript
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_SAVE_INTEGRATION_REQUESTED,
    watchSaveIntegration
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONIO_CREATE_LIST_REQUESTED,
    createAutomationActionIO
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONIO_VALIDATION_LIST_REQUESTED,
    automationActionIOValidationList
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONIO_UPDATE_LIST_REQUESTED,
    updateAutomationActionsIO
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONIO_DELETE_LIST_REQUESTED,
    deleteAutomationActionsIO
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_THREAT_LIST_REQUESTED,
    automationThreat
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_THREAT_DELETE_LIST_REQUESTED,
    deleteAutomationThreat
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_THREAT_CREATE_LIST_REQUESTED,
    createAutomationThreat
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_THREAT_UPDATE_LIST_REQUESTED,
    updateAutomationThreat
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_CASE_FAMILY_LIST_REQUESTED,
    automationFamily
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_CASE_FAMILY_CREATE_LIST_REQUESTED,
    createAutomationFamily
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_CASE_FAMILY_DELETE_LIST_REQUESTED,
    deleteAutomationFamily
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_CASE_FAMILY_UPDATE_LIST_REQUESTED,
    updateAutomationFamily
  );
  yield takeLatest(
    ADMINISTRATION_EDIT_OPERATING_SYSTEM_REQUESTED,
    editOperatingSystem
  );
  yield takeLatest(
    ADMINISTRATION_DELETE_OPERATING_SYSTEM_REQUESTED,
    deleteOperatingSystem
  );
  yield takeLatest(
    ADMINISTRATION_GET_ASSET_DEPARTMENT_LIST_REQUESTED,
    assetDepartmentList
  );
  yield takeLatest(
    ADMINISTRATION_CREATE_ASSET_DEPARTMENT_LIST_REQUESTED,
    createAssetDepartment
  );
  yield takeLatest(
    ADMINISTRATION_EDIT_DEPARTMENT_REQUESTED,
    editAssetDepartment
  );
  yield takeLatest(
    ADMINISTRATION_DELETE_DEPARTMENT_REQUESTED,
    deleteAssetDepartment
  );

  yield takeLatest(ADMINISTRATION_CASES_CONTAINER_LIST_REQUESTED, watchContainerList);
  yield takeLatest(ADMINISTRATION_CASES_DESPOSITION_REQUESTED, watchCasesDisposition);
  yield takeLatest(ADMINISTRATION_CASES_CATEGORY_REQUESTED, watchCasesCategoriesList);

  yield takeLatest(
    ADMINISTRATION_ASSET_GET_BUSINESS_GROUP_LIST_REQUESTED,
    getAssetBusinessGroupList
  );
  yield takeLatest(
    ADMINISTRATION_ASSET_CREATE_BUSINESS_GROUP_LIST_REQUESTED,
    createAssetBusinessGroup
  );
  yield takeLatest(
    ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_LIST_REQUESTED,
    editAssetBusinessGroup
  );
  yield takeLatest(
    ADMINISTRATION_ASSET_DELETE_BUSINESS_GROUP_LIST_REQUESTED,
    deleteAssetBusinessGroup
  );
  yield takeLatest(
    ADMINISTRATION_ASSET_EDIT_BUSINESS_GROUP_TITLE_REQUESTED,
    editAssetBusinessGroupTitle
  );
  yield takeLatest(
    ADMINISTRATION_CASES_CATEGORY_LIST_REQUESTED,
    watchCasesCategory
  );
  yield takeLatest(
    ADMINISTRATION_GET_ASSET_TYPE_ASSET_DROP_DOWN_LIST_REQUESTED,
    getAssetDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_GET_ASSET_TYPE_SUBGROUP_DROP_DOWN_LIST_REQUESTED,
    getSubGroupDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_GET_ASSET_TYPE_OWNER_DROP_DOWN_LIST_REQUESTED,
    getOwnerDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_GET_ASSET_TYPE_DEPARTMENT_DROP_DOWN_LIST_REQUESTED,
    getDepartmentDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_GET_ASSET_TYPE_ASSET_VALUE_DROP_DOWN_LIST_REQUESTED,
    getAssetValueDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_CREATE_ASSET_TYPE_REQUESTED,
    createAssetTypeList
  );
  yield takeLatest(ADMINISTRATION_EDIT_ASSET_TYPE_REQUESTED, editAssetTypeList);
  yield takeLatest(
    ADMINISTRATION_CASES_SUBCATEGORY_LIST_REQUESTED,
    watchCasesSubCategory
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_CATEGORY_CREATE_REQUESTED,
    watchCasesSubCategoryCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_CATEGORY_UPDATE_REQUESTED,
    watchCasesSubCategoryUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_CATEGORY_DELETE_REQUESTED,
    watchCasesSubCategoryDelete
  );
  yield takeLatest(
    ADMINISTRATION_SUB_CATEGORY_LIST_REQUESTED,
    watchCasesSubCategoryList
  );
  yield takeLatest(ADMINISTRATION_ASSET_TYPE_LIST_REQUESTED, getAssetTypeList);
  yield takeLatest(
    ADMINISTRATION_EDIT_ASSET_TYPE_TITLE_REQUESTED,
    editAssetTypeTitle
  );
  yield takeLatest(ADMINISTRATION_DELETE_ASSET_TYPE_REQUESTED, deleteAssetType);
  yield takeLatest(
    ADMINISTRATION_PRIMARY_CATEGORY_LIST_REQUESTED,
    watchCasesSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DISPOSITIONS_LIST_REQUESTED,
    watchCasesDispositionsList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DISPOSITION_UPDATE_REQUESTED,
    watchCasesDispositionUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DISPOSITION_DELETE_REQUESTED,
    watchCasesDispositionDelete
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DISPOSITION_CREATE_REQUESTED,
    watchCasesDispositionCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_DISPOSITION_LIST_REQUESTED,
    watchSubDispositions
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_DISPOSITION_CREATE_REQUESTED,
    watchCasesSubDispositionCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_DISPOSITION_UPDATE_REQUESTED,
    watchCasesSubDispositionUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_DISPOSITION_DELETE_REQUESTED,
    watchCasesSubDispositionDelete
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_TECHNICAL_REQUESTED,
    watchvulnerabilityTechnical
  );
  yield takeLatest(
    ADMINISTRATION_SUB_CATEGORY_LIST_REQUESTED,
    watchCasesSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SUB_DISPOSITION_DROPDOWN_LIST_REQUESTED,
    watchSubDispositionsDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DITECTION_METHOD_LIST_REQUESTED,
    watchDitectionMethod
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DITECTION_METHOD_DELETE_REQUESTED,
    watchCasesDetectionMethodDelete
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DITECTION_METHOD_UPDATE_REQUESTED,
    watchCasesDitectionMethodUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_DITECTION_METHOD_CREATE_REQUESTED,
    watchCasesDitectionMethodCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SLA_LIST_REQUESTED,
    watchDitectionSlas
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SLA_DELETE_REQUESTED,
    watchDeleteDitectionSlas
  );

  yield takeLatest(
    ADMINISTRATION_CASES_SLA_CREATE_REQUESTED,
    watchCreateDitectionSlas
  );

  yield takeLatest(
    ADMINISTRATION_CASES_SLA_UPDATE_REQUESTED,
    watchUpdateDitectionSlas
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SLA_VIEW_LIST_REQUESTED,
    watchLogDitectionSlas
  );
  yield takeLatest(
    ADMINISTRATION_CASES_SLA_RULE_LIST_REQUESTED,
    watchSlasRulesView
  );
  yield takeLatest(
    ADMINISTRATION_CASES_LOCATIONS_LIST_REQUESTED,
    watchCasesLocationsList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_LOCATION_DELETE_REQUESTED,
    watchCasesLocationDelete
  );
  yield takeLatest(
    ADMINISTRATION_CASES_LOCATION_CREATE_REQUESTED,
    watchCasesLocationCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_LOCATION_UPDATE_REQUESTED,
    watchCasesLocationUpdate
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_TECHNICAL_DELETE_REQUESTED,
    deleteTechnicalVulnerability
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_SEVERITY_REQUESTED,
    vulnerabilityseverityList
  );
  yield takeLatest(
    ADMINISTRATION_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
    vulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
    createVulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
    updateVulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
    deleteVulnerabilityseverityLevelList
  );

  yield takeLatest(
    ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_LIST_REQUESTED,
    nontechvulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_DELETE_LIST_REQUESTED,
    deleteNonVulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_UPDATE_LIST_REQUESTED,
    updateNonVulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_NON_VAL_SEVERTIY_LEVEL_CREATE_LIST_REQUESTED,
    createNonVulnerabilityseverityLevelList
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_TECHNICAL_CREATE_REQUESTED,
    createTechnicalVulnerability
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_TECHNICAL_UPDATE_REQUESTED,
    updateTechnicalVulnerability
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_REQUESTED,
    watchvulnerabilityNonTechnical
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_SEVERITY_REQUESTED,
    vulnerabilityNonTechnicalseverityList
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_THREAT_REQUESTED,
    vulnerabilitythreatList
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_DELETE_REQUESTED,
    deleteNonTechnicalVulnerability
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_TECHNICAL_NON_UPDATE_REQUESTED,
    updateNonTechnicalVulnerability
  );
  yield takeLatest(
    ADMINISTRATION_VULNERABILITY_NON_TECHNICAL_CREATE_REQUESTED,
    createNonTechnicalVulnerability
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_CATEGORY_LIST_REQUESTED,
    threatIntelCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_CATEGORY_CREATE_REQUESTED,
    createthreatIntelCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_CATEGORY_DELETE_REQUESTED,
    deletethreatIntelCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_CATEGORY_UPDATE_REQUESTED,
    updatethreatIntelCategoryList
  );


  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_LIST_REQUESTED,
    threatIntelSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_CREATE_REQUESTED,
    createthreatIntelSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_DELETE_REQUESTED,
    deletethreatIntelSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_SUB_CATEGORY_UPDATE_REQUESTED,
    updatethreatIntelSubCategoryList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_SEVERITY_REQUESTED,
    threatIntelSeverityList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_PRIMARY_CATEGORY_REQUESTED,
    threatIntelPrimaryCateogryList
  );

  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_PERIORITIE_DELETE_REQUESTED,
    watchadministratorCasesNcissPerioritieDelete
  );

  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_DELETE_LIST_REQUESTED,
    deleteCasesNciss
  );

  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_PERIORITIE_UPDATE_REQUESTED,
    watchadministratorCasesNcissPerioritieUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_PERIORITIE_CREATE_REQUESTED,
    watchadministratorCasesNcissPerioritieCreate
  );

  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_LIST_REQUESTED,
    watchCasesNcissList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_CATEGORY_LIST_REQUESTED,
    watchCasesNcissDropDownList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_CREATE_REQUESTED,
    watchCasesNcissCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_UPDATE_REQUESTED,
    watchCasesNcissUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_CATEGORIES_LIST_REQUESTED,
    watchCasesncissCategoriesList
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_CATEGORIES_UPDATE_REQUESTED,
    watchCasesncissCategoriesUpdate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_CATEGORIES_CREATE_REQUESTED,
    watchCasesncissCategoriesCreate
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_CATEGORIES_DELETE_REQUESTED,
    watchCasesncissCategoriesDelete
  );
  yield takeLatest(
    ADMINISTRATION_CASES_NCISS_PERIORITIES_LIST_REQUESTED,
    watchadministratorCasesNcissPeriorities
  );
  yield takeLatest(
    ADMINISTRATION_GET_DISPOSITION_LIST_REQUESTED,
    getThreatIntelDispositionList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_DISPOSITION_UPDATE_REQUESTED,
    updateThreatIntelDispositionList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_DISPOSITION_CREATE_REQUESTED,
    createThreatIntelDispositionList
  );
  yield takeLatest(
    ADMINISTRATION_THREAT_INTEL_DISPOSITION_DELETE_REQUESTED,
    deleteThreatIntelDispositionList
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_INGESTION_LIST_REQUESTED,
    automationIngestionList
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_INGESTION_CREATE_LIST_REQUESTED,
    createAutomationIngestion
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_INGESTION_UPDATE_LIST_REQUESTED,
    updateAutomationIngestion
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_INGESTION_DELETE_LIST_REQUESTED,
    deleteAutomationIngestionIO
  );
  yield takeLatest(INGESTION_TYPE_REQUESTED, watchIngestionType);
  yield takeLatest(INGESTION_WIDGET_REQUESTED, watchIngestionWidget);
  yield takeLatest(INGESTION_FREQUENCY_REQUESTED, watchInjestionFrequency);
  yield takeLatest(INGESTION_APPLICATION_REQUESTED, watchIngestionApplication);
  yield takeLatest(INGESTION_USERS_REQUESTED, watchInjestionUsers);
  yield takeLatest(INGESTION_ACTION_REQUESTED, watchIngestionAction);
  yield takeLatest(ENABLE_ADVISORY_REQUESTED, watchAdvisory);
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONS_LIST_REQUESTED,
    automationActionsList
  );
  yield takeLatest(
    AUTOMATION_ACTION_CONFIGURATION_REQUESTED,
    automationConfigurationList
  );
  yield takeLatest(
    AUTOMATION_ACTION_CONFIGURATION_POST_REQUESTED,
    automationConfigurationIngestionPost
  );
  yield takeLatest(
    AUTOMATION_ACTION_VIEW_SCRIPT_REQUESTED,
    automationViewScript
  );
  yield takeLatest(
    AUTOMATION_ACTION_MULTIDATA_POST_REQUESTED,
    automationactionUpdateList
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONS_CREATE_LIST_REQUESTED,
    createAutomationList
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONS_UPDATE_LIST_REQUESTED,
    updatAutomationActionList
  );
  yield takeLatest(
    ADMINISTRATION_AUTOMATION_ACTIONS_DELETE_LIST_REQUESTED,
    deleteAutomationActionList
  );
  yield takeLatest(ACTIONS_TYPE_REQUESTED, actionsType);
  yield takeLatest(ACTIONS_SCRIPT_TYPE_REQUESTED, actionsScript);
  yield takeLatest(ACTIONS_IO_TYPE_REQUESTED, actionsIoType);
  yield takeLatest(ACTIONS_APPLICATION_REQUESTED, actionsApplication);
  yield takeLatest(GET_INGESTION_MULTI_DATA_REQUESTED, ingestionGetMultiData);
  yield takeLatest(GET_INGESTION_MAPPING_REQUESTED, ingestionGetMapping);
  yield takeLatest(ADD_INGESTION_MULTI_DATA_REQUESTED, ingestionAddMultiData);
  yield takeLatest(ADMINISTRATION_LOGS_APPS_LIST_REQUESTED, logsAppList);
  yield takeLatest(APP_LOGS_NOTIFICATION_LIST_REQUESTED, appLogsNotificationList);
  yield takeLatest(
    ADMINISTRATION_LOGS_AUTHENTICATION_LIST_REQUESTED,
    logsAuthenticationList
  );
  yield takeLatest(
    ADMINISTRATION_LOGS_ACTIVITY_LIST_REQUESTED,
    logsActivityList
  );
  yield takeLatest(
    ADMINISTRATION_LOGS_INGESTION_LIST_REQUESTED,
    logsIngestionList
  );
  yield takeLatest(
    ADMINISTRATION_LOGS_PRE_INGESTION_LIST_REQUESTED,
    logsPreIngestionList
  );
  yield takeLatest(
    ADMINISTRATION_PRODUCT_FAILED_REGISTER_LIST_REQUESTED,
    productsRegisterList
  );
  yield takeLatest(
    ADMINISTRATION_PRODUCT_FAILED_REGISTER_DELETE_REQUESTED,
    productsRegisterDeleteList
  );
  yield takeLatest(ADMINISTRATION_ACCESS_USERS_LIST_REQUESTED, accessUsersList);
  yield takeLatest(
    ADMINISTRATION_ACCESS_USERS_DELETE_REQUESTED,
    accessUserDeleteList
  );
  yield takeLatest(
    ADMINISTRATION_ACCESS_AUTHENTICATION_TYPE_REQUESTED,
    authenticateType
  );
  yield takeLatest(
    ADMINISTRATION_ACCESS_PERMISSION_REQUESTED,
    accessPermission
  );
  yield takeLatest(
    ADMINISTRATION_ACCESS_DASHBOARDS_REQUESTED,
    accessDashboards
  );
  yield takeLatest(
    ADMINISTRATION_ACCESS_LANDING_PAGE_REQUESTED,
    accessLandingPage
  );
  yield takeLatest(ADMINISTRATION_ACCESS_GROUP_REQUESTED, accessGroup);
  yield takeLatest(ADMINISTRATION_ACCESS_SESSION__PASSWORD_REQUESTED, WatchSessionPassword);
  yield takeLatest(ADMINISTRATION_ACCESS_SESSION__PASSWORD_UPDATE_REQUESTED, WatchuUpdateSessionPassword);
  yield takeLatest(ADMINISTRATION_ACCESS_USERS_UPDATE_REQUESTED, watchAccessUserUpdate);
  yield takeLatest(ADMINISTRATION_ACCESS_USERS_PASSWORD_RESET_REQUESTED, watchAccessUserPasswordReset);
  yield takeLatest(ADMINISTRATION_ACCESS_USER_INVITE_REQUESTED, watchAccessInviteUser);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_GROUPS_REQUESTED, watchAccessControlGroups);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_GROUP_UPDATE_REQUESTED, watchAccessGroupsUpdate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_GROUP_CREATE_REQUESTED, watchAccessGroupsCreate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_GROUP_DELETE_REQUESTED, watchAccessGroupsDelete);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_ROLES_REQUESTED, watchAccessControlRoles);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_ROLE_UPDATE_REQUESTED, watchAccessRoleUpdate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_ROLE_DELETE_REQUESTED, watchAccessRoleDelete);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTORL_ROLE_CREATE_REQUESTED, watchAccessRoleCreate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTIONS_REQUESTED, watchAccessControlActions);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTION_UPDATE_REQUESTED, watchAccessActionsUpdate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTION_CREATE_REQUESTED, watchAccessActionsCreate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTION_DELETE_REQUESTED, watchAccessActionsDelete);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_REQUESTED, watchAccessControlActionGroup);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTIONS_GROUP_TAB_REQUESTED, watchAccessControlActionsGroupTab);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_UPDATE_REQUESTED, watchAccessActionsGroupUpdate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_CREATE_REQUESTED, watchAccessActionsGroupCreate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ACTION_GROUP_DELETE_REQUESTED, watchAccessActionsGroupDelete);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_LIST_REQUESTED, watchAccessControlAuthSetting);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_TYPE_LIST_REQUESTED, watchAccessControlAuthenticationType);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_AUTH_SETTING_AUTHENTICATION_VENDOR_REQUESTED, watchAccessControlAuthenticationVendors);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_DELETE_AUTH_SETTING_REQUESTED, watchDeleteThirdPartyAuth);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_CREATE_AUTH_SETTING_REQUESTED, watchCreateThirdPartyAuth);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_UPDATE_AUTH_SETTING_REQUESTED, watchUpdateThirdPartyAuth);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_LIST_REQUESTED, watchAccessControlPrevileges);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_ALL_PREVILEGES_REQUESTED, watchAccessControlAllPrevileges);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_CREATE_REQUESTED, watchAccessControlPrevilegesCreate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_UPDATE_REQUESTED, watchAccessControlPrevilegesUpdate);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_DELETE_REQUESTED, watchAccessControlPrevilegesDelete);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PREVILEGES_GROUP_REQUESTED, watchAccessControlPrevilegesGroup);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_LIST_REQUESTED, watchProductSettingFeedbackList);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_FEEDBACK_DELETE_REQUESTED, watchDeleteProductSettingFeedback);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGETS_LIST_REQUESTED, watchProductSettingWidget);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_UPDATE_REQUESTED, watchUpdateWidgets);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_CREATE_REQUESTED, watchCreateWidgets);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_DELETE_REQUESTED, watchDeleteWidget);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_GROUP_LIST_REQUESTED, watchProductSettingWidgetGroup);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_WIDGET_SOURCE_LIST_REQUESTED, watchProductSettingWidgetSources);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_INFO_LIST_REQUESTED, watchProductLicense);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_UPLOAD_REQUESTED, uploadLicense);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_CREATE_REQUESTED, createLicense);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_UPDATE_REQUESTED, updateLicense);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_DELETE_REQUESTED, deleteLicense);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_DOWNLOAD_REQUESTED, downloadLicense);
  yield takeLatest(ADMINISTRATION_PRODUCT_SETTINGS_PATCH_REQUESTED, patchUpdate);
  yield takeLatest(ADMINISTRATION_PRODUCT_LICENSE_SOURCES_REQUESTED, licenceModulesSources);
  yield takeLatest(ADMINISTRATION_NOTIFICATIONS_LIST_REQUESTED, watchNotificationList);
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_HEALTH_REQUESTED, watchProductSettingHealth)
  yield takeLatest(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_CONNECTIVITY_REQUESTED, watchProductSettingConnectivity)
  yield takeEvery(ADMINISTRATION_NOTIFICATIONS_UPDATE_REQUESTED, watchNotificationUpdate);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_LIST_REQUESTED, watchProductSettingBackupList);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_IMPORT_BACKUP_REQUESTED, watchImportBackup);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_BACKUP_REQUESTED, watchRestoreBackup);
  yield takeEvery(ADMINISTRATION_PRODUCT_SETTTING_BACKUP_SETTING_RESTORE_CONFIGURE_AUTO_BACKUP_REQUESTED, watchConfigureRestoreBackup);
  yield takeEvery(ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED, watchSFTPConfigure);
  yield takeEvery(ADMINISTRATION_PRODUCT_SETTTING_SFTP_CONFIGURE_REQUESTED_Old, watchSFTPConfigureOld);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_DELETE_REQUESTED, watchProductSettingBackupDelete);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_BACKUP_CREATE_REQUESTED, watchCreateBackup);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_NOTIFY_USERS_LIST_REQUESTED, watchNotifyWatchList);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_DOWNLOAD_REQUESTED, watchDowloadBackup);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SETTING_REQUESTED, watchGetBackupSettingAPI);
  yield takeEvery(ADMINISTRATION_ACCESS_CONTROL_PRODUCT_SETTTING_GET_BACKUP_SFTP_REQUESTED, watchGetSftpConfigureAPI);
  yield takeLatest(ADMINISTRATION_FIELD_SETTING_LIST_REQUESTED, watchFieldSettingList);
  yield takeLatest(ADMINISTRATION_FIELD_SETTING_UPDATE_REQUESTED, watchFieldSettingUpdate);
  yield takeLatest(ADMINISTRATION_CASE_FIELD_SETTING_LIST_REQUESTED, watchCaseFieldSettingList);
  yield takeLatest(ADMINISTRATION_CASE_FIELD_SETTING_UPDATE_REQUESTED, watchCaseFieldSettingUpdate);
  yield takeLatest(ADMINISTRATION_CASE_FIELD_SETTING_DUPLICATE_REQUESTED, watchCaseFieldSettingDuplicate);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_GET_DEFAULT_REQUESTED, watchProbabilityGetDefault);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_LIST_REQUESTED, watchProbabilityList);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_DELETE_LIST_REQUESTED, watchDeleteProbabilityList);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_UPDATE_LIST_REQUESTED, watchUpdateProbabilityList);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_PROBABLITY_CREATE_LIST_REQUESTED, watchCreateProbabilityList);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_CHANGE_TITLE_REQUESTED, watchChangeTitle);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_UPDATE_FORMULA_REQUESTED, watchUpdateFormula);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_GET_FORMULA_REQUESTED, watchGetFormula);
  yield takeLatest(ADMINISTRATION_GET_TABS_HEADING_REQUESTED, watchCaseGetTabsHeadings);
  yield takeLatest(ADMINISTRATION_RISK_MATRIX_LIST_REQUESTED, watchGetRiskMatrix);
  yield takeLatest(ADMINISTRATION_CREATE_RISK_MATRIX_REQUESTED, watchCreateRiskMatrix)
  yield takeEvery(ADMINISTRATION_RESET_RISK_MATRIX_REQUESTED, watchResetRiskMatrix);
  yield takeEvery(ADMINISTRATION_RISKMANAGEMENT_GET_RISK_META_REQUESTED, watchRiskMetaList);
  yield takeLatest(ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_LABEL_REQUESTED, watchRiskMetaLabelUpdate);
  yield takeLatest(ADMINISTRATION_RISKMANAGEMENT_RISK_META_UPDATE_REQUESTED, watchRiskMetaUpdate);
  yield takeEvery(ADMINISTRATION_GET_RISK_MATRIX_DROPDOWN_DATA_REQUESTED, watchGetRiskMatrixDropdownData);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_LIST_REQUESTED, watchGetThreatActorList);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_ASSOCIATE_ACTOR_THREAT_ACTOR_REQUESTED, watchAssociateGroupThreatActor);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_ASSOCIATE_THREAT_ACTOR_LIST_REQUESTED, associateThreatActorList);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_CREATE_REQUESTED, watchThreatActorCreate);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_UPDATE_REQUESTED, watchThreatActorUpdate);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_THREAT_ACTOR_DELETE_REQUESTED, watchThreatActorDelete);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_LIST_REQUESTED, watchGetAssociatedThreatActorList);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_CREATE_REQUESTED, watchAssociatedThreatActorCreate);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_UPDATE_REQUESTED, watchAssociatedThreatActorUpdate);
  yield takeLatest(ADMINISTRATION_THREAT_INTEL_ASSOCIATED_THREAT_ACTOR_DELETE_REQUESTED, watchAssociatedThreatActorDelete);
  yield takeEvery(ADMINISTRATION_NOTIFICATIONS_LOG_UPDATE_REQUESTED, watchLogNotificationUpdate);
  yield takeLatest(ADMINISTRATION_NOTIFICATIONS_LOG_LIST_REQUESTED, watchLognotificationList);
  yield takeLatest(ADMINISTRATION_CASES_LESSON_LIST_REQUESTED,watchCasesLessonsList);
  yield takeLatest(ADMINISTRATION_CASES_LESSON_DELETE_REQUESTED,watchCasesLessonDelete);
  yield takeLatest(ADMINISTRATION_CASES_LESSON_CREATE_REQUESTED,watchCasesLessonCreate);
  yield takeLatest(ADMINISTRATION_CASES_LESSON_UPDATE_REQUESTED,watchCasesLessonUpdate);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINMENT_STATUS_LIST_REQUESTED,watchCasesContainmentStatusList);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINMENT_STATUS_DELETE_REQUESTED,watchCasesContainmentStatusDelete);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINMENT_STATUS_CREATE_REQUESTED,watchCasesContainmentStatusCreate);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINMENT_STATUS_UPDATE_REQUESTED,watchCasesContainmentStatusUpdate);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINED_BY_LIST_REQUESTED,watchCasesContainedByList);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINED_BY_DELETE_REQUESTED,watchCasesContainedByDelete);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINED_BY_CREATE_REQUESTED,watchCasesContainedByCreate);
  yield takeLatest(ADMINISTRATION_CASES_CONTAINED_BY_UPDATE_REQUESTED,watchCasesContainedByUpdate);
  yield takeLatest(INGESTION_OUTPUT_REQUESTED,watchIngestionOutput);
  yield takeLatest(UPDATE_INGESTION_OUTPUT_REQUESTED,watchUpdateIngestionOutput);
  yield takeLatest(ADMINISTRATION_AFFECTED_VENDOR_LIST_REQUESTED,affectedVendorList);
  yield takeLatest(ADMINISTRATION_AFFECTED_VENDOR_CREATE_REQUESTED,createAffectedVendor);
  yield takeLatest(ADMINISTRATION_AFFECTED_VENDOR_DELETE_REQUESTED,deleteAffectedVendor);
  yield takeLatest(ADMINISTRATION_AFFECTED_VENDOR_UPDATE_REQUESTED,updateAffectedVendor);

  yield takeLatest(ADMINISTRATION_AFFECTED_PRODUCT_LIST_REQUESTED,affectedProductList);
  yield takeLatest(ADMINISTRATION_AFFECTED_PRODUCT_CREATE_REQUESTED,createAffectedProduct);
  yield takeLatest(ADMINISTRATION_AFFECTED_PRODUCT_DELETE_REQUESTED,deleteAffectedProduct);
  yield takeLatest(ADMINISTRATION_AFFECTED_PRODUCT_UPDATE_REQUESTED,updateAffectedProduct);
  yield takeLatest(UPDATE_SLA_STATUS_REQUESTED,updateSlaStatus);
  
  yield takeLatest(PRE_INGESTION_RULE_REQUESTED,preIngestionRules);
  yield takeLatest(PRE_INGESTION_RULE_CREATE_REQUESTED,createPreIngestionRule);
  yield takeLatest(PRE_INGESTION_RULE_UPDATE_REQUESTED,updatePreIngestionRule);
  yield takeLatest(PRE_INGESTION_RULE_DELETE_REQUESTED,deletePreIngestionRule);
  
}
