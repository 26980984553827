import axios from 'axios';
import API from '../config/endpoints.config';
import { downloadBase64File } from '../utils/helper.utils';
import { showToastError } from '../utils/toasts';
const FileDownload = require('js-file-download');

export async function listMembersUser(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.baseUrl + '/custom-functions/users?type=incidentTicket', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function addEvidenceSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const payload = {
    heading: data.data.values.heading,
    ina_output_html: data.data.ina_output_html,
  };
  const response = await axios.post(
    `${API.incidentManagement}/add-evidence?id=${data.id}`,
    payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}


export async function listMemberUserGroups(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.baseUrl + '/custom-functions/user-group', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getIncidentManagementSeverity() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.severity,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentcategory() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.category,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentsubCategory(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    `${API.incidentManagementModule.master.subCategory}?cat_id=${payload?.cat_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentdisposition() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.disposition,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentsubDisposition(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    `${API.incidentManagementModule.master.subDisposition}?disp_id=${payload?.disp_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentlocation() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.location,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}
export async function getIncidentContainmentStatus() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.containmentStatus,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}
export async function getIncidentContainedBy() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.containedBy,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}
export async function getIncidentLessonLearned() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.lessonLearned,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentdetectionMethods() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.caseManagementModule.master.detectionMethods,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentartifacts() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.artifacts,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getIncidentartifactList() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.master.artifactList,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getActionApps() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagementModule.actionApp.list,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function runActions(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let refinedPayload = {
    ...payload,
    input: payload?.input?.toString() || '',
  }
  if(refinedPayload.hasOwnProperty('multi_step')){
    refinedPayload.multi_step = (payload?.multi_step && payload?.multi_step!== 'false' && payload?.multi_step!== 'False')? 'true' : "false"
  }
  if(refinedPayload.hasOwnProperty('act_is_multiinput')){
    refinedPayload.act_is_multiinput = (payload?.act_is_multiinput && payload?.act_is_multiinput!== 'false' && payload?.act_is_multiinput!== 'False')? 'true' : "false"
  }
  if(refinedPayload.hasOwnProperty('app_multi_config_allowed')){
    refinedPayload.app_multi_config_allowed = (payload?.app_multi_config_allowed && payload?.app_multi_config_allowed!== 'false' && payload?.act_is_multiinput!== 'False')? 'true' : "false"
  }

  const url = payload?.url ?? API.incidentManagementModule.runActions;
  const response = await axios.post(url, refinedPayload, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function addArtifact(payload) {
  const { id, url, index, art_id, module } = payload;
  delete payload?.id;
  delete payload?.url;
  const tempPayload = { ...payload, action: payload?.type };
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    `${url ?? API.incidentManagementModule.artifact.update}?id=${id}&index=${index}&art_id=${art_id}${module? `&type=${module}`: ''}`,
    tempPayload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getRowOutput(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    `${API.incidentManagementModule.rowOutput}?id=${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function reportTypes() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.incidentManagementModule.reportType, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function generateReport(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagementModule.generateReport,
    payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
      responseType: 'blob',
    }
  );

  let ext = 'xlsx';
  if (
    response?.headers?.['content-type'] &&
    response?.headers?.['content-type'].includes('pdf')
  ) {
    ext = 'pdf';
  }

  FileDownload(response.data, `report.${ext}`);
  return response.data;
}

// export async function downloadAsset(payload) {
//   try {
//     const userToken = localStorage.getItem('AccessToken');
//     const response = await axios.get(payload?.url, {
//       headers: {
//         Authorization: 'Bearer ' + userToken,
//       },
//       // responseType: 'blob',
//     });

//     let fileName = response?.data?.artifactName ? response?.data?.artifactName : payload?.name === 'evidence' ? payload?.name + `.${payload?.ext?.split('_')[0]}` : payload?.name?.replace(/,(\s+)?$/, '') + `.${payload?.ext}`
//     let fileExt = response?.data?.artifactName ? response?.data?.artifactName?.split('.')?.pop() : payload?.ext

//     downloadBase64File(
//       response?.data?.image,
//       fileName,
//       fileExt
//     );
//     return response?.data?.image;
//   } catch (error) {
//     showToastError('Download Failed')
//   }
// }


export async function downloadAsset(payload) {

  try {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(payload?.url, {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
      responseType: 'blob',
    });
  
    FileDownload(
      response.data,
      payload?.name == 'evidence' ? payload?.name + `.${payload?.ext?.split('_')[0]}` : payload?.name?.replace(/,(\s+)?$/, '') + `.${payload?.ext}`
      // payload?.name == 'evidence' ? payload?.name+'.zip' :  payload?.name?.replace(/,(\s+)?$/, '')+`.${payload?.ext}`
      //`${payload?.name ?? 'evidence'}.${payload?.ext.split('__')?.[0] ?? 'zip'}`
    );
    return response.data;
  } catch (error) {
    showToastError('Download Failed')
  }
}
