import {
  ALERTS_LIST_REQUESTED,
  ALERTS_LIST_SUCCESSED,
  ALERTS_LIST_FAILED,
  RESET_ALERTS_LIST_REQUESTED
} from '../../constants/actionTypes';

const initialState = {
  listData: {},
  isProcessing: false,
  isSuccess: null,
  hasErrors: null,
};

export const alertListStore = (state = initialState, action) => {
  switch (action.type) {
    case ALERTS_LIST_REQUESTED: {
      return {
        ...state,
        listData: null,
        hasErrors: null,
        isProcessing: true,
        isSuccess: null,
      };
    }
    case ALERTS_LIST_SUCCESSED: {
      return {
        listData: action.data,
        hasErrors: null,
        isProcessing: false,
        isSuccess: true,
      };
    }
    case ALERTS_LIST_FAILED: {
      return {
        listData: action.data,
        hasErrors: true,
        isProcessing: false,
        isSuccess: null,
      };
    }
    case RESET_ALERTS_LIST_REQUESTED: {
      return {
        listData: {},
        hasErrors: null,
        isProcessing: false,
        isSuccess: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default alertListStore;
