import axios from 'axios';
import API from '../config/endpoints.config';

const FileDownload = require('js-file-download');

export async function listSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api;
  const expand = '?expand=itiSource,incidentAssets,itiLocation,itiCategory,incidentComments,itiOpenedByy,itiClosedByy,itiAssignToo,itiDisposition,itiSubcategory,executedPlayBooksCount,members,ncisPriority,alertsStatuses,host,incidentArtifact,trends&';

  if (payload.disposition && payload.disposition !== 'all') {
    api = API.incidentManagement + '/' + payload.disposition + expand;
  } else {
    api = API.incidentManagement + expand;
  }

  if (payload.queryItem) {
    api += payload.queryItem;
  }
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function incidentFilterList(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api = API.incidentManagement;
  if (payload.queryItem) {
    api += `?${payload.queryItem}`;
  }
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listIncidentSourcesSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.baseUrl + '/custom-functions/sources?type=Incident', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listIncidentReportsSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.report + '/reports', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}


export async function listIncidentCustomersSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.baseUrl + '/custom-functions/customer', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listIncidentSeveritySaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.baseUrl + '/custom-functions/severity', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listIncidentDispositionSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.baseUrl + '/custom-functions/disposition?isIncident=true', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function singleExportPdf(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.incidentManagement + '/export?id=' + id,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function incidentDetailsSaga(id, isOnlyTask) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'itiSource',
    'host',
    'itiCategory',
    'itiDisposition',
    'itiAwareIncident',
    'itiDispositionSubCategory',
    'itiLocation',
    'itiOpenedByy',
    'itiClosedByy',
    'itiAssignToo',
    'members',
    'incidentAssets',
    'evidenceAttachment',
    'itiSubcategory',
    'slaInfo',
    'timeTracking',
    'mitreSubTechniques',
    'mitreTactics',
    'mitreTechniques',
    'ncisPriority',
    'ncisChart',
    'itiLessonLearned',
    'itiContainedBy',
    'itiContainmentStatus',
    // 'makeAlertRead',
    // 'itiSource.disApp',
    'childAlerts'
  ];
  if (isOnlyTask === true) {
    fields = [
      'incidentTasks',
      'incidentTasks.invAssignTo',
      'incidentTasks.invLaunchedBy',
      'incidentTasks.invTaskCategory',
      'incidentTasks.invDepartment',
      'incidentTasks.invAttachment',
    ];
  }

  if (isOnlyTask === 'isEdit') {
    fields = [
      'incidentArtifact',
      'evidenceAttachment',
      'ncisPriority',
      'isEdit',
      'childAlerts',
      'advVendors',
      'advProducts'
    ];
  }
  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}


export async function artifactsDataSaga(id, evidenceKey, page, searchValue) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'incidentArtifact',
    'incidentApps',
    'incidentActionsMapped'
  ];

  const expandedString = fields.toString();
  let apiUrl = `${API.incidentManagement}/${id}?art_id=${evidenceKey || null}&index=${page == null ? null : 0}&expand=${expandedString}`;

  if (searchValue) {
    apiUrl = apiUrl + `&search=${searchValue}`
  }
  const response = await axios.get(
    apiUrl,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function fetchIncidentPayloadSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    `${API.baseUrl}/custom-functions/json-to-html?id=${id}&module=Incident`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function commentsDataSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'commentApps',
    'commentAppsList',
    'incidentComments',
    'incidentComments.icoAuthor',
    'incidentComments.attachment',
    'incidentComments.icoAuthor.profileImage',
    'incidentComments.notifiedUsers'
  ];



  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function tasksDataSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'incidentTasks',
    'incidentTasks.invAssignTo',
    'incidentTasks.invLaunchedBy',
    'incidentTasks.invTaskCategory',
    'incidentTasks.invDepartment',
    'incidentTasks.invAttachment',
  ];



  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function treeGraphDataSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'treeGraph',
  ];

  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}
export async function slaLogsSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'slaLogs',
  ];

  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}
export async function relatedDataSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'relatedIncidents',
  ];

  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function activityListSaga(id, fields) {
  const userToken = localStorage.getItem('AccessToken');

  const expanedString = fields.toString();
  const response = await axios.get(
    API.incidentManagement + '/' + id + '?expand=' + expanedString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function createSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.incidentManagement, data?.data, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function editIncidentSaga(data) {
  if (data?.data?.atOnce) {
    const url = data?.data?.url;
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(url, data?.data?.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response.data;

  } else {
    const url = data?.url;
    delete data?.id;
    delete data?.url;
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(url, data, {
      headers: {
        Authorization: 'Bearer ' + userToken,
      },
    });
    return response.data;
  }

}

export async function exportPdf(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const { query, url, type } = payload || {};

  if (type === 'default') {
    const response = await axios.get(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
      responseType: 'blob',
    });

    FileDownload(response.data, 'export.pdf');
    return response?.data;
  } else {
    const columns = {
      columns: payload?.columns
    };

    let api = `${API.incidentManagement}/export-pdf?${query}`;

    let formData = new FormData();
    for (let key in columns) {
      formData.append(key, JSON.stringify(columns[key]));
    }

    const response = await axios.post(api, formData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
      responseType: 'blob',
    });

    FileDownload(response.data, 'export.pdf');
    return response?.data;
  }

}

export async function exportExcel(url) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, 'export.xlsx');

  return response.data;
}
export async function exportSAS(url) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, 'export.xlsx');

  return response.data;
}


export async function sendEmailSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.customFunctions + '/email?id=' + payload.id + '&type=incident',
    payload.data,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}


export async function defaulSaveSearchSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.report + '/default',
    payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response?.data;
}




export async function linkAlertsSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagement + '/link-alert',
    payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function deleteSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.delete(API.incidentManagement + '/' + data?.id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function postComment({ id, payload }) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagementModule.comment.post + '/' + id,
    payload?.data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    const postedComment = response?.data?.data?.commentData;
    // if (postedComment) {
    //   postedComment.icoAuthor = response?.data?.data?.commentData?.ico_author;
    //   postedComment.notifiedUsers = response?.data?.data?.commentData?.notifiedUsers;
    //   postedComment.attachment = response?.data?.data?.commentData?.notifiedUsers;
    //   postedComment.profileImage = response?.data?.data?.data?.[4]
    // }
    payload.callback(postedComment, response?.data?.data?.slaInfo);
  }
  return response.data;
}

export async function deleteIncidentAsset(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagementModule.asset.deleteCall + '?id=' + payload?.id,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    const postedComment = response?.data?.data?.data?.[0];
    if (postedComment) {
      postedComment.attachment = response?.data?.data?.[2];
    }

    payload.callback(postedComment);
  }
  return response.data;
}

export async function addMemberToIncident(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagementModule.member.add + '/' + payload?.id,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    payload.callback();
  }
  return response.data;
}

export async function deleteComment(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    payload?.data?.ico_attachment && payload?.data?.ico_attachment !== ''
      ? API.incidentManagementModule.comment.deleteAttchement
      : API.incidentManagementModule.comment.delete,
    payload?.data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    payload.callback(response.data);
  }
  return response.data;
}
export async function deleteAttachment(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.incidentManagementModule.evidence.delete,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );

  if (payload?.callback) {
    payload.callback(response.data);
  }
  return response.data;
}
export async function createIncidentSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = API.incidentManagementModule.update;

  const response = await axios.post(url, data?.data, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getDispositionKeys(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = API.incidentManagementModule.dispositionKey;

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  if (data?.callback) {
    data.callback(response.data);
  }
  return response.data;
}

export async function getDispositionFields(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = `${API.incidentManagementModule.dispositionFields}?type=${data?.type}&typeCase=${data?.typeCase}`;

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getAppsConfigurations(apps) {
  const userToken = localStorage.getItem('AccessToken');
  //const appIds = apps?.join(',');
  const appIds = apps;
  const url = `${API.incidentManagementModule.actionApp.commentAppconfig}`;
  const response = await axios.post(url, { ico_comment_post_app_config: appIds }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function bulkUpdateIncident(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = `${API.incidentManagementModule.bulkUpdate}`;

  const response = await axios.post(url, data?.data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getExecutedPlaybooks(data) {
  const userToken = localStorage.getItem('AccessToken');

  const api = `${API.incidentManagement}/executed-playbook?id=${data.id}&page=${data.page}`;

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}



export async function getIncidentSearch(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api;
  api = `${API.incidentManagement}/incident-search?expand=itiCategory,itiAwareIncident&`;


  if (payload.queryItem) {
    api += `${payload.queryItem}`;
  }

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function bulkExecute(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = `${API.incidentManagementModule.bulkExecute}`;

  const response = await axios.post(url, data?.data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function bulkDelete(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = `${API.incidentManagementModule.bulkDelete}`;

  const response = await axios.post(url, data?.data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function getScoreApi(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.incidentManagement + '/nciss-category', {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function calculateScore(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.incidentManagement + '/calculate-nciss-score', payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function alertList(payload) {
  const { searchValue, disposition } = payload || {};
  const userToken = localStorage.getItem('AccessToken');
  let api = `${API.incidentManagement}/alert-list?searchValue=${searchValue}`;
  if (payload?.disposition) {
    api = `${API.incidentManagement}/alert-list?searchValue=${searchValue}&disposition=${disposition}`;
  }

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  }
  );
  return response.data;
}