import axios from 'axios';
import API from '../config/endpoints.config';
const FileDownload = require('js-file-download');

export async function threatIntelligenceSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api = '';
  let statusQuery = '';
  if (payload.path) {
    statusQuery = `adv_status=${payload.path}&`;
  }
  const expData =
    'expand=advDisposition,advCategory,advisoryOpenedBy,advArtifactMapping,advisorySource,compromisedAssets,advisoryOrganization,host';
  if (payload && payload.path && payload.path === 'threat-feeds') {
    api = `${API.advisory}/${payload.path}?${payload.queryItem}`;
  } else if (payload.queryItem) {
    api = `${API.advisory}?${expData}&${statusQuery}${payload.queryItem}`;
  } else {
    api = `${API.advisory}?${expData}&${statusQuery}`;
  }

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelFilterList(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api = API.advisory;
  if (payload.queryItem) {
    api += `?${payload.queryItem}`;
  }
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceDetailsSaga({ id, edit }) {
  const userToken = localStorage.getItem('AccessToken');
  let fields = [
    'advDisposition',
    'advCase',
    'advCategory',
    'advisoryOpenedBy',
    'advisoryOrganization',
    'advisorySource',
    'advArtifactMapping',
    // 'advisoryAsset',
    // 'advisoryAsset.astOwner',
    // 'advisoryAssetType',
    // 'slaInfo',
    'relatedAdvisories',
    'compromisedAssets',
    'mitreSubTechniques', 'mitreTactics', 'mitreTechniques',  'affectedVendors', 'affectedProducts'
  ];
  if (edit) {
    fields = [
      'incidentArtifact',
      'advArtifactMapping',
      'advArtifacts',
      'affectedVendors',
      'affectedProducts'
    ];
  }
  const expandString = fields.toString();
  const response = await axios.get(
    API.advisory + '/' + id + '?expand=' + expandString,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function threatDetailsSaga(id, fields, evidenceKey, page, searchValue) {
  const userToken = localStorage.getItem('AccessToken');
  const expandString = fields.toString();
  let apiUrl = `${API.advisory}/${id}?art_id=${evidenceKey || null}&index=${page == null ? null : 0}&expand=${expandString}`;

  if (searchValue) {
    apiUrl = apiUrl + `&search=${searchValue}`
  }
  const response = await axios.get(
    apiUrl,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function fetchThreaatIntelPayloadSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    `${API.baseUrl}/custom-functions/json-to-html?id=${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function filterSaga(filterData) {
  //   const userToken = localStorage.getItem('AccessToken');
  //   const response = await axios.get(API.searchCases + filterData, {
  //     // method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + userToken,
  //     },
  //   });
  //   return response.data;
}

export async function rawDataSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    API.advisoryArtifactRawOutput + '?id=' + data.id,
    {
      // method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function addEvidenceSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const payload = {
    heading: data.data.values.heading,
    ina_output_html: data.data.ina_output_html,
  };
  const response = await axios.post(
    API.advisoryAddEvidence + '?id=' + data.id,
    payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function deleteSaga(deleteCaseData) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.delete(API.advisory + '/' + deleteCaseData, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function deleteFeed(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.delete(`${API.advisory}/threat-feeds/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function sendAdviosry(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(
    `${API.advisory}/threat-feeds/send-advisory?id=${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}
export async function threatIntelligenceCategorySaga(categoryData) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryCategory, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function threatIntelligenceSubCategorySaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisorySubCategory + '?cat_id=' + id + `&module=advisory`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceCaseCategorySaga(categoryData) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryCaseCategory, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceCasesSubCategory(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.casesSubcategories + '?cat_id=' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceSubdispositionsSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.CasessubDispositions + '?cat_id=' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceSeveritySaga(categoryData) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisorySeverity, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceAssetSaga(categoryData) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetList, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceDepartmentSaga(categoryData) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryDepartments, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceEvidencesaga() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryEvidence, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceDispositionssaga() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryDispositions, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceOpenCaseSaga(id, requestBody) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(`${API.openCase}?vid=${id}`, requestBody, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceProductsaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const api = `${API.advisoryProducts}`;
  const response = await axios.get(api, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function threatIntelligenceVendors(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryVendors, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceSources() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisorySources, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceThreatActors() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryThreatActors, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceCasesAdvisoryLocation() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.casesAdvisorylocation, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getProductDatails() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryProducts, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getCaseAdvisoryItem() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.caseAdvisoryItems, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getCaseAdvisoryUsers() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.casesAdvisoryUserList, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function updateAdvisoryDetails(id, data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.put(API.advisory + '/' + id?.id, data?.data, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function createAdvisory(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.advisory, data?.data, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function threatIntelligenceExportAsPDF(id) {
  const userToken = localStorage.getItem('AccessToken');

  // const xhr = new XMLHttpRequest();
  // xhr.open('GET', `${API.advisoryExport}?id=${id}`, true);
  // xhr.responseType = 'arraybuffer';
  // xhr.setRequestHeader('Authorization', 'Bearer ' + userToken);
  // xhr.onload = function (e) {
  //   if (this.status === 200) {
  //     const blob = new Blob([this.response], { type: 'application/pdf' });
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'Report_' + new Date() + '.pdf';
  //     link.click();
  //   }
  // };
  // xhr.send();

  const response = await axios.get(`${API.advisoryExport}?id=${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, `Report_${new Date()}.pdf`);
  return response.data;
}

export async function updatePartialThreatIntelApi(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const requestBody = payload?.data;
  const response = await axios.post(
    API.advisoryCustomUpdate + '/' + payload?.id,
    requestBody,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getRunPlayBookDataApi() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryGetPlayBook, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function executePlayBookAPI(data) {
  const userToken = localStorage.getItem('AccessToken');
  const requestBody = {
    playbookid: data?.payload?.playBookID,
  };
  var baseItem = API.advisoryPlayBook;
  if (data?.payload?.type === 'incident') {
    baseItem = API.incidentPlayBook;
  }

  const response = await axios.post(
    baseItem + '?id=' + data?.payload?.id+'&type=incident',
    requestBody,
    {

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function publishAdvisoryAPI(data) {
  const userToken = localStorage.getItem('AccessToken');
  const requestBody = {
    publish_advisory: data?.payload?.publish_advisory,
  };
  var baseItem = `${API.advisory}/publish`;


  const response = await axios.post(
    baseItem + '?id=' + data?.payload?.id,
    requestBody,
    {

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function getUserEmailListApi() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.advisoryUserEmail, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function sendEmailApi(data) {
  const userToken = localStorage.getItem('AccessToken');
  const requestBody = {
    send_email_ids: data.payload.data.to,
    send_email_cc_ids: data.payload.data.cc,
    send_email_formats: data.payload.data.template,
    email_note: data.payload.data.description,
    send_email_template: data.payload.data.template_detail,
    send_email_bcc_ids: data.payload.data.bcc,
  };

  const response = await axios.post(
    API.advisorySendEmail + '?id=' + data?.payload?.selecteAdvisorieId + '&type=advisory',
    requestBody,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function updateArtifactsApi(data) {
  const userToken = localStorage.getItem('AccessToken');
  const { index, art_id, action , type } = data?.payload || {};
  let payload = "";
  if (data?.payload?.action === "add") {
    payload = {
      ...data?.payload?.updatedArtifact,
      action: action
    };
  } else {
    if (Object.keys(data?.payload?.updatedArtifact)[0] === 'Artifact_Header' || Object.keys(data?.payload?.updatedArtifact)[0] === 'Artifact_File') {
      payload = {
        ...data.payload.updatedArtifact,
        "status": "0",
        action: action
      }
    } else {
      payload = {
        ...data.payload.updatedArtifact,
        action: action
      };
    }
  }

  const response = await axios.post(
    `${API.advisoryArtifactUpdate}?id=${data.payload?.selecteAdvisorieId}&index=${index}&art_id=${art_id}&type=${type}`,
    payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function executeActionApi(data) {
  const userToken = localStorage.getItem('AccessToken');
  const requestBody = {
    act_is_multiinput: data.payload.actionInfo.item.details.act_is_multiinput,
    app_multi_config_allowed:
      data.payload.actionInfo.itemDetails.app_multi_config_allowed,
    t_ID: data.payload.selecteAdvisorieId,
    act: data.payload.actionInfo.item.details.act_id,
    input: data.payload.actionInfo.inputName,
    module: 'Advisory',
    multi_step: '',
    app: data.payload.actionInfo.item.details.app_id,
  };
  const response = await axios.post(
    API.advisoryArtifactExectuteAction,
    requestBody,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function multiExecuteActionApi(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(
    API.advisoryArtifactMultiConfigExectuteAction,
    data.payload,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function bulkUpdateAPI(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(`${API.advisory}/bulk`, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function bulkDelete(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(`${API.advisory}/bulk-delete`, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function bulkExecute(data) {
  const userToken = localStorage.getItem('AccessToken');
  const url = `${API.customFunctions}/bulk-execute`;

  const response = await axios.post(url, data?.data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function exportExcel(url) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
    responseType: 'blob',
  });
  FileDownload(response.data, 'export.xlsx');

  return response.data;
}

export async function generateReport(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let api = `${API.advisory}/reports`;
  let data = payload.payload;
  if (payload.module === 'cases') {
    api = `${API.cases}/reports`
  }
  if (payload.module === 'asset') {
    let search = (payload?.payload?.searchText) ? `&search=${payload.payload.searchText}` : '';
    let filters = (payload?.payload?.filters) ? `&filters=${payload.payload.filters}` : ''
    // api = `${API.asset}/export?page=${payload.payload.currentPage}&per-page=${payload.payload.iti_showing}${search}`;
    api = `${API.exportJob}/export?${search}${filters}`;
    data = payload.payload.values;
  }
  const response = await axios.post(
    api,
    data,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
      responseType: 'blob',
    }
  );
  let ext = 'xlsx';
  if (
    response?.headers?.['content-type'] &&
    response?.headers?.['content-type'].includes('pdf')
  ) {
    ext = 'pdf';
  }
  if (payload.module !== 'asset') {
    FileDownload(response.data, `report.${ext}`);

  }
  return response.data;
}
export async function slaLogsSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  var fields = [
    'slaLogs',
  ];

  const expanedString = fields.toString();
  let api = `${API.advisory}/${id}?expand=${expanedString}`;
  const response = await axios.get(
    api,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    }
  );
  return response.data;
}

export async function importSaga(file) {
  const userToken = localStorage.getItem('AccessToken');
  const f = new FormData();
  f.append('importedFile', file);
  const response = await axios.post(API.advisoryImport, f, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}