// import * as downloadjs from 'downloadjs';
import { put, call, takeLatest } from 'redux-saga/effects';
import { showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';
import { makeFilterList } from '../../utils/helper.utils';
import {
  INCIDENT_MANAGEMENT_LIST_SUCCESSED,
  INCIDENT_MANAGEMENT_LIST_FAILED,
  INCIDENT_MANAGEMENT_LIST_REQUESTED,
  INCIDENT_SOURCES_LIST_SUCCESSED,
  INCIDENT_SOURCES_LIST_FAILED,
  INCIDENT_SOURCES_LIST_REQUESTED,
  REPORTS_LIST_REQUESTED,
  REPORTS_LIST_SUCCESSED,
  REPORTS_LIST_FAILED,
  INCIDENT_CUSTOMERS_LIST_SUCCESSED,
  INCIDENT_CUSTOMERS_LIST_FAILED,
  INCIDENT_CUSTOMERS_LIST_REQUESTED,
  INCIDENT_SEVERITY_LIST_SUCCESSED,
  INCIDENT_SEVERITY_LIST_FAILED,
  INCIDENT_SEVERITY_LIST_REQUESTED,
  INCIDENT_DISPOSITION_LIST_SUCCESSED,
  INCIDENT_DISPOSITION_LIST_FAILED,
  INCIDENT_DISPOSITION_LIST_REQUESTED,
  INCIDENT_SINGLE_EXPORT_PDF_LIST_REQUESTED,
  INCIDENT_SINGLE_EXPORT_PDF_LIST_SUCCESSED,
  INCIDENT_SINGLE_EXPORT_PDF_LIST_FAILED,
  INCIDENT_DETAILS_REQUESTED,
  INCIDENT_DETAILS_SUCCESSED,
  INCIDENT_DETAILS_FAILED,
  INCIDENT_MANAGEMENT_EDIT_REQUESTED,
  INCIDENT_MANAGEMENT_EDIT_SUCCESSED,
  INCIDENT_MANAGEMENT_EDIT_FAILED,
  INCIDENT_MANAGEMENT_CREATE_REQUESTED,
  INCIDENT_MANAGEMENT_CREATE_SUCCESSED,
  INCIDENT_MANAGEMENT_CREATE_FAILED,
  INCIDENT_EXPORT_EXCEL_LIST_REQUESTED,
  INCIDENT_EXPORT_EXCEL_LIST_SUCCESSED,
  INCIDENT_EXPORT_EXCEL_LIST_FAILED,
  INCIDENT_EXPORT_PDF_LIST_REQUESTED,
  INCIDENT_EXPORT_PDF_LIST_SUCCESSED,
  INCIDENT_EXPORT_PDF_LIST_FAILED,
  INCIDENT_SEND_EMAIL_REQUESTED,
  INCIDENT_SEND_EMAIL_SUCCESSED,
  INCIDENT_SEND_EMAIL_FAILED,
  SHOW_LOADER,
  HIDE_LOADER,
  INCIDENT_POST_COMMENT_SUCCESSED,
  INCIDENT_POST_COMMENT_FAILED,
  INCIDENT_POST_COMMENT_REQUESTED,
  INCIDENT_ADD_MEMBER_REQUESTED,
  INCIDENT_ADD_MEMBER_FAILED,
  INCIDENT_ADD_MEMBER_SUCCESSED,
  INCIDENT_ASSET_DELETE_REQUESTED,
  INCIDENT_ASSET_DELETE_SUCCESSED,
  INCIDENT_ASSET_DELETE_FAILED,
  DELETE_COMMENT_SUCCESSED,
  DELETE_COMMENT_FAILED,
  DELETE_COMMENT_REQUESTED,
  DELETE_ATTACHMENT_SUCCESSED,
  DELETE_ATTACHMENT_FAILED,
  DELETE_ATTACHMENT_REQUESTED,
  GET_REPORT_TYPES_REQUESTED,
  GET_REPORT_TYPES_SUCCESSED,
  GET_REPORT_TYPES_FAILED,
  GENERATE_REPORT_SUCCESSED,
  GENERATE_REPORT_FAILED,
  GENERATE_REPORT_REQUESTED,
  DOWNLOAD_ARTIFCATS_FILE,
  INCIDENT_DISPOSITION_FIELD_REQUESTED,
  INCIDENT_DISPOSITION_KEY_REQUESTED,
  INCIDENT_DISPOSITION_KEY_SUCCESSED,
  INCIDENT_DISPOSITION_KEY_FAILED,
  INCIDENT_DISPOSITION_FIELD_SUCCESSED,
  INCIDENT_DISPOSITION_FIELD_FAILED,
  INCIDENT_APP_CONFIG_REQUESTED,
  INCIDENT_APP_CONFIG_SUCCESSED,
  INCIDENT_APP_CONFIG_FAILED,
  DELETE_INCIDENT_REQUESTED,
  DELETE_INCIDENT_SUCCESSED,
  DELETE_INCIDENT_FAILED,
  BULK_UPDATE_INCIDENT_REQUESTED,
  BULK_UPDATE_INCIDENT_SUCCESSED,
  BULK_UPDATE_INCIDENT_FAILED,
  THREAT_INTEL_EXPORT_EXCEL_LIST_SUCCESSED,
  THREAT_INTEL_EXPORT_EXCEL_LIST_FAILED,
  THREAT_INTEL_EXPORT_EXCEL_LIST_REQUESTED,
  INCIDENT_SEARCH_LIST_REQUESTED,
  INCIDENT_SEARCH_LIST_PROCESSING,
  INCIDENT_SEARCH_LIST_SUCCESSED,
  INCIDENT_SEARCH_LIST_FAILED,
  BULK_EXECUTE_REQUESTED,
  BULK_EXECUTE_SUCCESSED,
  BULK_EXECUTE_FAILED,
  BULK_DELETE_REQUESTED,
  BULK_DELETE_SUCCESSED,
  BULK_DELETE_FAILED,
  CASES_LIST_SUCCESSED,
  CASES_LIST_FAILED,
  ALERTS_LIST_REQUESTED,
  ALERTS_LIST_SUCCESSED,
  ALERTS_LIST_FAILED,
  INCIDENT_LINK_ALERTS_REQUESTED,
  INCIDENT_LINK_ALERTS_SUCCESSED,
  INCIDENT_LINK_ALERTS_FAILED,
  INCIDENT_DEFAULT_SAVED_SEARCH_REQUESTED
} from '../../constants/actionTypes';
import {
  listSaga,
  listIncidentSourcesSaga,
  listIncidentCustomersSaga,
  listIncidentSeveritySaga,
  listIncidentDispositionSaga,
  singleExportPdf,
  incidentDetailsSaga,
  exportExcel,
  exportPdf,
  sendEmailSaga,
  createSaga,
  editIncidentSaga,
  postComment,
  addMemberToIncident,
  deleteIncidentAsset,
  deleteComment,
  deleteAttachment,
  getDispositionKeys,
  getDispositionFields,
  getAppsConfigurations,
  deleteSaga,
  bulkUpdateIncident,
  getIncidentSearch,
  bulkExecute,
  bulkDelete,
  alertList,
  linkAlertsSaga,
  listIncidentReportsSaga,
  defaulSaveSearchSaga
} from '../../api/incidentManagementSaga';
import {
  downloadAsset,
  generateReport,
  reportTypes,
} from '../../api/incidentMasterData';
import {
  casesSaga,
} from '../../api/casesSaga';

export function* watchList(action) {
  try {
    const response = yield call(listSaga, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_MANAGEMENT_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_MANAGEMENT_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_MANAGEMENT_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSourcesList(action) {
  try {
    const response = yield call(listIncidentSourcesSaga, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_SOURCES_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_SOURCES_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_MANAGEMENT_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchReportsList(action) {
  try {
    const response = yield call(listIncidentReportsSaga, action.payload);
    if (response.success) {
      yield put({ type: REPORTS_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: REPORTS_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: REPORTS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchDefaultSavedSearch(action) {
  const loaderHandle = showToastLoader(`${action?.payload?.id ? 'Saving' : 'Removing'} Default Search...`)
  try {
    const response = yield call(defaulSaveSearchSaga, action.payload);
    if (response?.success) {
      showToastSuccess(response?.data?.success, loaderHandle)
    }
    else {
      showToastError('Default Search Save Failed', loaderHandle)
    }
  } catch (error) {
    showToastError('Default Search Save Failed', loaderHandle)
  }

}




export function* watchCustomersList(action) {
  try {
    const response = yield call(listIncidentCustomersSaga, action.payload);
    if (response.success) {
      const customerList = makeFilterList(response?.data) || [];
      yield put({ type: INCIDENT_CUSTOMERS_LIST_SUCCESSED, data: customerList });
    } else {
      yield put({ type: INCIDENT_CUSTOMERS_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_CUSTOMERS_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSeverityList(action) {
  try {
    const response = yield call(listIncidentSeveritySaga, action.payload);
    if (response.success) {
      const SeverityList = makeFilterList(response?.data) || [];
      yield put({ type: INCIDENT_SEVERITY_LIST_SUCCESSED, data: SeverityList });
    } else {
      yield put({ type: INCIDENT_SEVERITY_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_SEVERITY_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDispositionList(action) {
  try {
    const response = yield call(listIncidentDispositionSaga, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_DISPOSITION_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_DISPOSITION_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_DISPOSITION_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSingleExportPdf(action) {
  try {
    const response = yield call(singleExportPdf, action.id);
    if (response.success) {
      yield put({
        type: INCIDENT_SINGLE_EXPORT_PDF_LIST_SUCCESSED,
        data: response,
      });
    } else {
      yield put({ type: INCIDENT_SINGLE_EXPORT_PDF_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_SINGLE_EXPORT_PDF_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchIncidentDetails(action) {
  try {
    const response = yield call(incidentDetailsSaga, action.id, action.isOnlyTask);
    if (response.success) {
      yield put({ type: INCIDENT_DETAILS_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_DETAILS_FAILED, data: null });
    }
    yield put({ type: HIDE_LOADER });
    if (window.location.pathname.split('/')[1] == "incidentManagement" || window.location.pathname.split('/')[1] == "cases") {
      const response = yield call(incidentDetailsSaga, action.id, action.isOnlyTask);
      if (response.success) {
        yield put({ type: INCIDENT_DETAILS_SUCCESSED, data: response });
      } else {
        yield put({ type: INCIDENT_DETAILS_FAILED, data: null });
      }
      yield put({ type: HIDE_LOADER });
    }

  } catch (err) {
    yield put({
      type: INCIDENT_DETAILS_FAILED,
    });
    window.location.replace('/incidentManagement');
    yield put({ type: HIDE_LOADER });
  }
}

export function* watchEdit(action) {
  let responseMessage, loaderMessage
  if (action.payload.iti_compromised_asset) loaderMessage = 'Adding asset...'
  else if (action.payload.iti_closed_remediation) loaderMessage = 'Updating remediation...'
  else if (action.payload.iti_suggestions_recovery) loaderMessage = 'Updating remediation details...'
  else if (action.payload.iti_disposition_id) loaderMessage = 'Changing disposition...'
  else if (action.payload.iti_ticket_status) loaderMessage = 'Changing status...'
  else if (action.payload.iti_assign_to) loaderMessage = 'Updating Analyst...'
  else loaderMessage = `Updating ${(action.payload?.type)?.toLowerCase() ?? 'incident'}...`

  const loaderHandle = showToastLoader(loaderMessage)
  try {
    const response = yield call(editIncidentSaga, action.payload);

    if (response.success) {
      if (action.payload.iti_compromised_asset) responseMessage = 'Asset added'
      else if (action.payload.iti_closed_remediation) responseMessage = 'Remediation updated'
      else if (action.payload.iti_suggestions_recovery) responseMessage = 'Remediation details updated'
      else if (action.payload.iti_disposition_id) responseMessage = 'Disposition changed'
      else if (action.payload.iti_ticket_status) responseMessage = 'Status changed'
      else if (action.payload.iti_assign_to) responseMessage = 'Analyst changed'
      else responseMessage = response?.data?.message;

      showToastSuccess(`${responseMessage}`, loaderHandle)
      yield put({ type: INCIDENT_MANAGEMENT_EDIT_SUCCESSED, data: response });
      if (action.payload?.callback) action.payload.callback({ isSuccess: true, data: response?.data });
    } else {
      yield put({ type: INCIDENT_MANAGEMENT_EDIT_FAILED, data: null });
      showToastError(`${action?.payload?.type} update failed`, loaderHandle)
      if (action.payload?.callback) action.payload.callback({ isSuccess: false });
    }
  } catch (err) {
    showToastError(`${action?.payload?.type} update failed`, loaderHandle)
    yield put({
      type: INCIDENT_MANAGEMENT_EDIT_FAILED,
      data: err?.response?.data?.data,
    });
    if (action.payload?.callback) action.payload.callback({ isSuccess: false });
  }
}

export function* watchExcelExport(action) {
  const loaderHandle = showToastLoader('Downloading excel...')
  try {

    const response = yield call(exportExcel, action.payload);
    if (response.size) {
      showToastSuccess('Excel downloaded', loaderHandle)
      yield put({ type: INCIDENT_EXPORT_EXCEL_LIST_SUCCESSED, data: response });
    } else {
      showToastError('Excel exporting failed', loaderHandle)
      yield put({ type: INCIDENT_EXPORT_EXCEL_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Excel exporting failed', loaderHandle)
    yield put({
      type: INCIDENT_EXPORT_EXCEL_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchThreatIntelExcelExport(action) {
  const loaderHandle = showToastLoader('Downloading excel...')
  try {
    let payload = action.payload;
    const expData =
      'expand=advCategory,advisorySource';
    payload = `${payload}&${expData}`
    const response = yield call(exportExcel, payload);
    if (response.size) {
      showToastSuccess('Excel downloaded', loaderHandle)
      yield put({ type: THREAT_INTEL_EXPORT_EXCEL_LIST_SUCCESSED, data: response });
    } else {
      showToastError('Excel download failed', loaderHandle)
      yield put({ type: THREAT_INTEL_EXPORT_EXCEL_LIST_FAILED, data: null });
    }
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    showToastError('Excel download failed', loaderHandle)
    yield put({
      type: THREAT_INTEL_EXPORT_EXCEL_LIST_FAILED,
      data: err?.response?.data?.data,
    });
    yield put({ type: HIDE_LOADER });
  }
}

export function* watchPdfExport(action) {
  const loaderHandle = showToastLoader('Downloading PDF...')
  try {
    const response = yield call(exportPdf, action?.payload);
    if (response.size) {
      showToastSuccess('PDF downloaded', loaderHandle)
      yield put({ type: INCIDENT_EXPORT_PDF_LIST_SUCCESSED, data: response });
      // downloadjs.download(response.data, 'export.pdf', 'application/pdf');
    } else {
      showToastError('PDF download failed', loaderHandle)
      yield put({ type: INCIDENT_EXPORT_PDF_LIST_FAILED, data: null });
    }
  } catch (err) {
    showToastError('PDF download failed', loaderHandle)
    yield put({
      type: INCIDENT_EXPORT_PDF_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchSendEmail(action) {
  const loaderHandle = showToastLoader('Sending email...')
  try {
    const response = yield call(sendEmailSaga, action.payload);

    if (response.success) {
      showToastSuccess('Email sent', loaderHandle)
      yield put({ type: INCIDENT_SEND_EMAIL_SUCCESSED, data: response });
    } else {
      showToastError('Email sending failed', loaderHandle)
      yield put({ type: INCIDENT_SEND_EMAIL_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Email sending failed', loaderHandle)
    yield put({
      type: INCIDENT_SEND_EMAIL_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchLinkingAlerts(action) {
  const loaderHandle = showToastLoader('Linking Alerts...')
  try {
    const response = yield call(linkAlertsSaga, action.payload);

    if (response.success) {
      showToastSuccess('Alerts Linked', loaderHandle)
      yield put({ type: INCIDENT_LINK_ALERTS_SUCCESSED, data: response });
      action?.callback();
    } else {
      showToastError('Alerts Linking failed', loaderHandle)
      yield put({ type: INCIDENT_LINK_ALERTS_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Alerts Linking failed', loaderHandle)
    yield put({
      type: INCIDENT_LINK_ALERTS_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchPostComment(action) {
  const loaderHandle = showToastLoader('Adding comment...')
  try {
    const response = yield call(postComment, action.payload);
    if (response.success) {
      showToastSuccess('Comment added', loaderHandle)
      yield put({ type: INCIDENT_POST_COMMENT_SUCCESSED, data: response });
    } else {
      showToastError('Comment add failed', loaderHandle)
      yield put({ type: INCIDENT_POST_COMMENT_FAILED, data: null });
    }
  } catch (err) {
    showToastError(err?.response?.data?.data?.message, loaderHandle)
    yield put({
      type: INCIDENT_POST_COMMENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAddMember(action) {
  const actionType = action?.payload?.action
  const loaderHandle = showToastLoader(`${actionType === 'add' ? 'Adding' : 'Deleting'} member...`)
  try {
    const response = yield call(addMemberToIncident, action.payload);
    if (response.success) {
      const msg = actionType === 'add' ? 'Member added' : 'Member deleted';
      showToastSuccess(`${msg}`, loaderHandle)
      yield put({ type: INCIDENT_ADD_MEMBER_SUCCESSED, data: response });
      action?.payload?.callback(response);
    } else {
      showToastError(`Member ${actionType === 'add' ? 'add' : 'delete'} failed`, loaderHandle)
      yield put({ type: INCIDENT_ADD_MEMBER_FAILED, data: null });
    }
  } catch (err) {
    showToastError(`Member ${actionType === 'add' ? 'add' : 'delete'} failed`, loaderHandle)
    yield put({
      type: INCIDENT_ADD_MEMBER_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteAsset(action) {
  const loaderHandle = showToastLoader('Deleting asset...')
  try {
    const response = yield call(deleteIncidentAsset, action.payload);
    if (response.success) {
      showToastSuccess('Asset deleted', loaderHandle)
      yield put({ type: INCIDENT_ASSET_DELETE_SUCCESSED, data: response });
    } else {
      showToastError('Asset delete failed', loaderHandle)
      yield put({ type: INCIDENT_ASSET_DELETE_FAILED, data: null });
    }
    if (action?.payload?.callback) {
      action.payload.callback(response?.data);
    }
  } catch (err) {
    showToastError('Asset delete failed', loaderHandle)
    yield put({
      type: INCIDENT_ASSET_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteComment(action) {
  const loaderHandle = showToastLoader('Deleting comment...')
  try {
    const response = yield call(deleteComment, action.payload);
    if (response.success) {
      const msg = action.payload?.data?.ico_attachment ? 'Comment Attachment deleted' : 'Comment deleted';
      showToastSuccess(`${msg}`, loaderHandle)
      yield put({ type: DELETE_COMMENT_SUCCESSED, data: response });
    } else {
      showToastError('Comment delete failed', loaderHandle)
      yield put({ type: DELETE_COMMENT_FAILED, data: null });
    }
    if (action?.payload?.callback) {
      action.payload.callback(response?.data);
    }
  } catch (err) {
    showToastError('Comment delete failed', loaderHandle)
    yield put({
      type: DELETE_COMMENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchDeleteAttachment(action) {
  const loaderHandle = showToastLoader('Deleting evidence attachment...')
  try {
    const response = yield call(deleteAttachment, action);
    if (response.success) {
      showToastSuccess('Evidence attachment deleted', loaderHandle)
      yield put({ type: DELETE_ATTACHMENT_SUCCESSED, data: response });
    } else {
      showToastError('Attachment delete failed', loaderHandle)
      yield put({ type: DELETE_ATTACHMENT_FAILED, data: null });
    }
    if (action?.callback) {
      action.callback(response?.data);
    }
  } catch (err) {
    showToastError('Attachment delete failed', loaderHandle)
    yield put({
      type: DELETE_ATTACHMENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGetReportTypes(action) {
  try {
    const response = yield call(reportTypes, action.payload);
    if (response.success) {
      yield put({ type: GET_REPORT_TYPES_SUCCESSED, data: response });
    } else {
      yield put({ type: GET_REPORT_TYPES_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: GET_REPORT_TYPES_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchGenerateReport(action) {
  const loaderHandle = showToastLoader('Generating report...')
  try {
    const response = yield call(generateReport, action.payload);
    if (response.size) {
      showToastSuccess('Report generated', loaderHandle)
      yield put({ type: GENERATE_REPORT_SUCCESSED, data: response });
    } else {
      showToastError('Report generation failed', loaderHandle)
      yield put({ type: GENERATE_REPORT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Report generation failed', loaderHandle)
    yield put({
      type: GENERATE_REPORT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchCreate(action) {
  const loaderHandle = showToastLoader(`Creating ${(action.payload?.type)?.toLowerCase() ?? 'incident'}...`)
  try {
    const response = yield call(createSaga, action.payload);
    if (response.success) {
      const msg = `${action.payload?.type ?? 'Incident'} created`;
      showToastSuccess(msg, loaderHandle)
      yield put({ type: INCIDENT_MANAGEMENT_CREATE_SUCCESSED, data: response });
      if (action.payload?.callback) action.payload.callback({ isSuccess: true, isLink: action?.payload.isLink });
    } else {
      showToastError(`${action?.payload?.type} create failed`, loaderHandle)
      yield put({ type: INCIDENT_MANAGEMENT_CREATE_FAILED, data: null });
      if (action.payload?.callback) action.payload.callback({ isSuccess: false });
    }
  } catch (err) {
    if (err?.response?.status == 406) {
      showToastError(`${action?.payload?.type} create failed`, loaderHandle)
    }
    else {
      showToastError(`${action?.payload?.type} create failed`, loaderHandle)
    }
    yield put({
      type: INCIDENT_MANAGEMENT_CREATE_FAILED,
      data: err?.response?.data?.data,
    });
    if (action.payload?.callback) action.payload.callback({ isSuccess: false });
  }
}

export function* watchAssetDownload(action) {
  try {
    yield put({ type: SHOW_LOADER });
    yield call(downloadAsset, action.payload);
    yield put({ type: HIDE_LOADER });
  } catch (err) {
    yield put({ type: HIDE_LOADER });
  }
}

export function* watchGetDispositionKeys(action) {
  try {
    const response = yield call(getDispositionKeys, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_DISPOSITION_KEY_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_DISPOSITION_KEY_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: INCIDENT_DISPOSITION_KEY_FAILED, data: null });
  }
}

export function* watchGetDispositionFields(action) {
  try {
    const response = yield call(getDispositionFields, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_DISPOSITION_FIELD_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_DISPOSITION_FIELD_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: INCIDENT_DISPOSITION_FIELD_FAILED, data: null });
  }
}

export function* watchGetAppConfiguration(action) {
  try {
    const response = yield call(getAppsConfigurations, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_APP_CONFIG_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_APP_CONFIG_FAILED, data: null });
    }
  } catch (err) {
    yield put({ type: INCIDENT_APP_CONFIG_FAILED, data: null });
  }
}

export function* watchDelete(action) {
  const type = action.payload.name
  const loaderHandle = showToastLoader(`Deleting ${type}...`)
  try {
    const deleteCaseData = yield call(deleteSaga, action.payload);

    if (deleteCaseData.success === true) {
      showToastSuccess(`${type} deleted`, loaderHandle)
      yield put({ type: DELETE_INCIDENT_SUCCESSED, data: deleteCaseData });
      const response = yield call(listSaga, action.payload);
      if (response.success) {
        yield put({ type: INCIDENT_MANAGEMENT_LIST_SUCCESSED, data: response });
      } else {
        yield put({ type: INCIDENT_MANAGEMENT_LIST_FAILED, data: null });
      }
    } else {
      showToastError(`${type} delete failed`, loaderHandle)
      yield put({ type: DELETE_INCIDENT_FAILED, data: null });
    }
  } catch (err) {
    showToastError(`${type} delete failed`, loaderHandle)
    yield put({
      type: DELETE_INCIDENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchBulkUpdateIncident(action) {
  const loaderHandle = showToastLoader('Updating records...')
  try {
    const deleteCaseData = yield call(bulkUpdateIncident, action.payload);

    if (deleteCaseData.success === true) {
      showToastSuccess('Records updated', loaderHandle)
      yield put({ type: BULK_UPDATE_INCIDENT_SUCCESSED, data: deleteCaseData });
      if (action?.payload?.data?.type !== 'case') {
        const response = yield call(listSaga, action.payload.data);
        if (response.success) {
          yield put({ type: INCIDENT_MANAGEMENT_LIST_SUCCESSED, data: response });
        } else {
          yield put({ type: INCIDENT_MANAGEMENT_LIST_FAILED, data: null });
        }
      }
      else {

        const casesData = yield call(casesSaga, action.payload, action?.payload?.activeOption);

        if (casesData.success === true) {
          yield put({ type: CASES_LIST_SUCCESSED, data: casesData });
          ;
        } else {
          yield put({ type: CASES_LIST_FAILED, data: null });
        }

      }
    } else {
      showToastError('Records update failed', loaderHandle)
      yield put({ type: BULK_UPDATE_INCIDENT_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Records update failed', loaderHandle)
    yield put({
      type: BULK_UPDATE_INCIDENT_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchGetIncidentSearch(action) {
  try {
    const response = yield call(getIncidentSearch, action.payload);
    if (response.success) {
      yield put({ type: INCIDENT_SEARCH_LIST_SUCCESSED, data: response });
    } else {
      yield put({ type: INCIDENT_SEARCH_LIST_FAILED, data: null });
    }
  } catch (err) {
    yield put({
      type: INCIDENT_SEARCH_LIST_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchBulkExecute(action) {
  const loaderHandle = showToastLoader('Executing playbook...')
  try {
    const deleteCaseData = yield call(bulkExecute, action.payload);

    if (deleteCaseData.success === true) {
      showToastSuccess('Playbook executed', loaderHandle)
      yield put({ type: BULK_EXECUTE_SUCCESSED, data: deleteCaseData });
      const response = yield call(listSaga, action.payload.data);


      if (action?.payload?.data?.type !== 'case') {
        if (response.success) {
          yield put({ type: INCIDENT_MANAGEMENT_LIST_SUCCESSED, data: response });
        } else {
          yield put({ type: INCIDENT_MANAGEMENT_LIST_FAILED, data: null });
        }
      }
      else {

        const casesData = yield call(casesSaga, action.payload, action?.payload?.activeOption);

        if (casesData.success === true) {
          yield put({ type: CASES_LIST_SUCCESSED, data: casesData });
          ;
        } else {
          yield put({ type: CASES_LIST_FAILED, data: null });
        }

      }

    } else {
      showToastError('Playbook execute failed', loaderHandle)
      yield put({ type: BULK_EXECUTE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Playbook execute failed', loaderHandle)
    yield put({
      type: BULK_EXECUTE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}
export function* watchBulkDelete(action) {
  const loaderHandle = showToastLoader('Deleting records...')
  try {
    const deleteCaseData = yield call(bulkDelete, action.payload);

    if (deleteCaseData.success === true) {
      showToastSuccess('Records deleted', loaderHandle)
      yield put({ type: BULK_DELETE_SUCCESSED, data: deleteCaseData });
      const response = yield call(listSaga, action.payload.data);
      if (action?.payload?.data?.type !== 'case') {
        if (response.success) {
          yield put({ type: INCIDENT_MANAGEMENT_LIST_SUCCESSED, data: response });
        } else {
          yield put({ type: INCIDENT_MANAGEMENT_LIST_FAILED, data: null });
        }
      }
      else {

        const casesData = yield call(casesSaga, action.payload, action?.payload?.activeOption);

        if (casesData.success === true) {
          yield put({ type: CASES_LIST_SUCCESSED, data: casesData });
          ;
        } else {
          yield put({ type: CASES_LIST_FAILED, data: null });
        }

      }

    } else {
      showToastError('Records delete failed', loaderHandle)
      yield put({ type: BULK_DELETE_FAILED, data: null });
    }
  } catch (err) {
    showToastError('Records delete failed', loaderHandle)
    yield put({
      type: BULK_DELETE_FAILED,
      data: err?.response?.data?.data,
    });
  }
}

export function* watchAlertList(action) {
  try {

    const response = yield call(alertList, action.payload);
    if (response.success) {
      yield put({
        type: ALERTS_LIST_SUCCESSED, data: response?.data,
      })
    }
  } catch (err) {
    yield put({ type: ALERTS_LIST_FAILED, data: err });
  }
}
export default function* watcher() {
  yield takeLatest(INCIDENT_MANAGEMENT_LIST_REQUESTED, watchList);
  yield takeLatest(INCIDENT_SOURCES_LIST_REQUESTED, watchSourcesList);
  yield takeLatest(REPORTS_LIST_REQUESTED, watchReportsList);
  yield takeLatest(INCIDENT_DEFAULT_SAVED_SEARCH_REQUESTED, watchDefaultSavedSearch);

  yield takeLatest(INCIDENT_CUSTOMERS_LIST_REQUESTED, watchCustomersList);
  yield takeLatest(INCIDENT_SEVERITY_LIST_REQUESTED, watchSeverityList);
  yield takeLatest(INCIDENT_DISPOSITION_LIST_REQUESTED, watchDispositionList);
  yield takeLatest(INCIDENT_SINGLE_EXPORT_PDF_LIST_REQUESTED, watchSingleExportPdf);
  yield takeLatest(INCIDENT_DETAILS_REQUESTED, watchIncidentDetails);
  yield takeLatest(INCIDENT_MANAGEMENT_EDIT_REQUESTED, watchEdit);
  yield takeLatest(INCIDENT_EXPORT_EXCEL_LIST_REQUESTED, watchExcelExport);
  yield takeLatest(THREAT_INTEL_EXPORT_EXCEL_LIST_REQUESTED, watchThreatIntelExcelExport);
  yield takeLatest(INCIDENT_EXPORT_PDF_LIST_REQUESTED, watchPdfExport);
  yield takeLatest(INCIDENT_SEND_EMAIL_REQUESTED, watchSendEmail);
  yield takeLatest(INCIDENT_POST_COMMENT_REQUESTED, watchPostComment);
  yield takeLatest(INCIDENT_ADD_MEMBER_REQUESTED, watchAddMember);
  yield takeLatest(INCIDENT_ASSET_DELETE_REQUESTED, watchDeleteAsset);
  yield takeLatest(DELETE_COMMENT_REQUESTED, watchDeleteComment);
  yield takeLatest(DELETE_ATTACHMENT_REQUESTED, watchDeleteAttachment);
  yield takeLatest(GET_REPORT_TYPES_REQUESTED, watchGetReportTypes);
  yield takeLatest(GENERATE_REPORT_REQUESTED, watchGenerateReport);
  yield takeLatest(INCIDENT_MANAGEMENT_CREATE_REQUESTED, watchCreate);
  yield takeLatest(DOWNLOAD_ARTIFCATS_FILE, watchAssetDownload);
  yield takeLatest(INCIDENT_DISPOSITION_FIELD_REQUESTED, watchGetDispositionFields);
  yield takeLatest(INCIDENT_APP_CONFIG_REQUESTED, watchGetAppConfiguration);
  yield takeLatest(INCIDENT_DISPOSITION_KEY_REQUESTED, watchGetDispositionKeys);
  yield takeLatest(DELETE_INCIDENT_REQUESTED, watchDelete);
  yield takeLatest(BULK_UPDATE_INCIDENT_REQUESTED, watchBulkUpdateIncident);
  yield takeLatest(INCIDENT_SEARCH_LIST_REQUESTED, watchGetIncidentSearch);
  yield takeLatest(BULK_EXECUTE_REQUESTED, watchBulkExecute);
  yield takeLatest(BULK_DELETE_REQUESTED, watchBulkDelete);
  yield takeLatest(ALERTS_LIST_REQUESTED, watchAlertList);
  yield takeLatest(INCIDENT_LINK_ALERTS_REQUESTED, watchLinkingAlerts);
}
