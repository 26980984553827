import axios from 'axios';
import API from '../config/endpoints.config';
import queryString from "query-string";

export async function listSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const fields = [
    'astLocation', 'astAssetValue', 'astGroup', 'astClassification', 'astOwners', 'astTypes.atyValue', 'astOwner',
    'astVendorsName', 'astProductsName'
  ];
  const expanedString = fields.toString();
  let api = API.asset + "?expand=" + expanedString;
  const obj = queryString.parse(payload.parameter);

  if (payload.parameter) {
    let myArrayQry = '';
    if (Object.keys(obj).length !== 0) {
      Object.entries(obj).forEach(([key, val]) => {
        myArrayQry += '&' + key + '=' + val;
      });

      api = api + myArrayQry;
    }
  }
  const response = await axios.get(api, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function importLogslistSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const fields = [
    'asmTemplate'
  ];
  const expanedString = fields.toString();
  let api = API.asset + "/import-logs?expand=" + expanedString;
  if (payload.parameter) {
    api = api + '&AssetMappingSearch[search]=' + payload.parameter;
  }
  const response = await axios.get(api, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function exportLoglistSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const fields = [
    'scheduledBy',
    'notifyTo'
  ];
  const expanedString = fields.toString();
  let api = API.exportJob + `?exj_container=${payload?.module}&expand=` + expanedString;
  if (payload?.parameter) {
    api = api + '&search=' + payload.parameter;
  }
  const response = await axios.get(api, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type':'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function getSingleSaga(id, isExpand) {
  const userToken = localStorage.getItem('AccessToken');
  let api = API.asset + '/' + id;
  if (isExpand) api = API.asset + '/' + id +
    `?expand=astLocation,astAssetValue,astCustodian,astGroup,
    astClassification,astTypes,astOwner,
    astStatus,astRemoteAp,astSystemType,astNetworkType,astPowerStatus,astDocumentType,astDepartments,astVendorsName,astProductsName`;

  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function createSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.asset, data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}


export async function getClassificationValue(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.asset + '/calculate-classification-value', data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function editSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.put(API.asset + '/' + data.ast_id, data, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });


  return response.data;
}

export async function deleteSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.delete(API.asset + '/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function importSaga(file) {
  const userToken = localStorage.getItem('AccessToken');
  const f = new FormData();
  f.append('importFile', file);
  const response = await axios.post(API.assetImport, f, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}

export async function listAssetClassificationSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetClassification, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listAssetConfidentialitySaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetConfidentiality, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listAssetAvailabilitySaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetAvailability, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listAssetIntegritySaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetIntegrity, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listAssetOSSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetOS, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getAssetTypeById(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetType + '/' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function getAssetDashboardSaga(id) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetDetailsDashboard + '?id=' + id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listAssetCategoriesSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetCategories, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function listAssetEnableFieldsSaga(payload) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetEnableFields, {
    method: 'GET',
    params: payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listAssetTypesSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  let api = API.assetType;
  if (data === false) {
    api = API.assetType + '?pagination=false';
  }
  const response = await axios.get(api, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function listGetAssetFieldsSaga(field) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.assetFields + '?field=' + field, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}


export async function getAssetTemplates() {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.get(API.asset + '/template', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}


export async function createTemplateSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.asset + '/template-create', data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function assetTemplateDraftSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.asset + '/draft-template', data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function assetImportProcessSaga(data) {
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(API.asset + '/process', data, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}
export async function bulkDelete(data) {
  let url = API.asset + "/bulk-delete";
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.post(url, data?.data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}

export async function assetImportedJobDelete(payload) {
  let url = API.asset + `/delete-import-logs/${payload}`;
  const userToken = localStorage.getItem('AccessToken');
  const response = await axios.delete(url ,{
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });
  return response.data;
}


export async function assetExportJobDelete(payload) {
  const userToken = localStorage.getItem('AccessToken');
  let url = API.exportJob + `/delete-exported-job?module=${payload?.module}&id=${payload?.id}`;
  const response = await axios.delete(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userToken,
    },
  });

  return response.data;
}