import { put, call, takeLatest } from 'redux-saga/effects';
import {
    WIDGET_MANAGEMENT_LIST_REQUESTED,
    WIDGET_MANAGEMENT_LIST_SUCCESSED,
    WIDGET_MANAGEMENT_LIST_FAILED,
    WIDGET_DELETION_REQUESTED,
    WIDGET_DUPLICATION_REQUESTED,
    RENDER_WIDGET_REQUESTED,
    RENDER_WIDGET_SUCCESSED,
    RENDER_WIDGET_FAILED,
    CREATE_WIDGET_REQUESTED,
    SAVE_WIDGET_REQUESTED,
    GET_WIDGET_SETTINGS_REQUESTED,
    GET_WIDGET_SETTINGS_SUCCESSED,
    GET_WIDGET_SETTINGS_FAILED,
    GET_WIDGET_DATASOURCES_REQUESTED,
    GET_WIDGET_DATASOURCES_SUCCESSED,
    GET_WIDGET_DATASOURCES_FAILED,
    GET_WIDGET_DATA_FIELDS_REQUESTED,
    GET_WIDGET_DATA_FIELDS_SUCCESSED,
    GET_WIDGET_DATA_FIELDS_FAILED,
} from '../../constants/actionTypes';
import {
    widgetlistSaga,
    widgetSettingsSaga,
    widgetDeletionSaga,
    widgetDuplicationSaga,
    widgetRenderSaga,
    widgetCreationSaga,
    widgetSaveAsDraftSaga,
    widgetDataSourcesSaga,
    widgetDataFieldsSaga,
} from '../../api/widgetManagementSaga';
import { dismissToastLoader, showToastError, showToastLoader, showToastSuccess } from '../../utils/toasts';

// Listing Widget function
export function* watchWidgetListing(action) {
    try {
        const response = yield call(widgetlistSaga, action?.payload);
        if (response.success) {
            yield put({ type: WIDGET_MANAGEMENT_LIST_SUCCESSED, data: response.data });
        } else {
            yield put({ type: WIDGET_MANAGEMENT_LIST_FAILED, data: null });
        }
    } catch (err) {
        yield put({
            type: WIDGET_MANAGEMENT_LIST_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Deleting Custom Widget function
export function* watchWidgetDeletion(action) {
    const loaderHandle = showToastLoader('Deleting widget...');
    try {
        const deleteWidgetResponse = yield call(widgetDeletionSaga, action?.payload);
        if (deleteWidgetResponse.success) {
            const response = yield call(widgetlistSaga, action?.payload);
            if (response.success) {
                yield put({ type: WIDGET_MANAGEMENT_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: WIDGET_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (deleteWidgetResponse.success) {
            showToastSuccess(deleteWidgetResponse?.data?.message, loaderHandle);
        } else {
            showToastError(deleteWidgetResponse?.data?.message, loaderHandle);
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle);
    }
}

// Duplicating Custom Widget function
export function* watchWidgetDuplication(action) {
    const loaderHandle = showToastLoader('Duplicating widget...');
    try {
        const duplicateWidgetResponse = yield call(widgetDuplicationSaga, action?.payload);
        if (duplicateWidgetResponse.success) {
            const response = yield call(widgetlistSaga, action?.payload);
            if (response.success) {
                yield put({ type: WIDGET_MANAGEMENT_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: WIDGET_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (duplicateWidgetResponse.success) {
            showToastSuccess(duplicateWidgetResponse?.data?.message, loaderHandle);
        } else {
            showToastError(duplicateWidgetResponse?.data?.message, loaderHandle);
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle);
    }
}

// Render Custom Widget
export function* watchRenderWidget(action) {
    const loaderHandle = showToastLoader('Fetching widget data...');
    try {
        let isDataInvalid = false;
        const response = yield call(widgetRenderSaga, action?.payload);
        if (response.success) {
            // if (action?.payload?.widget_type != 'text' && !response.data?.result[0]?.agr) {
            //     showToastError('Please select an aggregate function', loaderHandle);
            //     return;
            // }

            response.data.result.map(item => {
                const isNumberExists = Object.keys(item.fields)?.map((v, i) => isNaN(item.fields[Object.keys(item.fields)[i]]));
                if (!isNumberExists.includes(false)) {
                    isDataInvalid = true;
                }
            });
            if (action?.payload?.widget_type != 'text' && action?.payload?.widget_type != 'table' && isDataInvalid) {
                showToastError('The query must return at least one numeric value', loaderHandle);
                return;
            }

            // Convert to old structure
            const oldResponse = {
                ...response,
                data: {
                    ...response.data,
                    result: response.data.result.map(item => ({
                        ...item.fields // Spread all fields from the "fields" object into the result item
                    }))
                }
            };

            dismissToastLoader(loaderHandle);
            yield put({ type: RENDER_WIDGET_SUCCESSED, data: oldResponse?.data });
        } else {
            yield put({ type: RENDER_WIDGET_FAILED, data: null });
            showToastError(response?.data?.data?.error, loaderHandle);
        }
    } catch (err) {
        let errStr = err?.response?.data?.data?.error
        //This condition has been added based on the backend requirement to handle specific PostgreSQL query errors.
        if(errStr && errStr?.includes(`must appear in the GROUP BY clause`)){
            const errorStart = errStr.indexOf("ERROR:");
            const sqlStateStart = errStr.indexOf("(SQLSTATE");
            if (errorStart !== -1 && sqlStateStart !== -1) {
                const extractedError = errStr.substring(errorStart, sqlStateStart).trim();
                showToastError(extractedError, loaderHandle);
            }
            else{
                showToastError('Error rendering widget', loaderHandle);
            }
        }
        else{
            showToastError(errStr || 'Error rendering widget', loaderHandle);
        }
    }
}

// Create Custom Widget
export function* watchCreateWidget(action) {
    const loaderHandle = showToastLoader(action?.payload?.id ? 'Updating widget...' : 'Creating widget...');
    try {
        const createWidgetResponse = yield call(widgetCreationSaga, action?.payload);
        if (createWidgetResponse.success) {
            const response = yield call(widgetlistSaga);
            if (response.success) {
                yield put({ type: WIDGET_MANAGEMENT_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: WIDGET_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (createWidgetResponse.success) {
            showToastSuccess(createWidgetResponse?.data?.message, loaderHandle);
            action?.payload?.closeDrawer();
        } else {
            showToastError(createWidgetResponse?.data?.message, loaderHandle);
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle);
    }
}

// Save Custom Widget As Draft
export function* watchSaveWidget(action) {
    const loaderHandle = showToastLoader('Saving widget as draft...');
    try {
        const saveWidgetResponse = yield call(widgetSaveAsDraftSaga, action?.payload);
        if (saveWidgetResponse.success) {
            const response = yield call(widgetlistSaga);
            if (response.success) {
                yield put({ type: WIDGET_MANAGEMENT_LIST_SUCCESSED, data: response.data });
            } else {
                yield put({ type: WIDGET_MANAGEMENT_LIST_FAILED, data: null });
            }
        }
        if (saveWidgetResponse.success) {
            showToastSuccess("Widget saved as draft", loaderHandle);
        } else {
            showToastError(saveWidgetResponse?.data?.message, loaderHandle);
        }
    } catch (err) {
        showToastError(err?.response?.data?.data?.message, loaderHandle);
    }
}

// Getting Widget Settings
export function* watchGetWidgetSettings(action) {
    try {
        const response = yield call(widgetSettingsSaga, action?.payload);
        if (response.success) {
            const widgetSettingsList = JSON.parse(response?.data?.wds_settings);
            const { graphs } = widgetSettingsList?.settings || {};
            let widgetSettings = {};
            if (graphs && graphs?.type) {
                const widgetTypes = graphs?.type?.map((v) => ({ key: String(v?.name), value: String(v?.name), label: String(v?.name), src: v.image, limit: v["field-allowed"] }));
                const dbFunctions = graphs?.func?.map((v) => ({ key: String(v), value: String(v), label: String(v) }));
                const dbClauses = widgetSettingsList?.settings?.available_tags?.map((v) => ({ key: String(v), value: String(v), label: String(v), GroupLabel: 'Clauses' }));
                const dbOperators = widgetSettingsList?.settings?.operators?.map((v) => ({ key: String(v), value: String(v), label: String(v) }));
                widgetSettings = { types: widgetTypes, functions: dbFunctions, clauses: dbClauses, operators: dbOperators };
            }
            yield put({ type: GET_WIDGET_SETTINGS_SUCCESSED, data: widgetSettings });
        } else {
            yield put({ type: GET_WIDGET_SETTINGS_FAILED, data: null });
        }
    } catch (err) {
        yield put({
            type: GET_WIDGET_SETTINGS_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Getting Data Sources
export function* watchGetDataSources(action) {
    try {
        const response = yield call(widgetDataSourcesSaga, action?.payload);
        if (response.success) {
            let dataSources = response?.data?.map((v) => ({ key: String(v), value: String(v), label: String(v).replaceAll('advisory', 'Threat Intel'), GroupLabel: 'Sources', }));
            yield put({ type: GET_WIDGET_DATASOURCES_SUCCESSED, data: dataSources });
        } else {
            yield put({ type: GET_WIDGET_DATASOURCES_FAILED, data: null });
        }
    } catch (err) {
        yield put({
            type: GET_WIDGET_DATASOURCES_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

// Getting Data Fields
export function* watchGetDataFields(action) {
    try {
        const response = yield call(widgetDataFieldsSaga, action?.payload);
        if (response.success === true) {
            const mergedFields = response?.data.flat(1);
            let dataFields = mergedFields?.map((v) => ({ key: String(v), value: String(v)?.replaceAll('advisory', 'Threat Intel'), label: String(v).replaceAll('advisory', 'Threat Intel'), GroupLabel: 'Fields', }));
            yield put({ type: GET_WIDGET_DATA_FIELDS_SUCCESSED, data: dataFields });
        } else {
            yield put({ type: GET_WIDGET_DATA_FIELDS_FAILED, data: null });
        }
    } catch (err) {
        yield put({
            type: GET_WIDGET_DATA_FIELDS_FAILED,
            data: err?.response?.data?.data,
        });
    }
}

export default function* watcher() {
    yield takeLatest(WIDGET_MANAGEMENT_LIST_REQUESTED, watchWidgetListing);
    yield takeLatest(WIDGET_DELETION_REQUESTED, watchWidgetDeletion);
    yield takeLatest(WIDGET_DUPLICATION_REQUESTED, watchWidgetDuplication);
    yield takeLatest(GET_WIDGET_SETTINGS_REQUESTED, watchGetWidgetSettings);
    yield takeLatest(GET_WIDGET_DATASOURCES_REQUESTED, watchGetDataSources);
    yield takeLatest(GET_WIDGET_DATA_FIELDS_REQUESTED, watchGetDataFields);
    yield takeLatest(RENDER_WIDGET_REQUESTED, watchRenderWidget);
    yield takeLatest(CREATE_WIDGET_REQUESTED, watchCreateWidget);
    yield takeLatest(SAVE_WIDGET_REQUESTED, watchSaveWidget);
}
