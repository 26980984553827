import axios from 'axios';
import API from '../config/endpoints.config';

const FileDownload = require('js-file-download');

export async function vulnerabilityListAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = 'expand=assetNameAPI,vvaOrganization,vvaCase,vvaVulAsset,scanHistory,vvaSeverityOrder,vulnerabilitiesList,compromisedAssets,actionLogs,vulnerabilityCases,relatedVulnerability';
    if (payload.queryItem) {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}?${expData}`;
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        cancelToken: payload?.cancelToken
    });
    return response.data;
}

export async function vulnerabilityListDeleteAPI(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.delete(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function vulnerabilitylistUpdate(id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/${id}`, payload, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function vulnerabilityLogList(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/logs?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function vulnerabilityAssetDelete(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/delete-asset`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function vulnerabilityAddAsset(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/add-asset`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}


export async function vulnerabilityByIdAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = 'expand=vmUrl,assetNameAPI,compromisedAssets,actionLogs,actionLogs.aloUser,vulnerabilityCases,scanHistory.scanName,vvaSeverityOrder,relatedVulnerability';
    if (payload.queryItem) {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/${payload?.id}?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/${payload?.id}?${expData}`;
    }

    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function exportPDF(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let data = payload?.details.values;
    let api = `${API.exportJob}/export?${(payload?.myArrayQry)}`;

    const response = await axios.post(api,data,
        {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',
    });
    return response.data;
}


// export async function exportExcel(queryItem) {
//     const userToken = localStorage.getItem('AccessToken');
//     const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/export?${queryItem}`, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: 'Bearer ' + userToken,
//         },
//         responseType: 'blob',
//     });
//     FileDownload(response.data, 'export.xlsx');
//     return response.data;
// }

export async function vulnerabilityAssessmentListAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = 'expand=createdBy';
    if (payload.queryItem) {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment?${expData}`
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function importedVulnerabilityAssessmentListAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    const expData = 'expand=attachmentUrl';
    if (payload.queryItem) {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/imported-assessments?${expData}&${payload.queryItem}`;
    } else {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/imported-assessments?${expData}`
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function importedVulnerabilityAssessmentAPI(id) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';

    api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/get-after-import?id=${id}`;

    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function initiatemanualAssessment(payload) {
    const userToken = localStorage.getItem('AccessToken');

    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function initiatemanualAssessmentuUpdate(id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/${id}`, payload, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}


export async function vulnerabilityAssessmentListDeleteAPI(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.delete(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function importedAssessmentListDeleteAPI(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.delete(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/delete-imported-assessment?id=${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function generateReportValAssessmentAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/generate-report`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',

    });
    let ext = 'xlsx';
    if (response?.headers?.['content-type'] && response?.headers?.['content-type'].includes('pdf')) { ext = 'pdf' }
    FileDownload(response.data, `report.${ext}`);
    return response.data;
}
export async function templateListAssessmentAPI() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/template-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
//createapi
export async function templateCreateAssessmentAPI(data) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/template`, data, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function bulkUpdateValnerabilityAPI(data) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/bulk`, data, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function attackTypeListVulnerability() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/attack-type`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function assetListVulnerability() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/assets-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function fetchAssetsList(search) {
    const userToken = localStorage.getItem('AccessToken');
    let api = API.asset;

    if (search) api = API.asset + `?search=${search}`

    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    const tempAssets = response?.data?.data?.items || [];
    const assets = tempAssets?.map(ast => ({
        key: ast?.ast_id,
        value: ast?.ast_hostname || ast?.ast_ip_address || ast?.ast_name,
        label: ast?.ast_hostname || ast?.ast_ip_address || ast?.ast_name
    }));
    return assets || [];
}

export async function severityListVulnerability() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/severity-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function vulnerabilityAssessmentApplicationList(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/application-list?flag=${payload.source}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function vulnerabilityAssessmentSourceFilterList(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/application-list?flag=${payload.source}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function vulnerabilityAssessmentActionList(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/config-action?id=${id}&artifact=&type=assess&asset_type=Assets`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function executeInitiateManualActionList(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/execute`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function deleteMultiValnerabilityActionList(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/multi-delete`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function importAssessmentAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = ""

    // if (payload.type === "Rapid7") {
    //     api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/rapid7-import`
    // } else {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/import`
  //  }
    const response = await axios.post(api, payload.importedFile, {
        method: 'POST',
        headers: {
            'Content-Type': 'text/xml',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}


export async function getValnerabilityAssessmentsAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = '';
    //wa
    const expand = "&expand=astData,vulnerabilityCases"
    if (payload.queryItem) {

        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/view?id=${payload.id}&status=${payload.flag}${expand}&${payload.queryItem}`;
    } else {
        api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/view?id=${payload.id}&status=${payload.flag}${expand}`;
    }
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}


export async function addAssessmentVulnerabilityAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/add-vulnerability`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function deleteAssessmentVulnerabilityAPI(payload) {
    const userToken = localStorage.getItem('AccessToken');
    let api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/delete-vulnerability`;
    if (payload?.delete) api = `${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/delete-vulnerability?delete=true`;
    const response = await axios.post(api, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function updatePartialVulnerabilityManagementApi(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityCustomUpdate + '?id=' + payload?.id}`, payload?.data, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function addTaskVulnerabilityManagement(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/tasks`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
export async function viewTaskVulnerabilityManagement(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/tasks`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
// Vulnerabilities Comment

export async function vulnerabilitiesComment(data) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/comment?id=${data.id}`, data.payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

// UPLOAD NESSUS
export async function viewTaskVulnerabilityUploadNessus(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/upload`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
export async function vulnerabilityTemplateType() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/template-type`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

// Task Categories
export async function vulnerabilityTaskCategories() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/task/categories`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
export async function vulnerabilityTaskDepartments() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/task/departments`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function vulnerabilityDeleteAttachment(id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/delete-attachment`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function vulnerabilityCreateCases(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.baseUrl}/cases-vulnerability`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
export async function vulnerabilityCaseList(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.baseUrl}/cases-vulnerability/cases-list?ids=${payload.ids}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
export async function vulnerabilityAsset(id, pageNumber) {
    pageNumber = typeof pageNumber === 'undefined' ? 1 : pageNumber;
    const userToken = localStorage.getItem('AccessToken');
    const expand = "vuaAsset,vulnerbilityVulnerbilityAssets"
    const response = await axios.get(`${API.vulnerabilityAssessmentManagementModule.vulnerabilityManagementAssessmentList}/vulnerability-asset?asses_id=${id}&expand=${expand}&page=${pageNumber}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}
export async function vulnerabilitySearchAsset(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityAssessmentManagementModule.vulnerabilityManagementAssessmentList}/vulnerability-asset?search=${payload.searchValue}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function vulnerabilityManagementNameList() {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagement}/vulnerabilities-list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function vulnerabilityContent(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(`${API.vulnerabilityManagement}/vulnerability-content`, payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function vulnerabilityCveSearch(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagement}/search-vulnerabilities?expand=assetNameAPI&VulnerbilityVulnerbilityAssetsSearch[search]=${payload}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function vulnerabilityManagementImageLoad(url) {
    const userToken = localStorage.getItem('AccessToken');
    try {
        const response = await axios.get(`${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userToken,
            },
        }
        )
        if (response.config.url) {
            return response.data;
        } else {
            return false
        }

    } catch (err) {
        return false
    }

}
export async function vulnerabilityUpdateAssessment(id, assessmentId, vua_asset_id, payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.put(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/update-vulnerability?id=${id}&assessId=${assessmentId}&assetId=${vua_asset_id}`, payload, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
export async function downloadFile(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(payload?.url, {
        headers: {
            Authorization: 'Bearer ' + userToken,
        },
        responseType: 'blob',
    });
    FileDownload(response.data, payload?.name);
    return response.data;
}


export async function duplicateAssessmentsaga(id) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.get(`${API.vulnerabilityManagementModule.vulnerabilityManagementList}/assessment/duplicate?id=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}

export async function getFieldMappingResult(payload) {
    const userToken = localStorage.getItem('AccessToken');
    const response = await axios.post(API.vulnerabilityAssessment + '/output', payload, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    }
    );
    return response.data;
}

export async function getSearchedCases(value) {
    const userToken = localStorage.getItem('AccessToken');
    let api = `${API.baseUrl}/cases-vulnerability/cases-list?value=${value}`;
    const response = await axios.get(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
        },
    });
    return response.data;
}
